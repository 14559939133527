import "./styles.scss";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { speakersApi } from "../../../../../core/services/SpeakersApi";
import { trackEvent } from "../../../../../shared/analytics";
import { Speaker } from "../../IProps/speaker.interface";
import { SpeakerCardSkeleton } from "../skeleton/speakerCard";
import { SpeakerDetail } from "../speakerDetail";
import { useTranslation } from "react-i18next";

export const Speakers: React.FC = () => {
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedSpeaker, setSelectedSpeaker] = useState<Speaker | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    trackEvent("Button", "Click", "palestrantes");
    const fetchSpeakers = async () => {
      try {
        const eventId = "6696d5451f8a82a8c8c18a7c";
        const response = await speakersApi.getByEvent(eventId);
        setSpeakers(response);
      } catch (err) {
        setError("Failed to fetch speakers");
      } finally {
        setLoading(false);
      }
    };

    fetchSpeakers();
  }, []);

  const handleSpeakerClick = (speaker: Speaker) => {
    setSelectedSpeaker(speaker);
  };

  if (loading) {
    return (
      <div className="speakers">
        <h2 className="speakers__title">
          <Skeleton
            width={176}
            height={25}
            style={{ marginBottom: 10 }}
            borderRadius={0}
          />
        </h2>
        <div className="grid-layout-4-2">
          {[...Array(8)].map((_, index) => (
            <SpeakerCardSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (selectedSpeaker) {
    return (
      <div className="speakers">
        <SpeakerDetail
          speakerId={selectedSpeaker.id}
          onBack={() => setSelectedSpeaker(null)}
        />
      </div>
    );
  }

  return (
    <div className="speakers">
      <h2 className="speakers__title">{t("speakers")}</h2>
      <div className="grid-layout-4-2">
        {speakers.map((speaker) => (
          <div
            key={speaker.id}
            className="speakers__card"
            onClick={() => handleSpeakerClick(speaker)}
          >
            <ImageWithLoading src={speaker.profilePicture} alt={speaker.name} />
            <h3 className="speakers__card-name">{speaker.name}</h3>
            <p className="speakers__card-position">{speaker.company}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const ImageWithLoading: React.FC<{ src: string; alt: string }> = ({
  src,
  alt,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="image-container">
      {!isLoaded && <SpeakerCardSkeleton />}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        style={{ display: isLoaded ? "block" : "none" }}
        className="speakers__card-image"
      />
    </div>
  );
};
