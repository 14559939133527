import React from "react";
import { FaCalendar } from "react-icons/fa";
import { MdFavoriteBorder } from "react-icons/md";
import { IExpositorCardShortcuts } from "../../../modules/psiu/event/IProps/expositor.interface";
import "./styles.scss";
 
export const ExpositorCardShortcuts: React.FC<IExpositorCardShortcuts> = ({
  imageUrl,
  name,
  description,
  quota
}) => {

  const quotaTranslations: { [key: string]: string } = {
    DIAMOND: "Diamante",
    GOLD: "Ouro",
    SILVER: "Prata",
    TECH: "Tech",
    CONSTRUCTOR: "Construtora",
    CONSTRUCTION: "Construtora",
  };

  return (
    <section className="expositorCardShortcuts">
      <div className="expositorCardShortcuts__image">
        <img
          src={
            imageUrl ??
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjpmZvsRQgC9JK17R3HTGetqX8J5Xy8EsfgQ&s"
          }
          alt={name}
        />
      </div>

      <div className="expositorCardShortcuts__info">
        <section className="expositorCardShortcuts__info--desc">
          <div className="expositorCardShortcuts__info--hour">
            <p>
              {quotaTranslations[quota] || quota}
            </p>
          </div>
          <div className="expositorCardShortcuts__info--favorite">
            {/* <MdFavoriteBorder size={20} id="icon-favorite" /> */}
          </div>
        </section>
        <p className="expositorCardShortcuts__info--name">{name}</p>
        <p className="expositorCardShortcuts__info--location">{description}</p>
      </div>
    </section>
  );
};
