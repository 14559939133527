import "./styles.scss";

import React, { useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";

import { EventData } from "../../../../../core/interfaces/event/shedules.interface";
import shedulesApi from "../../../../../core/services/ShedulesApi";
import { trackEvent } from "../../../../../shared/analytics";
import { SpeakerScheduleCard } from "../../../../../shared/components/speakerScheduleCard";
import { Speaker } from "../../IProps/speaker.interface";
import { SpeakerScheduleCardSkeleton } from "../skeleton/speakerScheduleCard";
import { SpeakerDetail } from "../speakerDetail";
import { useTranslation } from "react-i18next";

export const Schedule: React.FC = () => {
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [days, setDays] = useState<string[]>([]);
  const [eventData, setEventData] = useState<EventData>({});
  const [loading, setLoading] = useState(true);
  const [selectedSpeaker, setSelectedSpeaker] = useState<Speaker | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    trackEvent("Button", "Click", "agenda");
    getSchedule();
  }, []);

  const getSchedule = async () => {
    const response = await shedulesApi.shedule();
    if (response) {
      const fetchedDays = Object.keys(response);
      setDays(fetchedDays);
      setSelectedDay(fetchedDays[0]);
      setEventData(response);
    }
    setLoading(false);
  };

  const handleSpeakerClick = (speaker: any) => {
    setSelectedSpeaker(speaker);
  };

  if (selectedSpeaker) {
    return (
      <div className="schedule">
        <SpeakerDetail
          speakerId={selectedSpeaker.id}
          onBack={() => setSelectedSpeaker(null)}
        />
      </div>
    );
  }

  const formatDate = (dateString: string): string => {
    // Extrai apenas a parte da data (YYYY-MM-DD) da string ISO
    const [year, month, day] = dateString.split("T")[0].split("-");

    // Formata para dd/MM
    return `${day}/${month}`;
  };

  const renderCards = () => {
    if (loading == true) {
      return (
        <section className="schedule">
          <div className="schedule__buttons--days--skeleton">
            <Skeleton height={50} width={50} style={{ borderRadius: "50%" }} />
            <Skeleton height={50} width={50} style={{ borderRadius: "50%" }} />
          </div>
          <section className="schedule__buttons--content">
            {[...Array(8)].map((_, index) => (
              <SpeakerScheduleCardSkeleton key={index} />
            ))}
          </section>
        </section>
      );
    }
    const selectedEvents = eventData[selectedDay] || [];
    return selectedEvents.map((event) => (
      <div style={{ cursor: "pointer" }} key={event.id}>
        <SpeakerScheduleCard
          dados={event}
          onSpeakerClick={handleSpeakerClick}
        />
      </div>
    ));
  };

  return (
    <section className="schedule">
      <section className="schedule__buttons--days">
        {selectedDay === days[0] && <h3>{t("onlyVip")}</h3>}
        <div className="schedule__buttons-container">
          {days.map((day, index) => (
            <button
              key={index}
              className={selectedDay === day ? "selected" : ""}
              onClick={() => setSelectedDay(day)}
            >
              {`${formatDate(day)} `}
            </button>
          ))}
        </div>
      </section>
      <section className="schedule__buttons--content">{renderCards()}</section>
    </section>
  );
};
