import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import  ptBR  from "./translations/ptBR.json";
import  es  from "./translations/es.json";
import  en  from "./translations/en.json";

i18n.use(initReactI18next).init({
    fallbackLng: "ptBR",
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: en,
        ptBR: ptBR,
        es: es         
    }   
})