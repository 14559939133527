import React, { useEffect, useState } from "react";
import { BsImage } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { RiArrowDownSFill } from "react-icons/ri";
import eventsApi from "../../../core/services/EventsApi";
import { IEditPublicationProps } from "../../Iprops/IEditPublication.interface";
import "./styles.scss";

export const ModalEditPublication: React.FC<IEditPublicationProps> = ({
  isOpen,
  onClose,
  type,
  onUpdate,
  description,
  id,
  images,
}) => {
  const name = localStorage.getItem("name");
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [editDescription, setEditDescription] = useState(description);
  const [editImages, setEditImages] = useState<string[]>(images || []);

  useEffect(() => {
    setEditDescription(description);
    setEditImages(images || []);
  }, [description, images]);

  const handleMediaUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      if (mediaFiles.length + selectedFiles.length <= 3) {
        setMediaFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      } else {
        const remainingSlots = 3 - mediaFiles.length;
        const filesToAdd = selectedFiles.slice(0, remainingSlots);
        setMediaFiles((prevFiles) => [...prevFiles, ...filesToAdd]);
      }
    }
  };

  const handleRemoveAllMedia = () => {
    setMediaFiles([]);
    setEditImages([]);
  };

  const handleSubmit = async () => {
    try {
      const response = await eventsApi.editPublication(
        id,
        mediaFiles,
        editDescription,
        editImages
      );
      onClose();
      await onUpdate();
    } catch (error) {
      console.error("Erro :", error);
    }
  };

  if (!isOpen) return null;

  const mediaClass =
    mediaFiles.length + editImages.length === 1
      ? "one-media"
      : mediaFiles.length + editImages.length === 2
      ? "two-medias"
      : "three-medias";

  return (
    <section className="modal">
      <section className="modal__content">
        <section className="modal__header">
          <section className="modal__perfil">
            <div className="modal__perfil--icon">
              {name?.charAt(0).toUpperCase()}
              {name?.charAt(1).toUpperCase()}
            </div>
            <section className="modal__info">
              <h4>
                {name}
                <RiArrowDownSFill />
              </h4>
              <p>Publicar para todos</p>
            </section>
          </section>
          <section className="modal__close">
            <IoClose onClick={onClose} size={20} />
          </section>
        </section>

        <section className="modal__body">
          <textarea
            placeholder="O que você deseja compartilhar sobre o evento?"
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
          />
          {editImages.length > 0 && (
            <section className="modal__body--remove-all-media">
              <IoClose
                className="modal__body--remove-all-media--icon"
                onClick={handleRemoveAllMedia}
                size={20}
              />
            </section>
          )}

          <div className={`modal__uploaded--media ${mediaClass}`}>
            {editImages.map((url, index) => (
              <div key={index} className="media-container">
                <img
                  src={url}
                  alt={`Upload Preview ${index + 1}`}
                  className="modal__uploaded--image"
                />
              </div>
            ))}
            {mediaFiles.map((file, index) => {
              const fileUrl = URL.createObjectURL(file);
              return (
                <div key={index} className="media-container">
                  {file.type.startsWith("image/") ? (
                    <img
                      src={fileUrl}
                      alt={`Upload Preview ${index + 1}`}
                      className="modal__uploaded--image"
                    />
                  ) : (
                    <video controls className="modal__uploaded--video">
                      <source src={fileUrl} type={file.type} />
                      Seu navegador não suporta a tag de vídeo.
                    </video>
                  )}
                </div>
              );
            })}
          </div>
        </section>

        <section className="modal__footer">
          <section className="modal__buttons">
            <section className="modal__button--imagem">
              <label htmlFor="mediaUpload">
                <BsImage size={29} />
              </label>
              <input
                id="mediaUpload"
                type="file"
                multiple
                accept="image/*,video/*"
                onChange={handleMediaUpload}
                style={{ display: "none" }}
              />
            </section>
            <section className="modal__button--publish">
              <button onClick={handleSubmit}>Editar</button>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};
