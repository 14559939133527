import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import InternalLayout from '../core/components/internalLayout';
import { RequireAuth } from '../core/contexts/auth/RequiredAuth';
import { AuthProvider } from '../core/contexts/permissions/useAuth';
import { ConstructionPage } from '../modules/generic/pages/construction';
import SupportUrgent from '../modules/generic/pages/help';
import { Home } from '../modules/generic/pages/home';
import { Private } from '../modules/generic/pages/private';
import { Admin } from '../modules/psiu/admin/pages';
import { Politics } from '../modules/psiu/event/components/politics';
import { Event } from '../modules/psiu/event/page';
import { Expositor } from '../modules/psiu/exhibitor/pages';
import { Onboarding } from '../modules/psiu/mobile/onboarding';

const InternalRoutes = () => {
  const isInApp = navigator.userAgent.includes("YourAppIdentifier");
  console.log("pelo app", isInApp);
  return (
    <InternalLayout>
      <AuthProvider>
        <Routes>
          <Route
            path="/home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/event"
            element={
              <RequireAuth>
                <Event />
              </RequireAuth>
            }
          />
          <Route
            path="/area-produtor"
            element={
              <RequireAuth>
                <Admin />
              </RequireAuth>
            }
          />
          <Route
            path="/private"
            element={
              <RequireAuth>
                <Private />
              </RequireAuth>
            }
          />
          <Route
            path="/construction"
            element={
              <RequireAuth>
                <ConstructionPage />
              </RequireAuth>
            }
          />
          <Route
            path="/politics"
            element={
              <RequireAuth>
                <Politics />
              </RequireAuth>
            }
          />
          <Route
            path="/suport"
            element={
              <RequireAuth>
                <SupportUrgent />
              </RequireAuth>
            }
          />
          <Route
            path="/expositor"
            element={
              <RequireAuth>
                <Expositor />
              </RequireAuth>
            }
          />
          <Route path="/initial" element={<Onboarding />} />
          {/* Se o usuário não estiver autenticado, redirecione para a página de login */}
          <Route path="*" element={<Navigate to="/initial" />} />
        </Routes>
      </AuthProvider>
    </InternalLayout>
  );
};

export default InternalRoutes;
