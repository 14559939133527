import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { BsImage } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { RiArrowDownSFill } from 'react-icons/ri';

import eventsApi from '../../../core/services/EventsApi';
import {
  ModalPublicationProps,
} from '../../../modules/psiu/event/IProps/modalPublication.interface';

export const ModalPublication: React.FC<ModalPublicationProps> = ({
  isOpen,
  onClose,
  type,
  onUpdate,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<any>([]);
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const name = localStorage.getItem("name");

  useEffect(() => {
    if (!isOpen) {
      setMediaFiles([]);
      setDescription("");
    }
  }, [isOpen]);

  const handleRemoveAllMedia = () => {
    setMediaFiles([]);
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    onClose();
  };

  const handleMediaUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const filesCount = mediaFiles.length + selectedFiles.length;

      if (filesCount <= 3) {
        setMediaFiles((prevFiles: any) => [...prevFiles, ...selectedFiles]);
      } else {
        setShowLimitModal(true);
        event.target.value = ""; // Limpa o input para permitir nova seleção
      }
    } else {
      alert("Nenhum arquivo selecionado.");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const id = "6696d5451f8a82a8c8c18a7c";

      await eventsApi.uploadImage(id, mediaFiles, { description, type });

      await onUpdate();
    } catch (error) {
    } finally {
      setIsSuccess(false);
      onClose();
      setLoading(false);
      onUpdate();
    }
  };
  const teste = () => {
    alert(`Arquivos para enviar: ${mediaFiles.length}`);
    alert(`pegando dados já`);
  };

  if (!isOpen) return null;

  const mediaClass =
    mediaFiles.length === 1
      ? "one-media"
      : mediaFiles.length === 2
      ? "two-medias"
      : "three-medias";

  return (
    <section className="modal">
      <section className="modal__content">
        <section className="modal__header">
          <section className="modal__perfil">
            <div className="modal__perfil--icon">
              {name?.charAt(0).toUpperCase()}
              {name?.charAt(1).toUpperCase()}
            </div>

            <section className="modal__info">
              <h4>
                {name}
                <RiArrowDownSFill />
              </h4>
              <p>Publicar para todos</p>
            </section>
          </section>
          <section className="modal__close">
            <IoClose onClick={onClose} size={20} />
          </section>
        </section>

        <section className="modal__footer">
          <section className="modal__buttons">
            <section className="modal__button--imagem">
              <label htmlFor="mediaUpload">
                <BsImage size={29} />
              </label>
              <input
                id="mediaUpload"
                type="file"
                multiple
                accept="image/*,video/*"
                onChange={handleMediaUpload}
                style={{ display: "none" }}
              />
            </section>
            <section className="modal__button--publish">
              <button onClick={handleSubmit}>
                {" "}
                {loading ? (
                  <>
                    <div className="spinner"></div>
                    publicando...
                  </>
                ) : (
                  "publicar"
                )}
              </button>
            </section>
          </section>
        </section>
        <hr></hr>
        <section className="modal__body">
          <textarea
            placeholder="O que você deseja compartilhar sobre o evento?"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {mediaFiles.length > 0 && (
            <section className="modal__body--remove-all-media">
              <IoClose
                className="modal__body--remove-all-media--icon"
                onClick={handleRemoveAllMedia}
                size={20}
              />
            </section>
          )}

          <div className={`modal__uploaded--media ${mediaClass}`}>
            {mediaFiles.map((file: any, index: any) => {
              const fileUrl = URL.createObjectURL(file);
              return (
                <div
                  key={index}
                  className="media-container"
                  style={{ gridArea: `media${index + 1}` }}
                >
                  {file.type.startsWith("image/") ? (
                    <img
                      src={fileUrl}
                      alt={`Upload Preview ${index + 1}`}
                      className="modal__uploaded--image"
                    />
                  ) : (
                    <video controls className="modal__uploaded--video">
                      <source src={fileUrl} type={file.type} />
                      Seu navegador não suporta a tag de vídeo.
                    </video>
                  )}
                </div>
              );
            })}
          </div>
        </section>
      </section>

      {showLimitModal && (
        <div className="modal-overlay">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              backgroundColor: "white",
              padding: 20,
              borderRadius: 10,
              margin: 20,
            }}
          >
            <h3>Limite de Mídias Excedido</h3>
            <p>Você só pode adicionar até 3 mídias na publicação.</p>
            <button
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 38,
                backgroundColor: "#102a4f",
                color: "white",
              }}
              onClick={() => setShowLimitModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </section>
  );
};
