import { Navigate } from 'react-router-dom';

import ExternalLayout from '../../components/externalLayout';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // Se o usuário não estiver autenticado, redirecione para a página de login
    return <Navigate to="/initial" />;
  }

  // Se o usuário estiver autenticado, renderize as rotas internas
  return <ExternalLayout>{children}</ExternalLayout>;
};
