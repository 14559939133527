import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { RiArrowRightSLine } from "react-icons/ri";
import { MdKeyboardArrowLeft } from "react-icons/md";

interface NavBarProps {
  items: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const NavBarAdmin: React.FC<NavBarProps> = ({ items, activeTab, setActiveTab }) => {
  const navRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const scrollRight = () => {
    if (navRef.current) {
      navRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };

  const scrollLeft = () => {
    if (navRef.current) {
      navRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const updateArrows = () => {
      if (navRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = navRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
      }
    };

    updateArrows();
    if (navRef.current) {
      navRef.current.addEventListener('scroll', updateArrows);
    }
    return () => {
      if (navRef.current) {
        navRef.current.removeEventListener('scroll', updateArrows);
      }
    };
  }, []);

  return (
    <div className="navAdmin-container">
      {showLeftArrow && (
        <div className="navAdmin-arrow left" onClick={scrollLeft}>
          <MdKeyboardArrowLeft size={24} />
        </div>
      )}
      <div className="navAdmin-bar" ref={navRef}>
        {items.map((item, index) => (
          <div
            key={index}
            className={`navAdmin-item ${activeTab === item ? 'active' : ''}`}
            onClick={() => setActiveTab(item)}
          >
            {item}
          </div>
        ))}
      </div>
      {showRightArrow && (
        <div className="navAdmin-arrow right" onClick={scrollRight}>
          <RiArrowRightSLine size={24} />
        </div>
      )}
    </div>
  );
};
