import { BASE_URL, fetchWithAuth } from "../interceptors/axiosInstance";

const shedulesApi = {
  registeredEvents: async () => {
    const response = await fetchWithAuth(
      `${BASE_URL}agenda/participatingAgenda`
    );
    return response;
  },

  shedule: async () => {
    const response = await fetchWithAuth(
      `${BASE_URL}agenda/event/6696d5451f8a82a8c8c18a7c`
    );
    return response;
  },
};

export default shedulesApi;
