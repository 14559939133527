import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const useApi = () => ({
  validateToken: async (token: string) => {
    const response = await api.post("/validate", { token });
    return response.data;
  },

  signin: async (login: string, password: string) => {
    const response = await fetch(
      `https://yyxkpx5kpv.us-east-2.awsapprunner.com/api/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Tenant": "frmi",
        },
        body: JSON.stringify({ login, password }),
      }
    );

    return response.json();
  },
  logout: async () => {
    return { status: true };
    const response = await api.post("/logout");
    return response.data;
  },
});
