import { IPCircle } from "../../Iprops/IPCicle.interface";
import "./styles.scss";

export const Circle: React.FC<IPCircle> = ({ id, name, img }) => {
  return (
    <section className="wrapper__sponsor">
      <div className="wrapper__sponsor--circle">
        <img src={img} alt={name + id} />
      </div>
      {/* <div className="wrapper__sponsor--circle-name">{name}</div> */}
    </section>
  );
};
