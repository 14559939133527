import React, { ChangeEvent, useContext, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../core/contexts/auth/AuthContext";
import { ModalRegisterProps } from "../../../modules/psiu/event/IProps/modalRegister.interface";
import { ModalSuccess } from "../modalRegisterSuccess";
import "./styles.scss";

export const ModalRegister: React.FC<ModalRegisterProps> = ({
  isOpen,
  onClose,
}) => {
  const { signin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const toggleModalType = () => {
    setIsLogin(!isLogin);
  };

  const handleEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRegister = () => {
    setIsSuccess(true);
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    onClose();
  };

  const handleLogin = async () => {
    setLoading(true);
    if (email && password) {
      const isLogged = await signin(email, password);
      if (isLogged) {
        onClose();
        setLoading(false);
      } else {
        alert("Não deu certo.");
        setLoading(false);
      }
    } else {
      alert("Por favor, preencha o email e a senha.");
      setLoading(false);
    }
  };

  return (
    <>
      <section className="modalRegister">
        <section className="modalRegister__content">
          <section className="modalRegister__content--header">
            <h4>
              {isLogin ? "Entre para iniciar a publicação" : "Cadastre-se"}
            </h4>
            <IoClose onClick={onClose} size={20} />
          </section>

          <section className="modalRegister__content--body">
            {isLogin ? (
              <section>
                <h5>Email</h5>
                <input type="email" value={email} onChange={handleEmailInput} />
                <h5>Senha</h5>
                <section className="password-input">
                  <span onClick={togglePasswordVisibility}>
                    {showPassword ? <HiOutlineEyeSlash /> : <HiOutlineEye />}
                  </span>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordInput}
                  />
                </section>
                <section className="modalRegister__content--body--buttons">
                  <button
                    className="modalRegister__content--body--buttons--login"
                    onClick={handleLogin}
                  >
                    {loading ? (
                      <>
                        <div className="spinner"></div>
                        entrando....
                      </>
                    ) : (
                      "entrar"
                    )}
                  </button>
                  <div className="modalRegister__content--body--buttons--separator">
                    <hr />
                    <p>Ou</p>
                    <hr />
                  </div>
                  {/* <button className="modalRegister__content--body--buttons--google">
                    <FcGoogle /> Entrar com Google
                  </button> */}
                  <p>
                    Não possui conta? <a onClick={toggleModalType}>Registrar</a>
                  </p>
                </section>
              </section>
            ) : (
              <section>
                <div className="row">
                  <div className="input-group">
                    <h5>Nome</h5>
                    <input type="text" />
                  </div>
                  <div className="input-group">
                    <h5>CPF</h5>
                    <input type="text" />
                  </div>
                </div>
                <h5>Email</h5>
                <input type="email" />
                <div className="row">
                  <div className="input-group">
                    <h5>Senha</h5>
                    <section className="password-input">
                      <input type={showPassword ? "text" : "password"} />
                      <span onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <HiOutlineEyeSlash />
                        ) : (
                          <HiOutlineEye />
                        )}
                      </span>
                    </section>
                  </div>
                  <div className="input-group">
                    <h5>Confirmar Senha</h5>
                    <section className="password-input">
                      <input type={showConfirmPassword ? "text" : "password"} />
                      <span onClick={toggleConfirmPasswordVisibility}>
                        {showConfirmPassword ? (
                          <HiOutlineEyeSlash />
                        ) : (
                          <HiOutlineEye />
                        )}
                      </span>
                    </section>
                  </div>
                </div>
                <section className="modalRegister__content--body--buttons">
                  <button
                    className="modalRegister__content--body--buttons--login"
                    onClick={handleRegister}
                  >
                    Finalizar Cadastro
                  </button>
                  <div className="modalRegister__content--body--buttons--separator">
                    <hr />
                    <p>Ou</p>
                    <hr />
                  </div>
                  <button className="modalRegister__content--body--buttons--google">
                    <FcGoogle /> Cadastrar com Google
                  </button>
                  <p>
                    Já possui conta? <a onClick={toggleModalType}>Entrar</a>
                  </p>
                </section>
              </section>
            )}
          </section>
        </section>
      </section>
      <ModalSuccess isOpen={isSuccess} onClose={handleCloseModal} />
    </>
  );
};
