import React from "react";
import "./styles.scss";
import Skeleton from "react-loading-skeleton";

export const SpeakerScheduleCardSkeleton: React.FC  = ({
   
}) => {
  return (
    <section className="card">
      <section className="card__info">
        <section className="card__info--header">
          <section className="card__info--header--img">
            <Skeleton width={75.54} height={76.14} style={{ borderRadius: "54.85px" }} />
          </section>
          <section className="card__info--header--name">
            <Skeleton width={100} height={18.71} />
          </section>
        </section>
        <section className="card__info--description">
          <Skeleton width={175} height={19} />
        </section>
        <hr />
        <section className="card__info--location">
          <section style={{ display: "flex", flexDirection: "row" }} className="card__info--location--hour">
            <Skeleton width={100} height={20} />
          </section>
          <section className="card__info--location--stage">
            <Skeleton width={100} height={20} />
          </section>
        </section>
      </section>
    </section>
  );
};
