import React, { useState } from "react";
import { NavbarAdmin } from "../navbar";
import { Sidebar } from "../sidebar";
import "./styles.scss";

export const Admin: React.FC = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <section className="main">
      <NavbarAdmin toggleSidebar={toggleSidebar} />
      <Sidebar isVisible={isSidebarVisible} />
    </section>
  );
};
