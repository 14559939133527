import React, { useState } from "react";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export const ChangeLanguage: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const languageOptions = [
        {
            name: "Português  ",
            value: "ptBR",
            flag: "br",
        },
        // {
        //     name: "Português (Portugal)",
        //     value: "ptPT",
        //     flag: PTFlag, // Adicionar a bandeira de Portugal
        // },
        {
            name: "Español  ",
            value: "es",
            flag: "es",
        },
        {
            name: "English  ",
            value: "en",
            flag: "us",
        }
    ];


    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        setIsOpen(false); // Fecha o dropdown após a troca
    };

    return (
        <div className="changeLanguage" onClick={toggleDropdown}>
            <FiMapPin />
            <p id="location-text">{t("languageName")}</p>
            <IoIosArrowDown onClick={toggleDropdown} />
            {isOpen && (
                <div className="dropdown">
                    {languageOptions.map(option => (
                        <p
                            key={option.value}
                            onClick={() => changeLanguage(option.value)}
                            className={currentLanguage === option.value ? "selected" : ""}
                        >
                            <img src={`https://flagcdn.com/40x30/${option.flag}.png`} alt={`${option.name} flag`} style={{ width: "30px", height: "20px", marginRight: "5px" }} />
                            {option.name}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};
