import "./styles.scss";

import React, { useEffect, useState } from "react";

import eventsApi from "../../../../../../core/services/EventsApi";
import { IMediaPublication } from "../../../../event/IProps/media.interface";
import { ModalPublicationType } from "../../../../event/IProps/modalPublication.interface";
import { NavBarAdmin } from "../../tabsBody";
import { PublicationsEvent } from "../publications";
import { SubscribersEvent } from "../subscribers";

interface DetailEventProps {
  title: string;
  eventTitle: string;
  navItems: string[];
  eventId: string;
}

export const DetailEvent: React.FC<DetailEventProps> = ({
  title,
  eventTitle,
  navItems,
  eventId,
}) => {
  const [activeTab, setActiveTab] = useState(navItems[0]);
  const eventType = ModalPublicationType.PUBLICATION;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [publicationsData, setPublicationsData] = useState<IMediaPublication[]>(
    []
  );
  const [subscribeData, setSubscribeData] = useState<any[]>([]);

  const fetchPublications = async () => {
    try {
      const response = await eventsApi.getPublications(
        eventId,
        eventType,
        true
      );
      setPublicationsData(response);
    } catch (err) {
      console.error("Failed to fetch publications:", err);
      setError("Failed to fetch publications");
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscribe = async () => {
    try {
      const response = await eventsApi.getSubscribes();
      setSubscribeData(response);
    } catch (err) {
      console.error("Failed to fetch subscribes:", err);
      setError("Failed to fetch subscribes");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPublications();
    fetchSubscribe();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "Inscritos":
        return (
          <section>
            <SubscribersEvent subscribers={subscribeData} />
          </section>
        );
      case "Publicações":
        return (
          <section>
            <PublicationsEvent publications={publicationsData} />
          </section>
        );
      default:
        return "Publicações";
    }
  };

  return (
    <section className="detailEvent">
      <section className="detailEvent__title">
        <h1>{title}</h1>
        <h2>Evento: {eventTitle}</h2>
      </section>

      <section className="detailEvent__body">
        <NavBarAdmin
          items={navItems}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </section>

      <section className="detailEvent__content">{renderContent()}</section>
    </section>
  );
};
