// src/features/data/dataSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

interface DataState {
  images: string[];
  listSponsor: string[];
  videos: string[];
  aboutSponsor: string;
  description: string;
  location: string;
}

const initialState: DataState = {
  images: [],
  listSponsor: [],
  videos: [],
  aboutSponsor: "",
  description: "",
  location: "",
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<{ key: keyof DataState; value: any }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setData } = dataSlice.actions;

export const selectDataByKey = (key: keyof unknown) => (state: RootState) =>
  state.data[key];

export default dataSlice.reducer;
