import React from "react";
import { IExpositor } from "../../../modules/psiu/event/IProps/expositor.interface";
import "./styles.scss";

export const ExpositorCard: React.FC<IExpositor> = ({
  imageUrl,
  name,
  category,
}) => {
  return (
    <section className="expositorCard">
      <div className="expositorCard__image">
        <img
          src={
            imageUrl ??
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjpmZvsRQgC9JK17R3HTGetqX8J5Xy8EsfgQ&s"
          }
          alt={name}
        />
      </div>

      <div className="expositorCard__info">
        <section className="expositorCard__info--desc">
          <div className="expositorCard__info--hour">
            <p></p>
          </div>
          <div className="expositorCard__info--favorite">
            {/* <MdFavoriteBorder size={20} id="icon-favorite" /> */}
          </div>
        </section>
        <p className="expositorCard__info--name">{name}</p>
        <p className="expositorCard__info--location">{category}</p>
      </div>
    </section>
  );
};
