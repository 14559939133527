import "./styles.scss";

import React, { useEffect, useState } from "react";

import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import { EventCard } from "../eventCard";

export const MyExhibitors: React.FC = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [exhibitorsData, setExhibitorsData] = useState<
    Record<string, IEvent.Exhibitor[]>
  >({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleCardClick = () => {
    setShowDetail(true);
  };

  useEffect(() => {
    const fetchExhibitors = async () => {
      try {
        const eventId = "6696d5451f8a82a8c8c18a7c";
        const response = await eventsApi.getExhibitors(eventId);

        // Verifique se a resposta é um objeto com arrays de expositores
        if (response && typeof response === "object") {
          setExhibitorsData(response);
        } else {
          console.error("Expected an object with arrays but got:", response);
          setExhibitorsData({});
        }
      } catch (err) {
        console.error("Failed to fetch exhibitors:", err);
        setError("Failed to fetch exhibitors");
      } finally {
        setLoading(false);
      }
    };

    fetchExhibitors();
  }, []);

  const categoryTranslations: { [key: string]: string } = {
    DIAMOND: "Diamante",
    GOLD: "Ouro",
    SILVER: "Prata",
    TECH: "Tech",
    CONSTRUCTOR: "Construtora",
    Construction: "Construtora",
  };

  const getTranslatedCategory = (category: string) =>
    categoryTranslations[category] || category;

  return (
    <section className="myEvent">
      <section className="myEvent__title">
        <h1>Meus Eventos</h1>
      </section>
      <section
        className="myEvent__body"
        style={{ marginTop: "30px" }}
      >
        {loading ? (
          <p>Carregando expositores...</p>
        ) : error ? (
          <p>{error}</p>
        ) : Object.keys(exhibitorsData).length > 0 ? (
          Object.keys(exhibitorsData).map((category) => (
            <div key={category} className="expositors__category">
              <h2 className="expositors__category-title">
                {getTranslatedCategory(category)}
              </h2>
              <div className="expositors__cards">
                {exhibitorsData[category]?.map(
                  (exhibitor: IEvent.Exhibitor) => (
                    <EventCard
                      key={exhibitor.id}
                      category={getTranslatedCategory(exhibitor.category)}
                      imageUrl={
                        exhibitor.profilePicture ||
                        "https://via.placeholder.com/150"
                      }
                      title={exhibitor.title}
                      status="Ativo"
                      organizador={exhibitor.local}
                      date={exhibitor.dateStart || "--"}
                      organizadorImg="https://picsum.photos/50/50"
                      onClick={handleCardClick}
                    />
                  )
                )}
              </div>
            </div>
          ))
        ) : (
          <p>Nenhum expositor encontrado.</p>
        )}
      </section>
    </section>
  );
};
