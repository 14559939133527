import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import verified from "../../../../../assets/icons/star-verified.svg";
import { Notification } from "../../../../../shared/components/notification";
import { IPHeader } from "../../../event/IProps/header.interface";
import "./styles.scss";

export const HeaderExhibitor: React.FC<IPHeader> = ({
  title,
  month,
  hours,
  location,
  backgroundImage,
  profileImage,
  mobileBackground,
}) => {
  const [currentBackground, setCurrentBackground] = useState(backgroundImage);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setCurrentBackground(mobileBackground || backgroundImage);
      } else {
        setCurrentBackground(backgroundImage);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [backgroundImage, mobileBackground]);

  return (
    <header className="headerExhibitor" id="headerExhibitor">
      <div className="header__background" id="header__background">
        {currentBackground ? (
          <img
            src={currentBackground}
            alt="Imagem de fundo"
            className="header__background--img"
          />
        ) : (
          <Skeleton />
        )}
        <section
          className="headerExhibitor__notification"
          id="headerExhibitor__notification"
        >
          <Notification />
        </section>
        <section
          className="headerExhibitor__details"
          id="headerExhibitor__details"
        >
          <section
            className="headerExhibitor__image"
            id="headerExhibitor__image"
          >
            <img
              className="headerExhibitor__image--img"
              id="headerExhibitor__image--img"
              src={profileImage ?? ""}
              alt="Foto do perfil"
            />
            <div
              className="headerExhibitor__verified"
              id="headerExhibitor__verified"
            >
              <img
                className="headerExhibitor__verified--img"
                id="headerExhibitor__verified--img"
                src={verified}
                alt="ícone de verificado"
              />
            </div>
          </section>
          <section className="headerExhibitor__info" id="headerExhibitor__info">
            <h1
              className="headerExhibitor__info--title"
              id="headerExhibitor__info--title"
            >
              {title}
            </h1>
            <div className="headerExhibitor__local" id="headerExhibitor__local">
              {hours && (
                <p
                  className="headerExhibitor__local--date"
                  id="headerExhibitor__local--date"
                >
                  <FaCalendar /> {month}
                </p>
              )}
              <p className="headerExhibitor__local--location">
                <FaLocationDot /> {location}
              </p>
            </div>
          </section>
        </section>
      </div>
    </header>
  );
};
