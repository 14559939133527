import './styles.scss';

import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import { FaRegClock } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import SockJS from 'sockjs-client';

import {
  Client,
  CompatClient,
} from '@stomp/stompjs';

import { decodeToken } from '../../../core/utils/jwt-code/decodeToken';
import {
  Notification as NotificationType,
} from '../../Iprops/INotification.interface';

export const Notification: React.FC = () => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const stompClientRef = useRef<CompatClient | null>(null);

  const showNotification = (message: string): void => {
    const newNotification: NotificationType = { id: Date.now(), message };
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);
  };

  //Notificações de publicações de evento
  const subscribeToEventQueues = (client: any) => {
    const eventIds = ["6696d5451f8a82a8c8c18a7c"]; // Substitua com IDs reais conforme necessário
    eventIds.forEach((eventId) => {
      client.subscribe(
        `/topic/event/6696d5451f8a82a8c8c18a7c`,
        (message: any) => {
          showNotification(message.body);
        }
      );
    });
  };

  // Função para subscrever ao tópico de mensagens de usuário
  const subscribeToUserMessages = (client: any) => {
    const authToken = localStorage.getItem("authToken") as any;
    client.subscribe(
      `/topic/user/${decodeToken(authToken).userId}`,
      (message: any) => {
        showNotification(message.body);
      }
    );
  };

  useEffect(() => {
    const socket = new SockJS(
      "https://yyxkpx5kpv.us-east-2.awsapprunner.com/api/websocket"
    );
    const stompClient = new Client({
      webSocketFactory: () => socket,
      debug: (msg) => console.log(msg),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: (frame) => {
        subscribeToEventQueues(stompClient);
        subscribeToUserMessages(stompClient);
      },
      onStompError: (frame) => {
        console.error("STOMP error: " + frame.headers["message"]);
      },
      onWebSocketError: (error) => {
        console.error("WebSocket error: ", error);
      },
    });

    stompClientRef.current = stompClient as never;
    stompClient.activate();

    return () => {
      if (stompClientRef.current) {
        stompClientRef.current.deactivate();
      }
    };
  }, []);

  const removeNotification = (id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  useEffect(() => {
    const timers = notifications.map((notification) => {
      return setTimeout(() => {
        removeNotification(notification.id);
      }, 10000);
    });
    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [notifications]);

  return (
    <section className="notifications">
      {notifications.map((notification) => (
        <section key={notification.id} className="notification">
          <section
            className="notification__close"
            onClick={() => removeNotification(notification.id)}
          >
            <IoClose />
          </section>
          <section className="notification__content">
            <section className="notification__content--icon">
              <FaRegClock size={15} />
            </section>
            <section className="notification__content--description">
              <h3>{notification.message}</h3>
            </section>
            <section className="notification__content--visualize">
              <button>Visualize a publicação</button>
            </section>
          </section>
        </section>
      ))}
    </section>
  );
};
