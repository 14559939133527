import "./styles.scss";
import React, { useEffect, useState } from "react";
import eventsApi from "../../../../../core/services/EventsApi";
import { trackEvent } from "../../../../../shared/analytics";
import { InputPublication } from "../../../../../shared/components/inputPublication";
import { MediaPublication } from "../../../../../shared/components/mediaPublication";
import { IMediaPublication } from "../../IProps/media.interface";
import { ModalPublicationType } from "../../IProps/modalPublication.interface";
import { InputPublicationSkeleton } from "../skeleton/inputPublication";
import { MediaPublicationSkeleton } from "../skeleton/mediaPublication";
import PullToRefresh from 'react-pull-to-refresh';  
import { PiArrowClockwiseFill } from "react-icons/pi";  

export const Publications: React.FC = () => {
  const authToken = localStorage.getItem("authToken");
  const name = localStorage.getItem("name");
  const typePublication = ModalPublicationType.PUBLICATION;
  let islogged = false;
  let isAdm = false;
  const [role, setRole] = useState<string | null>(null);
  const [publicationsData, setPublicationsData] = useState<IMediaPublication[]>([]);
  const [loading, setLoading] = useState<boolean>(true);  
  const [refreshing, setRefreshing] = useState<boolean>(false);  
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if (authToken) {
      const base64Url = authToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const decodedToken = JSON.parse(jsonPayload);
      setRole(decodedToken.role);
      setImage(decodedToken.image);
    }
  }, [authToken]);

  islogged = role !== "GUEST";
  isAdm = role === "ADMIN";

  const fetchPublications = async (isInitialLoad = false) => {
    if (isInitialLoad) {
      setLoading(true);  
    } else {
      setRefreshing(true); 
    }

    try {
      const eventId = "6696d5451f8a82a8c8c18a7c";
      const response = await eventsApi.getPublications(eventId, typePublication, true);
      setPublicationsData(response);
    } catch (err) {
      console.error("Failed to fetch publications:", err);
      setError("Failed to fetch publications");
    } finally {
      if (isInitialLoad) {
        setLoading(false);  
      } else {
        setRefreshing(false);  
      }
    }
  };

  useEffect(() => {
    trackEvent("Button", "Click", "publicacoes");
    fetchPublications(true);  
  }, []);

  return (
    <PullToRefresh
      style={{ flex: 1 }}
      onRefresh={() => fetchPublications(false)}  
      icon={refreshing ? <PiArrowClockwiseFill className="icon-loading" /> : null}  
    >
      <section className="publication">
        <section className="publication__input">
          {loading ? (
            <InputPublicationSkeleton />
          ) : (
            <InputPublication
              name={name}
              perfil={image}
              onUpdate={() => fetchPublications(false)}
              typePublication={typePublication}
              isLogged={islogged}
            />
          )}
        </section>

        <section className="publication__posts">
          {loading ? (
            <MediaPublicationSkeleton />
          ) : (
            publicationsData.map((publication) => (
              <MediaPublication
                status={publication.status}
                typePublication={typePublication}
                onUpdate={() => fetchPublications(false)}
                key={publication.id}
                id={publication.id}
                creationDate={publication.creationDate}
                user={publication.user}
                description={publication.description}
                isAdmin={isAdm}
                bookmark={true}
                images={publication.images}
              />
            ))
          )}
        </section>
      </section>
    </PullToRefresh>
  );
};
