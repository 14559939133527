import "./styles.scss";

import React from "react";

export const SubscribersEvent: React.FC<any> = ({ subscribers }) => {
  // Acessar o array de assinantes a partir da propriedade `content`
  const content = subscribers?.content || [];

  return (
    <>
      <div className="subscribers__container">Inscritos: {subscribers.totalElements || 0}</div>
      <div className="subscribers__table-container">
        <table className="subscribers__table">
          <thead>
            <tr>
              <th className="subscribers__header-cell">Nome</th>
              <th className="subscribers__header-cell">E-mail</th>
              <th className="subscribers__header-cell">Permissão</th>

              <th className="subscribers__header-cell">Data de criação</th>
            </tr>
          </thead>
          <tbody>
            {content.length > 0 ? (
              content.map((contact: any) => (
                <tr key={contact.id}>
                  {" "}
                  {/* Use o ID único como chave */}
                  <td className="subscribers__body-cell">
                    {contact.personalData.name || "--"}
                  </td>
                  <td className="subscribers__body-cell">
                    {contact.email || "--"}
                  </td>
                  <td className="subscribers__body-cell">
                    {contact.role || "--"}
                  </td>
                  <td className="subscribers__body-cell">
                    {contact.creationDate || "--/--/--"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={3}
                  className="subscribers__body-cell"
                  style={{ textAlign: "center" }}
                >
                  Nenhum inscrito cadastrado
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <section className="divider"></section>
    </>
  );
};
