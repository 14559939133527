import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthCallback: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");
  
      console.log("Token extraído:", token);
  
      if (token) {
        localStorage.setItem("authToken", token);
        console.log("Token armazenado no LocalStorage:", token);
  
        navigate("/event");
      } else {
        console.error("Token não encontrado na URL.");
      }
    }, [location, navigate]);

    return (
        <>
            <h1>Processando autenticação...</h1>
        </>
    );
}