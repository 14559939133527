import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaSpotify,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useLocation } from "react-router-dom";
import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import { ExpositorCardShortcuts } from "../../../../../shared/components/expositorCardShortcuts";
import { ExpositorCardSkeleton } from "../../../event/components/skeleton/expositorCard";
import { RelatedEventCard } from "../relatedEventCard";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export const ShortcutsExhibitor: React.FC = () => {
  const [shortcuts, setShortcuts] = useState<IEvent.shortcutsByEventId | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const exhibitorId = location.state?.exhibitorId;
  const [eventData, setEventData] = useState<IEvent.byId>();
  const {t} = useTranslation()

  const meses: string[] = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const separarData = (data: string) => {
    const match = /^(\d{2})\/(\d{2})\/\d{4}$/.exec(data);

    if (!match) {
      console.error(
        "Formato de data inválido. O formato esperado é dd/MM/yyyy."
      );
      return null;
    }

    const [, dia, mesIndex] = match;
    const mes = meses[parseInt(mesIndex, 10) - 1]; // Convertendo o índice do mês para 0-based

    return { dia, mes };
  };

  useEffect(() => {
    getShortCutById();
    getById();
  }, []);

  const getShortCutById = async () => {
    const date = await eventsApi.getShortCutByExhibitorId(exhibitorId);
    if (date) {
      setShortcuts(date);
    }
    setLoading(false);
  };

  const getById = async () => {
    const date = await eventsApi.getById("6696d5451f8a82a8c8c18a7c");
    if (date) {
      setEventData(date);
      setLoading(false);
    }
  };

  const getTranslatedCategory = (category: string) => {
    return t(category.toUpperCase());
  };

  return (
    <section className="shortcuts">
      <section className="shortcuts__expositor">
        {loading ? "" : <h4>{t("relatedEvent")}</h4>}
        <section className="shortcuts__expositor--cards">
          {loading ? (
            Array(1)
              .fill(0)
              .map((_, index) => ExpositorCardSkeleton(index))
          ) : (
            <Link
              to="/event"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#212121",
              }}
            >
              <RelatedEventCard
                key={eventData?.id}
                id={eventData?.id ?? ""}
                imageUrl={eventData?.profilePicture}
                name={eventData?.title ?? ""}
                location={eventData?.local ?? ""}
                moth={separarData(eventData?.dateStart || "")?.mes ?? ""}
                timeEnd={eventData?.timeEnd ?? ""}
                timeStart={eventData?.timeStart ?? ""}
              />
            </Link>
          )}
          <hr />
        </section>
      </section>

      <section className="shortcuts__expositor">
        {loading ? "" : <h4>{t("discoverOtherStands")}</h4>}
        <section className="shortcuts__expositor--cards">
          {loading
            ? Array(4)
                .fill(0)
                .map((_, index) => ExpositorCardSkeleton(index))
            : shortcuts?.exhibitors.slice(0, 4).map((exhibitors) => (
                <Link
                  to="/expositor"
                  state={{ exhibitorId: exhibitors.id }}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: "#212121",
                  }}
                  key={exhibitors.id}
                >
                  <ExpositorCardShortcuts
                    key={exhibitors.id}
                    id={exhibitors.id}
                    imageUrl={exhibitors.profilePicture}
                    name={exhibitors.title}
                    description={exhibitors.shortDescription}
                    quota={getTranslatedCategory(exhibitors.quota)}
                    />
                </Link>
              ))}
          {loading ? (
            <Skeleton width={138} height={25} />
          ) : (
            <a href="#">{t("viewAll")}</a>
          )}
        </section>
      </section>
      <hr />

      <section className="shortcuts__network">
        <section className="shortcuts__network--website">
          {loading ? <Skeleton width={138} height={25} /> : <h4>Website</h4>}
          <section className="shortcuts__network--website--link">
            {loading ? (
              <Skeleton width={200} />
            ) : (
              shortcuts?.socialMedia?.site && (
                <a
                  target="_blank"
                  href={shortcuts.socialMedia?.site}
                  rel="noreferrer"
                >
                  {shortcuts.socialMedia?.site}
                </a>
              )
            )}
            <MdArrowOutward />
          </section>
        </section>

        <section className="shortcuts__network--social">
          <div className="shortcuts__network--social--title">
            {loading ? (
              <Skeleton width={138} height={25} />
            ) : (
              <h4>{t("socialMedia")}</h4>
            )}
          </div>
          <div className="shortcuts__network--social--icons">
            <>
              {loading ? (
                <Skeleton width={25} height={25} />
              ) : (
                shortcuts?.socialMedia?.spotify && (
                  <a
                    target="_blank"
                    href={shortcuts.socialMedia?.spotify}
                    rel="noreferrer"
                  >
                    <FaSpotify size={19} />
                  </a>
                )
              )}

              {loading ? (
                <Skeleton width={25} height={25} />
              ) : (
                shortcuts?.socialMedia?.facebook && (
                  <a
                    target="_blank"
                    href={shortcuts.socialMedia?.facebook}
                    rel="noreferrer"
                  >
                    <FaFacebook size={19} />
                  </a>
                )
              )}

              {loading ? (
                <Skeleton width={25} height={25} />
              ) : (
                shortcuts?.socialMedia?.linkedin && (
                  <a
                    target="_blank"
                    href={shortcuts.socialMedia?.linkedin}
                    rel="noreferrer"
                  >
                    <FaLinkedin size={19} />
                  </a>
                )
              )}

              {loading ? (
                <Skeleton width={25} height={25} />
              ) : (
                shortcuts?.socialMedia?.instagram && (
                  <a
                    target="_blank"
                    href={shortcuts.socialMedia?.instagram}
                    rel="noreferrer"
                  >
                    <FaInstagram size={19} />
                  </a>
                )
              )}

              {loading ? (
                <Skeleton width={25} height={25} />
              ) : (
                shortcuts?.socialMedia?.youtube && (
                  <a
                    target="_blank"
                    href={shortcuts.socialMedia?.youtube}
                    rel="noreferrer"
                  >
                    <FaYoutube size={19} />
                  </a>
                )
              )}

              {loading ? (
                <Skeleton width={25} height={25} />
              ) : (
                shortcuts?.socialMedia?.twitter && (
                  <a
                    target="_blank"
                    href={shortcuts.socialMedia?.twitter}
                    rel="noreferrer"
                  >
                    <FaTwitter size={19} />
                  </a>
                )
              )}
            </>
          </div>
        </section>
      </section>
    </section>
  );
};
