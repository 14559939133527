import React from "react";
import "./styles.scss";
import Skeleton from 'react-loading-skeleton';

export const HeaderSkeleton: React.FC = () => {
  const eventContainerStyle = {
    backgroundImage: `url(${<Skeleton />})`,
    backgroundSize: "cover",
  };

  return (
    <header className="headerSkeleton" id="headerSkeleton">
      <div
        className="headerSkeleton__background"
        id="headerSkeleton__background"
        style={eventContainerStyle}
      >
        <section className="headerSkeleton__details" id="headerSkeleton__details">
          <section className="headerSkeleton__image" id="headerSkeleton__image"></section>
          <section className="headerSkeleton__info" id="headerSkeleton__info">
            <h1 className="headerSkeleton__info--title" id="headerSkeleton__info--title">
              <Skeleton width={200} height={20} borderRadius={0} />
            </h1>
            <div className="headerSkeleton__local" id="headerSkeleton__local">
              <p className="headerSkeleton__local--date" id="headerSkeleton__local--date">
                <Skeleton width={24} height={24} borderRadius={0}  />  
                <Skeleton width={83} height={24} borderRadius={0}  className="skeleton--date-small"  />
              </p>
              <p
                className="skeleton__local--location"
                id="skeleton__local--location"
              >
                <Skeleton width={24} height={24} borderRadius={0}  />
                <Skeleton width={246} height={24} borderRadius={0} className="skeleton-location-small"/>
              </p>
            </div>
          </section>
        </section>
      </div>
    </header>
  );
};
