import React from "react";
import constructionImage from "../../../../assets/imgs/under_constructions_1.svg";
import * as C from "./styles";

export const ConstructionPage: React.FC = () => {
  return (
    <C.ConstructionContainer>
      <C.BackgroundImage
        src={constructionImage}
        alt="Construction Illustration"
      />
      <C.Title>Em Construção</C.Title>
      <C.Description>
        Desculpe-nos, esta página está em construção. Estamos trabalhando nela e
        logo estará disponível.
      </C.Description>
    </C.ConstructionContainer>
  );
};
