import React from "react";
import { FaRegClock } from "react-icons/fa";
import { ITagProps } from "../../Iprops/ITag.interface";
import "./styles.scss";

export const Tag: React.FC<ITagProps> = ({
  title,
  color,
  status,
  eventType,
}) => {
  const isPendingAnalysis = status === "ANALYSIS";
  const isAnnouncement = eventType === "ANNOUNCEMENT";

  const textColorClass = isPendingAnalysis
    ? "tag--primary-text"
    : "tag--specific-title-text";
  const iconColorClass = isPendingAnalysis
    ? "tag--primary-icon"
    : "tag--specific-title-icon";

  return (
    <section
      className={`tag ${isPendingAnalysis ? "" : "tag__hidden"} ${
        isAnnouncement ? "tag__hidden" : ""
      }`}
    >
      <section style={{ backgroundColor: color }} className="tag__content">
        {isPendingAnalysis && <FaRegClock className={iconColorClass} />}
        <h4 className={textColorClass}>{title}</h4>
      </section>
    </section>
  );
};
