import { BASE_URL, fetchWithAuth } from "../interceptors/axiosInstance";

const eventsApi = {
  create: async (description: string, galery: File[], coverPhoto: File, coverPhotoMobile: File, profilePicture: File) => {
    const token = localStorage.getItem("authToken");
    const url = new URL(`${BASE_URL}event`);
 
    url.searchParams.append("request", description);
 
    const formData = new FormData();

    galery.forEach((file) => formData.append("files", file));
    formData.append("coverPhoto", coverPhoto);
    formData.append("coverPhotoMobile", coverPhotoMobile);
    formData.append("profilePicture", profilePicture);

    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "X-Tenant": "frmi",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  },


  getAll: async (pagination: {
    page: number;
    size: number;
    sort: string[];
  }) => {
    const { page, size, sort } = pagination;
    const queryParams = new URLSearchParams({
      page: String(page || 0),
      size: String(size || 10),
      sort: sort.join(","),
    });
    const url = `${BASE_URL}event?${queryParams.toString()}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  uploadImage: async (id: string, files: File[], object: any) => {
    const token = localStorage.getItem("authToken");
    const url = new URL(`${BASE_URL}publication/event/${id}`);

    url.searchParams.append("type", object.type);
    url.searchParams.append("description", object.description);

    const formData = new FormData();

    files.forEach((file) => formData.append("files", file, file.name));

    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "X-Tenant": "frmi",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to upload image");
    }

    return response.json();
  },

  getPublications: async (id: string, type: string, all: boolean) => {
    const url = `${BASE_URL}publication/event/${id}?type=${encodeURIComponent(
      type
    )}&allPublication=${all}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  removePublication: async (id: string) => {
    const url = `${BASE_URL}publication/${id}`;
    return fetchWithAuth(url, { method: "DELETE" });
  },

  logoutUser: async () => {
    const url = `${BASE_URL}auth/logout`;
    return fetchWithAuth(url, { method: "POST" });
  },

  editPublication: async (
    id: string,
    newFiles: File[],
    description: string,
    oldFiles: string[]
  ) => {
    const token = localStorage.getItem("authToken");
    const url = new URL(`${BASE_URL}publication/${id}`);

    url.searchParams.append("description", description);
    oldFiles.forEach((file) => url.searchParams.append("oldFiles", file));

    const formData = new FormData();
    newFiles.forEach((file) => formData.append("newFiles", file, file.name));

    const response = await fetch(url.toString(), {
      method: "PUT",
      headers: {
        "X-Tenant": "frmi",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to edit publication");
    }

    return response.json();
  },

  approvalPublication: async (id: string, status: string) => {
    const url = `${BASE_URL}publication/approval/${id}?status=${status}`;

    return fetchWithAuth(url, {
      method: "PATCH",
      body: JSON.stringify({ status }),
    });
  },

  getExhibitors: async (eventId: string) => {
    const url = `${BASE_URL}exhibitor/findByEvent/${eventId}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  getSubscribes: async () => {
    const url = `${BASE_URL}user`;
    return fetchWithAuth(url, { method: "GET" });
  },

  getRouteImmobile: async (id: string) => {
    const url = `${BASE_URL}routeImmobile/event/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },
  getRouteImmobileById: async (id: string) => {
    const url = `${BASE_URL}routeImmobile/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  getOneExhibitor: async (id: string) => {
    const url = `${BASE_URL}exhibitor/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  participate: async (id: string) => {
    const url = `${BASE_URL}event/participate/${id}`;
    return fetchWithAuth(url, { method: "POST" });
  },

  unparticipate: async (id: string) => {
    const url = `${BASE_URL}event/participate/${id}`;
    return fetchWithAuth(url, { method: "POST" });
  },

  getById: async (id: string) => {
    const url = `${BASE_URL}event/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  getListByCompanyId: async (id: string) => {
    const url = `${BASE_URL}event/listCompany/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  getListByCategoryId: async (id: string) => {
    const url = `${BASE_URL}event/listCategory/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  getShortCutById: async (id: string) => {
    const url = `${BASE_URL}event/shortcuts/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },

  getShortCutByExhibitorId: async (id: string) => {
    const url = `${BASE_URL}exhibitor/shortcuts/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },
};

export default eventsApi;
