import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import { sponsor } from "../../../../../core/interfaces/event/sponsor.interface";
import { selectDataByKey } from "../../../../../core/reduxTest/feature/dataSlice";
import { useAppSelector } from "../../../../../core/reduxTest/hook/hook";
import eventsApi from "../../../../../core/services/EventsApi";
import { EventButtons } from "../../../../../shared/components/buttons";
import { ExpositorCardSkeleton } from "../../../event/components/skeleton/expositorCard";
import { NavBarSkeleton } from "../../../event/components/skeleton/tabsBody";
import { NavBar } from "../../../event/components/tabsBody";
import { DetailExhibitor } from "../details";
import { RelatedEventCard } from "../relatedEventCard";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export const BodyExpositor: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(t("exhibitorDetails"));
  const listSponsor = useAppSelector(
    selectDataByKey("listSponsor" as never)
  ) as sponsor.get[];
  const [eventData, setEventData] = useState<IEvent.byId>();
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const exhibitorId = location.state?.exhibitorId;
  const [shortcuts, setShortcuts] = useState<IEvent.shortcutsByEventId | null>(
    null
  );
  const tabNames = [t("exhibitorDetails")];

  const meses: string[] = [
    t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
  ];

  useEffect(() => {
    getById();
    getShortCutById();

    // Adiciona um listener para mudanças de idioma
    const handleLanguageChange = () => {
      setActiveTab(t("exhibitorDetails")); // Atualiza a aba ativa para o título traduzido
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Limpeza do efeito
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [t, i18n]);

  const getById = async () => {
    const date = await eventsApi.getById("6696d5451f8a82a8c8c18a7c");
    if (date) {
      setEventData(date);
      setLoading(false);
    }
  };

  const getShortCutById = async () => {
    const date = await eventsApi.getShortCutByExhibitorId(exhibitorId);
    if (date) {
      setShortcuts(date);
    }
    setLoading(false);
  };

  const renderContent = () => {
    switch (activeTab) {
      case t("exhibitorDetails"):
        return <DetailExhibitor />;
      default:
        return t("exhibitorDetails");
    }
  };

  const separarData = (data: string) => {
    const match = /^(\d{2})\/(\d{2})\/\d{4}$/.exec(data);
    if (!match) {
      console.error(
        "Formato de data inválido. O formato esperado é dd/MM/yyyy."
      );
      return null;
    }

    const [, dia, mesIndex] = match;
    const mes = meses[parseInt(mesIndex, 10) - 1]; // Convertendo o índice do mês para 0-based

    return { dia, mes };
  };

  return (
    <section id="body" className="body">
      <section className="body__info">
        <EventButtons />
        <section className="body__info--event">
          {loading ? "" : <h4>{t("relatedEvent")}</h4>}
          <section className="body__info--event">
            {loading ? (
              Array(1)
                .fill(0)
                .map((_, index) => ExpositorCardSkeleton(index))
            ) : (
              <Link
                to="/event"
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#212121",
                }}
              >
                <RelatedEventCard
                  key={eventData?.id}
                  id={eventData?.id ?? ""}
                  imageUrl={eventData?.profilePicture}
                  name={eventData?.title ?? ""}
                  location={eventData?.local ?? ""}
                  moth={separarData(eventData?.dateStart || "")?.mes ?? ""}
                  timeEnd={eventData?.timeEnd ?? ""}
                  timeStart={eventData?.timeStart ?? ""}
                />
              </Link>
            )}
          </section>
        </section>
      </section>
      <section className="body__tabs">
        {loading ? (
          <NavBarSkeleton />
        ) : (
          <NavBar
            items={tabNames}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </section>
      <div id="body__content" className="body__content">
        {renderContent()}
      </div>
    </section>
  );
};
