import React, { useState } from "react";
import { Exhibitors } from "../../../../../core/interfaces/exhibitors/exhibitors.interface";
import ExhibitorApi from "../../../../../core/services/exhibitorsApi";
import InputField from "../../../../../shared/components/forms/input";
import TextAreaField from "../../../../../shared/components/forms/textarea";
import ImageUpload from "../../../../../shared/components/forms/upload";
import "./styles.scss";

export const CreatExhibitors: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    idEvent: "6696d5451f8a82a8c8c18a7c",
    title: "",
    description: "",
    shortDescription: "",
    category: "",
    profilePicture: new File([""], "default.png", { type: "image/png" }),
    coverPhoto: new File([""], "default.png", { type: "image/png" }),
    coverPhotoMobile: new File([""], "default.png", { type: "image/png" }),
    address: {
      road: "",
      city: "",
      state: "",
      zipCode: "",
      number: "",
      complement: "",
    },
    local: "",
    sector: "",
    quota: "",
    company: "",
    sponsor: false,  
    aboutSponsor: "",
    socialMedia: {
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "",
      youtube: "",
      site: "",
      whatsapp: "",
      spotify: "",
    },
  });

  const [errors, setErrors] = useState<Exhibitors.create>();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name in formValues.socialMedia) {
      setFormValues((prevState) => ({
        ...prevState,
        socialMedia: {
          ...prevState.socialMedia,
          [name]: value,
        },
      }));
    } else if (name in formValues.address) {
      setFormValues((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleImageUpload = (file: File | null, type: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [type]: file,
    }));
  };

const handleSubmit = async () => {
  setIsSubmitting(true);
  setLoading(true);
  setSuccessMessage(null);

  try {
    const params = JSON.stringify({
      idEvent: formValues.idEvent,
      title: formValues.title,
      description: formValues.description,
      category: formValues.category,
      shortDescription: formValues.shortDescription,
      sponsor: formValues.sponsor,
      aboutSponsor: formValues.aboutSponsor,
      sector: formValues.sector,
      quota: formValues.quota,
      company: formValues.company,
      address: formValues.address,
      socialMedia: formValues.socialMedia,
    });
    
    console.log(formValues.profilePicture.type);
    await ExhibitorApi.create(
      params,
      formValues.profilePicture,
      formValues.coverPhoto,
      formValues.coverPhotoMobile  
    );
    setSuccessMessage("Sua solicitação foi enviada com sucesso.");
  } catch (error) {
    console.error("Erro ao enviar a solicitação:", error);
    setSuccessMessage("Ocorreu um erro ao enviar a solicitação. Tente novamente.");
  } finally {
    setIsSubmitting(false);
    setLoading(false);
  }
};


  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      sponsor: value === "sim",  
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  return (
    <section className="event-list">
      <header className="event-list__header">
        <h1 className="event-list__title">Criar Expositor</h1>
      </header>
      <div className="event-list__body">
        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">
              1. Informações básicas
            </label>

            <InputField
              name="title"
              label="Nome do expositor"
              required
              placeholder="Digite seu nome"
              value={formValues.title}
              onChange={handleChange}
            />

            <InputField
              name="category"
              label="Categoria"
              required
              placeholder="Digite sua categoria"
              value={formValues.category}
              onChange={handleChange}
            />

            <TextAreaField
              name="shortDescription"
              label="Pequena descrição"
              required
              placeholder="Conte pequenos detalhes do expositor, como a programação e os diferenciais da sua produção!"
              value={formValues.shortDescription}
              onChange={handleChange}
            />

            <TextAreaField
              name="description"
              label="Descrição"
              required
              placeholder="Conte todos os detalhes do expositor, como a programação e os diferenciais da sua produção!"
              value={formValues.description}
              onChange={handleChange}
            />
            <div className="radio-group" style={{ display: "flex", flexDirection: "column"}}>
              <label>Patrocinador? *</label>
              <label>
                <input
                  type="radio"
                  name="sponsor"
                  value="sim"
                  checked={formValues.sponsor === true}
                  onChange={handleRadioChange}
                />
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  name="sponsor"
                  value="não"
                  checked={formValues.sponsor === false}
                  onChange={handleRadioChange}
                />
                Não
              </label>
            </div>
          </div>

          
        </article>

        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">2. Imagens</label>

            <ImageUpload           
              label="Imagem de capa"
              additionalText="A dimensão recomendada é de 1440 x 159. Formato JPEG ou PNG de no máximo 5MB. Imagens com dimensões diferentes serão redimensionadas."
              onImageUpload={(file) => handleImageUpload(file, "coverPhoto")}
            />

            <ImageUpload
              label="Imagem de capa mobile"
              additionalText="A dimensão recomendada é de 1251 x 159. Formato JPEG ou PNG de no máximo 5MB. Imagens com dimensões diferentes serão redimensionadas."
              onImageUpload={(file) =>
                handleImageUpload(file, "coverPhotoMobile")
              }
            />

            <ImageUpload
              label="Imagem de perfil"
              additionalText="A dimensão recomendada é de 300 x 300. Formato JPEG ou PNG de no máximo 5MB. Imagens com dimensões diferentes serão redimensionadas."
              onImageUpload={(file) =>
                handleImageUpload(file, "profilePicture")
              }
            />
          </div>
        </article>

        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">3. Sobre o Patrocinador</label>


            <TextAreaField
              name="aboutSponsor"
              label="Descrição"
              required
              placeholder="Conte todos os detalhes do expositor, como a programação e os diferenciais da sua produção!"
              value={formValues.aboutSponsor}
              onChange={handleChange}
            />
          </div>
        </article>

        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">4. Localização</label>

            <InputField
              name="road"
              label="Rua"
              required
              placeholder="Digite a rua"
              value={formValues.address.road}
              onChange={handleChange}
            />

            <InputField
              name="city"
              label="Cidade"
              required
              placeholder="Digite a cidade"
              value={formValues.address.city}
              onChange={handleChange}
            />

            <InputField
              name="state"
              label="Estado"
              required
              placeholder="Digite o estado"
              value={formValues.address.state}
              onChange={handleChange}
            />

            <InputField
              name="zipCode"
              label="CEP"
              required
              placeholder="Digite o CEP"
              value={formValues.address.zipCode}
              onChange={handleChange}
            />

            <InputField
              name="number"
              label="Número"
              required
              placeholder="Digite o número"
              value={formValues.address.number}
              onChange={handleChange}
            />

            <InputField
              name="complement"
              label="Complemento"
              placeholder="Digite o complemento"
              value={formValues.address.complement}
              onChange={handleChange}
            />
 
          </div>
        </article>

        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">
              5. Informações adicionais
            </label>

            <InputField
              name="sector"
              label="Setor"
              required
              placeholder="Digite o setor"
              value={formValues.sector}
              onChange={handleChange}
            />

        <div className="event-card--quota">
          <label htmlFor="quota">Cota</label>
          <select
            name="quota"
            id="quota"
            value={formValues.quota.toString()}
            onChange={handleSelectChange}
            required
          >
            <option value="" disabled>
              Selecione a quota
            </option>
            <option value="1">Presente</option>
            <option value="2">Diamante</option>
            <option value="3">Ouro</option>
            <option value="4">Prata</option>
            <option value="5">Tech</option>
            <option value="6">Construtora</option>
          </select>
        </div>

            <InputField
              name="company"
              label="Empresa"
              required
              placeholder="Digite o nome da empresa"
              value={formValues.company}
              onChange={handleChange}
            />
          </div>
        </article>
        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">
              6. Redes Sociais
            </label>

            <InputField
              name="site"
              label="site"
              required
              placeholder="Digite o link do site"
              value={formValues.socialMedia.site}
              onChange={handleChange}
            />

            <InputField
              name="youtube"
              label="youtube"
              required
              placeholder="Digite o link do youtube"
              value={formValues.socialMedia.youtube}
              onChange={handleChange}
            />

            <InputField
              name="twitter"
              label="twitter"
              required
              placeholder="Digite o link do twitter"
              value={formValues.socialMedia.twitter}
              onChange={handleChange}
            />
            <InputField
              name="spotify"
              label="spotify"
              required
              placeholder="Digite o link do spotify"
              value={formValues.socialMedia.spotify}
              onChange={handleChange}
            />
            <InputField
              name="linkedin"
              label="linkedin"
              required
              placeholder="Digite o link do linkedin"
              value={formValues.socialMedia.linkedin}
              onChange={handleChange}
            />
            <InputField
              name="facebook"
              label="facebook"
              required
              placeholder="Digite o link do facebook"
              value={formValues.socialMedia.facebook}
              onChange={handleChange}
            />
            <InputField
              name="instagram"
              label="instagram"
              required
              placeholder="Digite o link do instagram"
              value={formValues.socialMedia.instagram}
              onChange={handleChange}
            />
            <InputField
              name="whatsapp"
              label="whatsapp"
              required
              placeholder="Digite o número do whatsapp"
              value={formValues.socialMedia.whatsapp}
              onChange={handleChange}
            />
          </div>
        </article>

        <div className="action-buttons">
          {/* <button className="action-button preview-button">
            Pré visualizar
          </button> */}
          <button
            className="action-button publish-button"
            onClick={handleSubmit}
          >
            Publicar
          </button>
        </div>
      </div>
    </section>
  );
};