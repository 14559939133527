import React, { ChangeEvent, DragEvent, useState } from "react";

interface ImageUploadProps {
  label: string;
  onImageUpload: (files: File[]) => void; // Callback function to pass the files to the parent component
  additionalText?: string; // Optional prop for additional text below the image upload area
}

const MultipleUploadMedia: React.FC<ImageUploadProps> = ({
  label,
  onImageUpload,
  additionalText,
}) => {
  const [images, setImages] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      validateAndUploadFiles(Array.from(files));
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      validateAndUploadFiles(Array.from(files));
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const validateAndUploadFiles = (files: File[]) => {
    const validTypes = ["image/jpeg", "image/png"];
    const validFiles: File[] = [];
    const newImages: string[] = [];

    for (const file of files) {
      if (!validTypes.includes(file.type)) {
        setError("Somente arquivos JPEG ou PNG são permitidos.");
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        // 5 MB
        setError("O arquivo deve ter no máximo 5 MB.");
        return;
      }

      validFiles.push(file);
      newImages.push(URL.createObjectURL(file));
    }

    setError(null);
    setImages((prevImages) => [...prevImages, ...newImages]);
    onImageUpload(validFiles); // Pass the files to the parent component
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    // Optionally, you can notify the parent component about the removal
  };

  return (
    <div className="image-upload">
      <label className="image-upload__label">{label}</label>
      <div
        className="image-upload__dropzone"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {images.length > 0 ? (
          <div className="image-upload__image-container">
            {images.map((image, index) => (
              <div key={index} className="image-upload__image-wrapper">
                <img src={image} alt={`Uploaded ${index + 1}`} className="image-upload__image" />
                <button
                  className="image-upload__remove-btn"
                  onClick={() => handleRemoveImage(index)}
                >
                  🗑️
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="image-upload__placeholder">
            <span className="image-upload__icon">📁</span>
            <p className="image-upload__text">Clique ou arraste as imagens</p>
          </div>
        )}
        <input
          type="file"
          accept="image/jpeg, image/png"
          multiple
          className="image-upload__input"
          onChange={handleImageChange}
        />
      </div>
      {error && <p className="image-upload__error">{error}</p>}
      {additionalText && (
        <span className="image-upload__additional-text">{additionalText}</span>
      )}
    </div>
  );
};

export default MultipleUploadMedia;
