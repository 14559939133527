import styled from "styled-components";

export const ConstructionContainer = styled.div`
  width: 100%;
  padding: 0 0 24px;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  max-width: 400px; /* Limitando a largura da imagem */
  margin-bottom: 24px;
`;
