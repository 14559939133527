import "./styles.scss";

import { ChangeEvent, useContext, useState } from "react";

import { FcGoogle } from "react-icons/fc";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../../../assets/imgs/logo-p.svg";
import { AuthContext } from "../../../../core/contexts/auth/AuthContext";
import { LoadingOverlay } from "../../../../shared/components/loading";
import { User } from "../../../../utils/interfaces/user.interface";

export const Register = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [userData, setUserData] = useState<User.register>({
    login: "",
    password: "",
    confirmPassword: "",
    name: "",
    roleId: "6655395d53b3a347f0c23181",
  });

  const handleTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(event.target.checked);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      const modalElement = document.querySelector(".modal-content");
      if (modalElement) {
        modalElement.scrollTop = 0;
      }
    }, 0);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAcceptTerms(true); // Marca o checkbox como aceito ao fechar o modal
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData((prevUserData: any) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleRegister = async (e: any) => {
    e.preventDefault();
    setIsloading(true);
    setError(null);
    setSuccess(null);

    if (
      !userData.name ||
      !userData.login ||
      !userData.password ||
      !userData.confirmPassword
    ) {
      alert("Por favor, preencha todos os campos.");
      setIsloading(false);
      return;
    }

    if (userData.password !== userData.confirmPassword) {
      alert("As senhas não coincidem.");
      setIsloading(false);
      return;
    }

    if (!acceptTerms) {
      alert("Por favor, aceite os termos.");
      setIsloading(false);
      return;
    }

    try {
      const isRegistered = await auth.register(userData); // Use o nome correto do seu serviço

      if (isRegistered.messageError) {
        // Se houver um erro de registro, defina a mensagem de erro e pare o carregamento
        setErrorMessage(isRegistered.messageError);
      } else {
        // Se o registro for bem-sucedido, navegue para a página desejada
        navigate("/auth/code-authentication", {
          state: { email: userData.login },
        });
        setErrorMessage(null); // Limpa a mensagem de erro em caso de sucesso
      }
    } catch (error) {
      // Log do erro para fins de depuração
      console.error("Erro no registro:", error);
      setErrorMessage("Ocorreu um erro inesperado. Tente novamente."); // Defina uma mensagem genérica de erro
    } finally {
      // Garante que o estado de carregamento seja removido independentemente do sucesso ou falha
      setIsloading(false);
    }
  };

  const handleGoogleLogin = () => {
    const googleAuthUrl =
      "https://yyxkpx5kpv.us-east-2.awsapprunner.com/api/oauth2/authorization/google";

    window.location.href = googleAuthUrl;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleRegister(event);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="register">
      <LoadingOverlay loading={isLoading} />
      <section className="register__left">
        <img
          src="https://semaluguel-images.s3.us-east-2.amazonaws.com/FRMI/Event/Logo+PSIU+2023+-+Cores.jpg"
          alt="logo"
        />
      </section>

      <section className="register__content">
        <section className="register__content--header">
          <img src={logo} alt="log" />
          <h2>Seja Bem - Vindo</h2>
          <p>Acesse sua conta</p>
        </section>

        <section className="register__content--body">
          <section>
            <h5>Nome</h5>
            <input
              type="text"
              placeholder="Insira seu nome"
              onChange={handleInputChange}
              value={userData.name}
              name="name"
            />
            <h5>Email</h5>
            <input
              name="login"
              type="text"
              onKeyDown={handleKeyPress}
              placeholder="Insira seu email"
              onChange={handleInputChange}
              value={userData.login}
            />
            <h5>Senha</h5>
            <section className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={userData.password}
                onKeyDown={handleKeyPress}
                onChange={handleInputChange}
                placeholder="Insira sua senha"
              />
              <span onClick={togglePasswordVisibility}>
                {showPassword ? <HiOutlineEyeSlash /> : <HiOutlineEye />}
              </span>
            </section>

            <h5>Confirmar Senha</h5>
            <section className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                value={userData.confirmPassword}
                onKeyDown={handleKeyPress}
                onChange={handleInputChange}
                placeholder="Confirme sua senha"
              />
            </section>
            <section
              className="login__content--body--buttons--terms"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ width: "15px", height: "15px", marginTop: "6px" }}
                type="checkbox"
                checked={acceptTerms}
                onChange={handleTermsChange}
              />
              <label>
                Eu aceito a{" "}
                <span
                  onClick={handleOpenModal}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Política de Privacidade
                </span>
                .
              </label>
            </section>
            <section className="register__content--body--buttons">
              <button
                onClick={handleRegister}
                className="register__content--body--buttons--login"
              >
                Cadastrar
              </button>

              <p style={{ color: "red" }}>{errorMessage}</p>
              <div className="register__content--body--buttons--separator">
                <hr />
              </div>

              <button
                className="login__content--body--buttons--google"
                onClick={handleGoogleLogin}
              >
                <FcGoogle /> Cadastrar com Google
              </button>

              <section className="login__content--body--buttons--register">
                <p>Já possui uma conta? </p>
                <Link
                  style={{ textDecoration: "none", paddingLeft: "5px" }}
                  to="/auth/login"
                >
                  {" "}
                  Entrar
                </Link>
              </section>

              <Link
                style={{
                  textDecoration: "none",
                  paddingLeft: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
                to="/auth/fale-conosco"
              >
                {" "}
                fale-conosco
              </Link>
            </section>
          </section>
        </section>
      </section>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Política de Privacidade</h2>
            </div>
            <div className="modal-body">
              <h1>Política de Privacidade para Psui Eventos</h1>

              <strong>Introdução</strong>
              <p>
                Bem-vindo ao Psui Eventos ("Aplicativo"). A sua privacidade é
                importante para nós. Esta Política de Privacidade descreve como
                coletamos, usamos, e protegemos suas informações pessoais ao
                usar nosso Aplicativo, que tem a finalidade de expor stands de
                algumas marcas, podendo o local ser on-line.
              </p>
              <br />
              <strong>1. Coleta de Informações</strong>
              <p>
                Coletamos informações que você nos fornece diretamente, tais
                como:
              </p>
              <ul>
                <li>
                  <strong>Informações de Cadastro:</strong> Nome, endereço de
                  e-mail, número de telefone, e preferências de eventos.
                </li>
                <li>
                  <strong>Informações de Perfil:</strong> Foto de perfil,
                  biografia, e outras informações que você optar por
                  compartilhar.
                </li>
                <li>
                  <strong>Informações de Pagamento:</strong> Detalhes do cartão
                  de crédito ou outras informações de pagamento quando você
                  adquire ingressos ou outros serviços.
                </li>
              </ul>
              <p>
                Também coletamos automaticamente certas informações quando você
                usa o Aplicativo, como:
              </p>
              <ul>
                <li>
                  <strong>Dados de Uso:</strong> Informações sobre como você
                  utiliza o Aplicativo, incluindo páginas visualizadas, tempo
                  gasto em cada página e cliques.
                </li>
                <li>
                  <strong>Informações de Dispositivo:</strong> Tipo de
                  dispositivo, sistema operacional, identificadores exclusivos
                  de dispositivo e dados de conexão.
                </li>
                <li>
                  <strong>Dados de Localização:</strong> Informações de
                  geolocalização, se você permitir.
                </li>
              </ul>
              <br />
              <strong>2. Uso das Informações</strong>
              <p>Utilizamos as informações coletadas para:</p>
              <ul>
                <li>Fornecer, operar e manter o Aplicativo.</li>
                <li>
                  Processar transações e enviar confirmações relacionadas.
                </li>
                <li>
                  Personalizar sua experiência com base em suas preferências e
                  interações anteriores.
                </li>
                <li>
                  Enviar notificações e atualizações sobre eventos, promoções e
                  outras comunicações relevantes.
                </li>
                <li>
                  Analisar e melhorar a eficiência do Aplicativo e de nossos
                  serviços.
                </li>
                <li>
                  Proteger contra fraudes e garantir a segurança do Aplicativo.
                </li>
              </ul>
              <br />
              <strong>3. Compartilhamento de Informações</strong>
              <p>
                Não compartilhamos suas informações pessoais com terceiros,
                exceto nas seguintes situações:
              </p>
              <ul>
                <li>
                  Com fornecedores e prestadores de serviços que nos ajudam a
                  operar o Aplicativo e fornecer nossos serviços.
                </li>
                <li>
                  Quando exigido por lei, para cumprir com processos legais ou
                  para responder a solicitações de autoridades governamentais.
                </li>
                <li>
                  Para proteger nossos direitos, privacidade, segurança ou
                  propriedade, e/ou de nossos usuários.
                </li>
                <li>
                  Com seu consentimento explícito para qualquer outra situação.
                </li>
              </ul>
              <br />
              <strong>4. Segurança das Informações</strong>
              <p>
                Implementamos medidas de segurança técnicas e organizacionais
                apropriadas para proteger suas informações pessoais contra
                acesso não autorizado, alteração, divulgação ou destruição.
              </p>
              <br />
              <strong>5. Seus Direitos</strong>
              <p>Você tem o direito de:</p>
              <ul>
                <li>Acessar e corrigir suas informações pessoais.</li>
                <li>Solicitar a exclusão de suas informações pessoais.</li>
                <li>Optar por não receber comunicações de marketing.</li>
              </ul>
              <p>
                Para exercer esses direitos, entre em contato conosco através
                dos detalhes fornecidos abaixo.
              </p>
              <br />
              <strong>6. Retenção de Dados</strong>
              <p>
                Retemos suas informações pessoais pelo tempo necessário para
                cumprir os propósitos para os quais foram coletadas, incluindo
                para atender a quaisquer requisitos legais, contábeis ou de
                relatórios.
              </p>
              <br />
              <strong>7. Alterações a esta Política</strong>
              <p>
                Podemos atualizar esta Política de Privacidade periodicamente.
                Notificaremos você sobre quaisquer alterações, publicando a nova
                política no Aplicativo.
              </p>
              <br />
              <strong>8. Contato</strong>
              <p>
                Se você tiver alguma dúvida sobre esta Política de Privacidade,
                entre em contato conosco através do seguinte e-mail:
                privacidade@psui.com.
              </p>

              <p>
                Esta Política de Privacidade foi atualizada pela última vez em
                20 de agosto de 2023.
              </p>
            </div>

            <button onClick={handleCloseModal}>Aceitar</button>
          </div>
        </div>
      )}
    </section>
  );
};
