import React, { ChangeEvent, DragEvent, useState } from "react";
import "./styles.scss";

interface ImageUploadProps {
  label: string;
  onImageUpload: (file: File | null) => void; // Callback function to pass the file to the parent component
  additionalText?: string; // Optional prop for additional text below the image upload area
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  label,
  onImageUpload,
  additionalText,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    validateAndUploadFile(file);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    validateAndUploadFile(file);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const validateAndUploadFile = (file: File | undefined) => {
    if (!file) return;

    const validTypes = ["image/jpeg", "image/png"];
    if (!validTypes.includes(file.type)) {
      setError("Somente arquivos JPEG ou PNG são permitidos.");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      // 5 MB
      setError("O arquivo deve ter no máximo 5 MB.");
      return;
    }

    setError(null);
    const imageUrl = URL.createObjectURL(file);
    setImage(imageUrl);
    onImageUpload(file); // Pass the file to the parent component
  };

  const handleRemoveImage = () => {
    setImage(null);
    onImageUpload(null); // Notify parent component about removal
  };

  return (
    <div className="image-upload">
      <label className="image-upload__label">{label}</label>
      <div
        className="image-upload__dropzone"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {image ? (
          <div className="image-upload__image-container">
            <img src={image} alt="Uploaded" className="image-upload__image" />
            <button
              className="image-upload__remove-btn"
              onClick={handleRemoveImage}
            >
              🗑️
            </button>
          </div>
        ) : (
          <div className="image-upload__placeholder">
            <span className="image-upload__icon">📁</span>
            <p className="image-upload__text">Clique ou arraste a imagem</p>
          </div>
        )}
        <input
          type="file"
          accept="image/jpeg, image/png"
          className="image-upload__input"
          onChange={handleImageChange}
        />
      </div>
      {error && <p className="image-upload__error">{error}</p>}
      {additionalText && (
        <span className="image-upload__additional-text">{additionalText}</span>
      )}
    </div>
  );
};

export default ImageUpload;
