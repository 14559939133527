import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import verified from "../../../../../assets/icons/star-verified.svg";
import { Notification } from "../../../../../shared/components/notification";
import { IPHeader } from "../../IProps/header.interface";
import "./styles.scss";

export const Header: React.FC<IPHeader> = ({
  title,
  month,
  hours,
  location,
  backgroundImage,
  profileImage,
  mobileBackground
}) => {

  const [currentBackground, setCurrentBackground] = useState(backgroundImage);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setCurrentBackground(mobileBackground || backgroundImage);
      } else {
        setCurrentBackground(backgroundImage);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [backgroundImage, mobileBackground]);


  return (
    <header className="header" id="header">
      <div className="header__background" id="header__background">         
      {currentBackground ? (
          <img
            src={currentBackground}
            alt="Imagem de fundo"
            className="header__background--img"
          />
        ) : (
          <Skeleton />
        )}

        <section className="header__notification" id="header__notification">
          <Notification />
        </section>
        <section className="header__details" id="header__details">
          <section className="header__image" id="header__image">
            <img
              className="header__image--img"
              id="header__image--img"
              src={profileImage ?? ""}
              alt="Foto do perfil"
            />
            <div className="header__verified" id="header__verified">
              <img
                className="header__verified--img"
                id="header__verified--img"
                src={verified}
                alt="ícone de verificado"
              />
            </div>
          </section>
          <section className="header__info" id="header__info">
            <h1 className="header__info--title" id="header__info--title">
              {title}
            </h1>
            <div className="header__local" id="header__local">
              {hours && (
                <p className="header__local--date" id="header__local--date">
                  <FaCalendar /> {month}
                </p>
              )}
              <p className="header__local--location">
                <FaLocationDot /> {location}
              </p>
            </div>
          </section>
        </section>
      </div>
    </header>
  );
};
