import React, { useState } from "react";
import "./styles.scss";
import Skeleton from "react-loading-skeleton";

export const InputPublicationSkeleton: React.FC = () => {

    return (
        <section className="inputPublicationSkeleton">
          <section className="inputPublicationSkeleton__content">
            <img id="inputPublicationSkeleton__image" />
            <div className="inputPublicationSkeleton__input-wrapper">
              <div className="inputPublicationSkeleton__input-wrapper-content">
              <Skeleton className="inputPublicationSkeleton__skeleton" width={175} height={19} />
              </div>
            </div>
          </section>
        </section>
      );
    };