import { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaSpotify,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { SlGlobe } from "react-icons/sl";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export const DetailExhibitor: React.FC = () => {
  const [eventData, setEventData] = useState<IEvent.ExhibitorById>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const exhibitorId = location.state?.exhibitorId;
  const {t} = useTranslation()

  useEffect(() => {
    getById();
  }, []);

  const getById = async () => {
    const data = await eventsApi.getOneExhibitor(exhibitorId);
    if (data) {
      setEventData(data);
      setLoading(false);
    } else {
      setError("Erro ao carregar os dados.");
      setLoading(false);
    }
  };

  return (
    <div className="containe">
      <section id="sponsors">
        <section id="containe-sponsors" className="containe__section">
          {/* Seção de patrocinadores pode ir aqui */}
        </section>
      </section>

      <section className="containe__section">
        {loading ? (
          <Skeleton
            width={176}
            height={25}
            style={{ marginBottom: 10, marginTop: 64 }}
            borderRadius={0}
          />
        ) : (
          <h2 id="details">{t("exhibitorDetails")}</h2>
        )}

        {loading ? (
          <Skeleton
            height={633}
            width={"100%"}
            borderRadius={0}
            className="skeleton-map"
          />
        ) : (
          <article className="containe__section-article">
            <div
              dangerouslySetInnerHTML={{ __html: eventData?.description || "" }}
            />
          </article>
        )}
      </section>
      <hr />
      
      {eventData?.aboutSponsor && (
        <section className="containe__section">
          <h2>Sobre os Produtores</h2>
          <article className="containe__section-article">
            <div
              dangerouslySetInnerHTML={{
                __html: eventData?.aboutSponsor || "",
              }}
            />
          </article>
          <div className="btn--productor" id="register-button">
            <button>Fale com o Produtor</button>
          </div>
        </section>
      )}

      <section className="details__social" style={{ paddingLeft: "20px" }}>
        <section className="details__social--website">
          <section className="details__social--website--link">
            {loading ? (
              <Skeleton width={200} />
            ) : (
              eventData?.socialMedia?.site && (
                <>
                  <SlGlobe />
                  <a
                    target="_blank"
                    href={eventData.socialMedia.site}
                    rel="noreferrer"
                  >
                    {eventData.socialMedia.site}
                  </a>
                </>
              )
            )}
          </section>
        </section>

        <section className="details__social--network">
          <div className="details__social--network--title">
            {loading ? (
              <Skeleton width={138} height={25} />
            ) : (
              (eventData?.socialMedia?.facebook ||
                eventData?.socialMedia?.instagram ||
                eventData?.socialMedia?.linkedin ||
                eventData?.socialMedia?.spotify) && <h3>{t("socialMedia")}</h3>
            )}
          </div>
          <div className="details__social--network--icons">
            {loading ? (
              <Skeleton width={25} height={25} />
            ) : (
              <>
                {eventData?.socialMedia?.spotify && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.spotify}
                    rel="noreferrer"
                  >
                    <FaSpotify size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.facebook && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.facebook}
                    rel="noreferrer"
                  >
                    <FaFacebook size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.linkedin && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.linkedin}
                    rel="noreferrer"
                  >
                    <FaLinkedin size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.instagram && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.instagram}
                    rel="noreferrer"
                  >
                    <FaInstagram size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.youtube && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.youtube}
                    rel="noreferrer"
                  >
                    <FaYoutube size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.twitter && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.twitter}
                    rel="noreferrer"
                  >
                    <FaTwitter size={19} />
                  </a>
                )}
              </>
            )}
          </div>
        </section>
      </section>
    </div>
  );
};
