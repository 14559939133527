import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import helpApi from "../../../../core/services/helpApi";
import InputField from "../../../../shared/components/forms/input";
import TextAreaField from "../../../../shared/components/forms/textarea";
import "./styles.scss";
interface FormErrors {
  name: string;
  email: string;
  subject: string;
  message: string;
}
const SupportUrgent: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState<FormErrors>({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
    setErrors({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
    setSuccessMessage(null);
  };

  const validate = (): boolean => {
    let valid = true;
    const newErrors: FormErrors = {
      name: "",
      email: "",
      subject: "",
      message: "",
    };

    if (!formData.name.trim()) {
      newErrors.name = "Nome é obrigatório.";
      valid = false;
    }
    // if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
    //   newErrors.email = "E-mail válido é obrigatório.";
    //   valid = false;
    // }

    if (!formData.subject.trim()) {
      newErrors.subject = "Assunto é obrigatório.";
      valid = false;
    }
    if (!formData.message.trim()) {
      newErrors.message = "Mensagem é obrigatória.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validate()) return; // Não prosseguir se houver erros

    setIsSubmitting(true);
    setSuccessMessage(null);
    setLoading(true);

    try {
      // Simulação de envio
      await helpApi.create(formData);
      setLoading(true);
      resetForm();
      setSuccessMessage("Sua solicitação foi enviada com sucesso.");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      setErrors({ ...errors, message: "Ocorreu um erro. Tente novamente." });
      resetForm();
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      resetForm();
    }
  };

  const redirect = async () => {
    navigate(-1); // Volta para a página anterior
  };

  return (
    <section className="support-urgent">
      <h1 className="support-urgent__title">
        Preencha o formulário abaixo que entraremos em contato
      </h1>
      {successMessage && (
        <p className="support-urgent__success">{successMessage}</p>
      )}
      <form className="support-urgent__form" onSubmit={handleSubmit}>
        <InputField
          label="Nome"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          placeholder="Digite seu nome"
          error={errors.name}
        />
        <InputField
          label="E-mail"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          placeholder="Digite seu e-mail"
          error={errors.email}
        />
        <InputField
          label="Assunto"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          placeholder="Digite o assunto"
          error={errors.subject}
        />
        <TextAreaField
          label="Mensagem"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          placeholder="Digite sua mensagem"
          error={errors.message}
        />
        <div className="support-urgent__form--buttons">
        <button
          type="submit"
          className="support-urgent__button"
          disabled={isSubmitting}
          aria-busy={isSubmitting}
        >
          {loading ? (
            <>
              <div className="spinner"></div>
              enviando...
            </>
          ) : (
            "Enviar"
          )}
        </button>
        {/* <button
          onClick={redirect}
          type="submit"
          className="support-urgent__button"
          style={{ backgroundColor: "#fff", color: "#000", border: "1px solid #000" }}
          disabled={isSubmitting}
          aria-busy={isSubmitting}
        >
          voltar
        </button> */}
        </div>
      </form>
    </section>
  );
};

export default SupportUrgent;
