import "./styles.scss";

import React, { useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import { sponsor } from "../../../../../core/interfaces/event/sponsor.interface";
import { selectDataByKey } from "../../../../../core/reduxTest/feature/dataSlice";
import { useAppSelector } from "../../../../../core/reduxTest/hook/hook";
import eventsApi from "../../../../../core/services/EventsApi";
import { EventButtons } from "../../../../../shared/components/buttons";
import { Circle } from "../../../../../shared/components/circle";
import { Announcement } from "../annoucement";
import { Detail } from "../details";
import { Expositors } from "../expositors";
import { PropertyRoute } from "../propertyRoute";
import { Publications } from "../publications";
import { Schedule } from "../schedule";
import { NavBarSkeleton } from "../skeleton/tabsBody";
import { Speakers } from "../speakers";
import { NavBar } from "../tabsBody";
import { useTranslation } from "react-i18next";

interface BodyProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const Body: React.FC<BodyProps> = ({ activeTab, setActiveTab }) => {
  const listSponsor = useAppSelector(
    selectDataByKey("listSponsor" as never)
  ) as sponsor.get[];
  const [eventData, setEventData] = useState<IEvent.byId>();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const tabNames = [
    t("details"),
    t("announcement"),
    t("feed"),
    t("exhibitors"),
    t("speakers"),
    t("propertyRoute"),
    t("eventSchedule"),
  ];

  useEffect(() => {
    getById();
  }, []);

  const getById = async () => {
    const date = await eventsApi.getById("6696d5451f8a82a8c8c18a7c");
    if (date) {
      setEventData(date);
      setLoading(false);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case t("details"):
        return <Detail />;
      case t("announcement"):
        return (
          <section className="body__info--content">
            <Announcement />
          </section>
        );
      case t("feed"):
        return (
          <section className="body__info--content">
            <Publications />
          </section>
        );
      case t("exhibitors"):
        return (
          <section className="body__info--speakers">
            <Expositors />
          </section>
        );
      case t("speakers"):
        return (
          <section className="body__info--speakers">
            <Speakers />
          </section>
        );
      case t("propertyRoute"):
        return (
          <section className="body__info--speakers">
            <PropertyRoute />
          </section>
        );
      case t("eventSchedule"):
        return (
          <section className="body__info--speakers">
            <Schedule />
          </section>
        );
      default:
        return <Detail />;
    }
  };

  return (
    <section id="body" className="body">
      <section className="body__info">
        <EventButtons />
        <section className="body__info--sponsors">
          {loading ? "" : <h4>Patrocinadores</h4>}
          <section className="body__info--sponsors--perfil">
            {loading
              ? Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton
                      key={index}
                      height={51}
                      width={51}
                      borderRadius={"50%"}
                    />
                  ))
              : listSponsor.map((sponsor) => (
                  <Link
                    to="/expositor"
                    state={{ exhibitorId: sponsor.id }}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Circle
                      key={sponsor.id}
                      name={sponsor.name}
                      img={sponsor.profilePicture}
                    />
                  </Link>
                ))}
          </section>
        </section>
      </section>
      <section className="body__tabs">
        {loading ? (
          <NavBarSkeleton />
        ) : (
          <NavBar
            items={tabNames}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </section>
      <div id="body__content" className="body__content">
        {renderContent()}
      </div>
    </section>
  );
};
