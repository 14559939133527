import { ReactNode } from "react";

import { Menu } from "../menu";
// import { Shortcuts } from "../commons/shortcuts/shortcuts";

interface ExternalLayoutProps {
  children: ReactNode;
}

const InternalLayout: React.FC<ExternalLayoutProps> = ({ children }) => {
  return (
    <div>
      {/* <Menu></Menu> */}
      {children}
      {/* <Footer /> */}
    </div>
  );
};

export default InternalLayout;
