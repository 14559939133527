import React, { useState, useEffect, useRef } from "react";
import { FiEdit, FiTrash, FiMoreVertical } from "react-icons/fi";
import styled from "styled-components";
import { DropdownButton, DropdownContainer, DropdownIcon, DropdownMenu, DropdownOption, DropdownText } from "./styles";

interface DropdownProps {
  onEdit: () => void;
  onRemove: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({ onEdit, onRemove }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = () => {
    onEdit();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownIcon onClick={handleToggleDropdown} />
      <DropdownMenu isOpen={isOpen}>
        <DropdownButton onClick={handleEdit}>
          <FiEdit style={{ fontSize: "16px" }} />
          <DropdownText style={{ fontSize: "14px" }}>Editar</DropdownText>
        </DropdownButton>
        <DropdownOption onClick={onRemove}>
          <FiTrash style={{ fontSize: "16px" }} />
          <DropdownText style={{ fontSize: "14px" }}>Excluir</DropdownText>
        </DropdownOption>
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;
