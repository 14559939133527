import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ExternalLayout from "../core/components/externalLayout";
import { ConstructionPage } from "../modules/generic/pages/construction";
import { AutoLogin } from "../modules/generic/pages/guestLogin";
import SupportUrgent from "../modules/generic/pages/help";
import { Login } from "../modules/generic/pages/login";
import { Register } from "../modules/generic/pages/register";
import { useEffect } from "react";
import { Maintenance } from "../modules/generic/pages/maintenance";
import { AuthCallback } from "../modules/generic/pages/register/AuthCallback";
import { CodeAuthentication } from "../modules/generic/pages/codeAuthentication";

const ExternalRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const currentPath = window.location.pathname;
      const hasRedirected = sessionStorage.getItem("hasRedirectedToMobile");

      if (
        window.innerWidth <= 480 &&
        !hasRedirected &&
        (currentPath === '/' || currentPath === '' )
      ) {
        sessionStorage.setItem("hasRedirectedToMobile", "true");
        navigate('/initial');
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  return (
    <ExternalLayout>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/guest" element={<AutoLogin />} />
        <Route path="/cadastrar" element={<Register />} />
        <Route path="/callback" element={<AuthCallback />} />
        <Route path="/code-authentication" element={<CodeAuthentication />} />
        <Route path="/construction" element={<ConstructionPage />} />
        <Route path="/" element={<Maintenance />} />
        <Route path="*" element={<Maintenance />} />
        {/* <Route path="/fale-conosco" element={<SupportUrgent />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login/guest" />} /> */}
      </Routes>
    </ExternalLayout>
  );
};

export default ExternalRoutes;
