import { Navigate, Route, Routes } from "react-router-dom";
import ExternalRoutes from "./externalRoutes";
import InternalRoutes from "./internalRoutes";
import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { Maintenance } from "../modules/generic/pages/maintenance";

const RoutesApp = () => {
  return (
    <SkeletonTheme baseColor="#D6D6D6" highlightColor="#f5f5f5">
      <Routes>
        <Route path="/auth/*" element={<ExternalRoutes />} />
        <Route path="/*" element={<InternalRoutes />} />
        <Route path="/" element={<Navigate to="/initial" />} />
        <Route path="/" element={<Maintenance />} />
        <Route path="*" element={<Maintenance />} />
      </Routes>
    </SkeletonTheme>
  );
};

export default RoutesApp;
