import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../core/contexts/auth/AuthContext";

export const AutoLogin = () => {
  const { signin } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogin = async () => {
      // Substitua 'email' e 'password' com as credenciais que deseja usar para o login automático
      const email = "guest";
      const password = "guest";

      const isLogged = await signin(email, password);

      if (isLogged) {
        navigate("/event");
      } else {
        alert("Não foi possível realizar o login.");
      }
    };

    handleLogin();
  }, [signin, navigate]);

  return null; // O componente não precisa renderizar nada
};
