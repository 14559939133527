import React from "react";
import "./styles.scss"; // Certifique-se de incluir o CSS aqui

interface LoadingOverlayProps {
  loading: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="fullscreen-spinner-overlay">
      <div className="fullscreen-spinner"></div>
    </div>
  );
};
