import Skeleton from "react-loading-skeleton"
import React from "react";
  export const SpeakerCardSkeleton: React.FC = ({
}) => {
    return (  
        <div className="speakersSkeleton">
        <section className="speakersSkeleton__content">
         
            <div className="speakersSkeleton__content--card">
              <Skeleton width={162} height={188} className="speakersSkeleton__content--card--image" />
              <div className="speakersSkeleton__content--card---details">
                <Skeleton height={17} width={162} borderRadius={0} />
                <Skeleton height={11} width={103} borderRadius={0}  />
                <Skeleton height={11} width={103} borderRadius={0}  />
              </div>
            </div>
         
        </section>
      </div>      
    );
}