import React, { ReactNode } from "react";
interface ExternalLayoutProps {
  children: ReactNode;
}

const ExternalLayout: React.FC<ExternalLayoutProps> = ({ children }) => {
  return (
    <div>
      <main>{children}</main>
    </div>
  );
};

export default ExternalLayout;
