import React from 'react';
import './styles.scss';
import "./styles.scss";
import Skeleton from 'react-loading-skeleton';

export const NavBarSkeleton: React.FC = ({ }) => {
    return (
        <div className="navSkeleton-container">

            <div className="navSkeleton-bar"  >
                <section className="navSkeleton-item">
                    <Skeleton height={24} width={134} borderRadius={0} />
                </section>          
                <section className="navSkeleton-item">
                    <Skeleton height={24} width={134} borderRadius={0} />
                </section>          
                <section className="navSkeleton-item">
                    <Skeleton height={24} width={134} borderRadius={0} />
                </section>          
                <section className="navSkeleton-item">
                    <Skeleton height={24} width={134} borderRadius={0} />
                </section>           
                <section className="navSkeleton-item">
                    <Skeleton height={24} width={134} borderRadius={0} />
                </section>           
                <section className="navSkeleton-item">
                    <Skeleton height={24} width={134} borderRadius={0} />
                </section>
            </div>

            <div className="navSkeleton-arrow right"  >
                <Skeleton height={24} width={24} borderRadius={0} />
            </div>

        </div>
    );
};
