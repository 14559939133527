import React, { useState } from 'react';
import "./styles.scss";
import eventsApi from '../../../../../core/services/EventsApi';

interface PostModalProps {
    post: any;
    onClose: () => void;
}

export const SharedPublication: React.FC<PostModalProps> = ({ post, onClose }) => {
    const [loadingApprove, setLoadingApprove] = useState(false);
    const [loadingReject, setLoadingReject] = useState(false);

    if (!post) return null;

    const handleApproval = async (status: string) => {
        if (status === 'APPROVED') {
            setLoadingApprove(true);
        } else if (status === 'DENIED') {
            setLoadingReject(true);
        }

        try {
            await eventsApi.approvalPublication(post.id, status);
            onClose();
        } catch (error) {
            console.error("Error approving/rejecting post:", error);
        } finally {
            setLoadingApprove(false);
            setLoadingReject(false);
        }
    };

    const translateStatus = (status: string): string => {
        switch (status) {
          case 'ANALYSIS':
            return 'Análise';
          case 'APPROVED':
            return 'Aprovado';
          case 'DENIED':
            return 'Negado';
          default:
            return status;
        }
    };

    return (
        <div className="sharedModal">
            <div className="sharedModal__content">
                <span className="sharedModal__close" onClick={onClose}>&times;</span>
                <h2 className="sharedModal__title">Solicitação de Post</h2>
                <div className="sharedModal__details">
                    <div className="sharedModal__image-container">
                        {post.images && post.images.length > 0 ? (
                            <img className="sharedModal__image" src={post.images[0]} alt="Post" />
                        ) : (
                            <div className="sharedModal__image--placeholder"></div>
                        )}
                    </div>
                    <div className="sharedModal__description">
                        <h3>Descrição</h3>
                        <p className="sharedModal__description-text" style={{height: 'auto', marginBottom: '24px'}}>{post.description}</p>
                        <div className="sharedModal__info"   >
                            <p><strong>Nome:</strong> {post.user.name}</p>
                            <p><strong>Data:</strong> {new Date(post.creationDate).toLocaleDateString()}</p>
                            <p><strong>Evento:</strong> Evento 1</p>
                            <p><strong>Stand:</strong> Stand 1</p>
                            <p><strong>Status:</strong> {translateStatus(post.status)}</p>
                            <p><strong>Atualizado por:</strong> {post.user.name}</p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="sharedModal__actions">
                    <button onClick={() => handleApproval('DENIED')} className="sharedModal__actions--reject">
                        {loadingReject ? (
                            <>
                                <div className="spinner"></div>
                                Recusando...
                            </>
                        ) : (
                            "Recusar"
                        )}
                    </button>
                    <button onClick={() => handleApproval('APPROVED')} className="sharedModal__actions--approve">
                        {loadingApprove ? (
                            <>
                                <div className="spinner"></div>
                                Aprovando...
                            </>
                        ) : (
                            "Aprovar"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};
