import React, { useEffect, useState } from "react";
import { Speaker } from "../../IProps/speaker.interface";
import "./styles.scss";
import { speakersApi } from "../../../../../core/services/SpeakersApi";

interface SpeakerDetailProps {
  speakerId: string;
  onBack: () => void;
}

export const SpeakerDetail: React.FC<SpeakerDetailProps> = ({ speakerId, onBack }) => {
  const [speakers, setSpeakers] = useState<Speaker>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSpeakers = async () => {
      try {
        const response = await speakersApi.getById(speakerId);
        setSpeakers(response);
      } catch (err) {
        setError("Failed to fetch speakers");
      } finally {
        setLoading(false);
      }
    };

    fetchSpeakers();
  }, [speakerId]);

  return (
    <div className="speakerDetail">
        <button className="speakerDetail__back-button" onClick={onBack}>
          ←
        </button>
 
      <section className="speakerDetail__content">
        <section className="speakerDetail__content--image">
          <img
            src={speakers?.profilePicture}
            alt={speakers?.name}
          />
        </section>

        <section className="speakerDetail__details">
          <h2 className="speakerDetail__details--name">{speakers?.name}</h2>
          <p className="speakerDetail__details--office">{speakers?.office}</p>
          <p className="speakerDetail__details--office">{speakers?.company ?? "Função"}</p>

          <hr />
          <h4>Descrição</h4>
          <div
            className="speakerDetail__details--description"
            dangerouslySetInnerHTML={{ __html: speakers?.description || `` }}
          />
        </section>
      </section>
    </div>
  );
};
