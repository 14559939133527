// InputField.tsx
import React from "react";
import "./styles.scss";

interface InputFieldProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  name?: string;
  error?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  required = false,
  placeholder,
  value,
  name,
  error,
  onChange,
}) => {
  return (
    <div className="input-field-container">
      <label htmlFor={name} className="input-field-container__label">
        {label}
        {required && <span className="input-field-container__required">*</span>}
      </label>
      <div className="input-field-container__input-wrapper">
        <input
          name={name}
          type="textarea"
          className="input-field-container__input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
      {error && <p className="input-field-container__error">{error}</p>}
    </div>
  );
};

export default InputField;
