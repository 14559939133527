import { BASE_URL, fetchWithAuth } from "../interceptors/axiosInstance";

const helpApi = {
  create: async (data: any) => {
    const url = `${BASE_URL}user/help`;
    return fetchWithAuth(url, {
      method: "POST",
      body: JSON.stringify(data),
    });
  },
};
export default helpApi;
