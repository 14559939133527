import "./styles.scss";

import { useEffect, useState } from "react";

import { BiBath } from "react-icons/bi";
import { BsTextarea } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { MdOutlineMeetingRoom } from "react-icons/md";

import eventsApi from "../../../../../core/services/EventsApi";
import {
  IRouteImmobile,
  IRouteImmobileDetail,
} from "../../IProps/property.interface";

export const PropertyDetail: React.FC<IRouteImmobileDetail> = ({
  routeId,
  onBack,
}) => {
  const [route, setroute] = useState<IRouteImmobile>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRoute = async () => {
      try {
        const response = await eventsApi.getRouteImmobileById(routeId);
        setroute(response);
      } catch (err) {
        setError("Failed to fetch route");
      } finally {
        setLoading(false);
      }
    };

    fetchRoute();
  }, [routeId]);

  const statusTranslations: { [key: string]: string } = {
    READY: "pronto",
    LAUNCH: "lançamento",
    UNDER_CONSTRUCTION: "em construção",
    SOON_LAUNCH: "breve lançamento",
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case "READY":
        return "propertyDetail__content--image--status-ready";
      case "LAUNCH":
        return "propertyDetail__content--image--status-launch";
      case "UNDER_CONSTRUCTION":
        return "propertyDetail__content--image--status-under-construction";
      case "SOON_LAUNCH":
        return "propertyDetail__content--image--status-soon-launch";
      default:
        return "";
    }
  };

  return (
    <div className="propertyDetail">
      <button className="propertyDetail__back-button" onClick={onBack}>
        ←
      </button>

      <section className="propertyDetail__content">
        <section className="propertyDetail__content--image">
          <p
            className={`propertyDetail__content--image--status ${getStatusClass(
              route?.status || ""
            )}`}
          >
            {statusTranslations[route?.status || ""] || route?.status}
          </p>
          <img src={route?.images[0]} alt={route?.title} />
        </section>

        <section className="propertyDetail__details">
          <h2 className="propertyDetail__details--name">{route?.title}</h2>
          <p className="propertyDetail__details--location">
            <FiMapPin /> {route?.title}
          </p>
          <section className="propertyDetail__details--info">
            <p>
              <MdOutlineMeetingRoom size={24} /> {route?.room} quartos
            </p>
            <p>
              <BiBath size={24} />
              {route?.suites} suítes{" "}
            </p>
            <p>
              <BsTextarea size={24} />
              {route?.size} m2
            </p>
          </section>

          <hr />
          <h4>Descrição</h4>
          <div
            className="propertyDetail__details--description"
            dangerouslySetInnerHTML={{ __html: route?.description || `` }}
          />
          <section className="propertyDetail__details--map">
            <h4>Localização</h4>
            <section
              style={{
                width: "100%",
                height: "181px",
                marginRight: "20px",
                backgroundColor: "gray",
              }}
            ></section>
          </section>
          <section className="propertyDetail__details--contact">
            <h4>Contato</h4>
            <p>{route?.contact}</p>
          </section>
          <hr />
          <button disabled={true} className="propertyDetail__details--button">
            Entrar em contato
          </button>
        </section>
      </section>
    </div>
  );
};
