import React, { useEffect, useState } from "react";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { CiBookmark } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import verified from "../../../../../../assets/icons/star-verified.svg";
import "./styles.scss";
import Skeleton from "react-loading-skeleton";
import Dropdown from "../../../../../../shared/components/drowdown";

export const MediaPublicationSkeleton: React.FC = ({
}) => {
    return (   
        <>
        <section className="mediaPublicationSkeleton">
            <section className="mediaPublicationSkeleton__header">
                <div className="mediaPublicationSkeleton__header--profile-container">
                    <img />
                    <img src={verified} alt="Verificado" id="verified-star" />
                </div>
                <section className="mediaPublicationSkeleton__header--title">
                    <section className="mediaPublicationSkeleton__header--title--name">
                        <Skeleton height={17} width={143} />
                        <Skeleton height={17} width={163} />

                    </section>
                </section>
                <section className="mediaPublicationSkeleton__header--icon">

                    <section className="mediaPublicationSkeleton__header--dropdown">
                        <Dropdown onEdit={() => { }} onRemove={() => { }} />
                    </section>

                </section>
            </section>
            <section className="mediaPublicationSkeleton__content"> <Skeleton height={372} width={'100%'} /> </section>
            <section className="mediaPublicationSkeleton__footer">
                {/* <div className="mediaPublicationSkeleton__footer--info">
                    <div className="mediaPublicationSkeleton__footer--info--like">
                        <img/>
                        <img/>
                        <img/>
                        <img/>
                    </div>
                    <Skeleton height={17} width={225} borderRadius={0} />
                </div> */}
                {/* <div className="mediaPublicationSkeleton__footer--viewer">
                    <Skeleton height={17} width={225} borderRadius={0} />
                </div>
            </section>
            <hr />
            <section className="mediaPublicationSkeleton__actions">
                <section className="mediaPublicationSkeleton__actions--buttons">
                    <button >
                       <Skeleton height={24} width={24} borderRadius={0} />
                       <Skeleton height={24} width={72} borderRadius={0} />
                    </button>
                    <button>
                    <Skeleton height={24} width={24} borderRadius={0} />
                    <Skeleton height={24} width={72} borderRadius={0} />
                     </button>
                </section>
                <div className="mediaPublicationSkeleton__actions--bookmark">
                <Skeleton height={24} width={24} borderRadius={0} />
                </div> */}
            </section>

        </section> <section className="mediaPublicationSkeleton">
            <section className="mediaPublicationSkeleton__header">
                <div className="mediaPublicationSkeleton__header--profile-container">
                    <img />
                    <img src={verified} alt="Verificado" id="verified-star" />
                </div>
                <section className="mediaPublicationSkeleton__header--title">
                    <section className="mediaPublicationSkeleton__header--title--name">
                        <Skeleton height={17} width={143} />
                        <Skeleton height={17} width={163} />

                    </section>
                </section>
                <section className="mediaPublicationSkeleton__header--icon">

                    <section className="mediaPublicationSkeleton__header--dropdown">
                        <Dropdown onEdit={() => { }} onRemove={() => { }} />
                    </section>

                </section>
            </section>
            <section className="mediaPublicationSkeleton__content"> <Skeleton height={372} width={'100%'} /> </section>
            {/* <section className="mediaPublicationSkeleton__footer">
                <div className="mediaPublicationSkeleton__footer--info">
                    <div className="mediaPublicationSkeleton__footer--info--like">
                        <img/>
                        <img/>
                        <img/>
                        <img/>
                    </div>
                    <Skeleton height={17} width={225} borderRadius={0} />
                </div>
                <div className="mediaPublicationSkeleton__footer--viewer">
                    <Skeleton height={17} width={225} borderRadius={0} />
                </div>
            </section> */}
            {/* <hr />
            <section className="mediaPublicationSkeleton__actions">
                <section className="mediaPublicationSkeleton__actions--buttons">
                    <button >
                       <Skeleton height={24} width={24} borderRadius={0} />
                       <Skeleton height={24} width={72} borderRadius={0} />
                    </button>
                    <button>
                    <Skeleton height={24} width={24} borderRadius={0} />
                    <Skeleton height={24} width={72} borderRadius={0} />
                     </button>
                </section>
                <div className="mediaPublicationSkeleton__actions--bookmark">
                <Skeleton height={24} width={24} borderRadius={0} />
                </div>
            </section> */}

        </section>
        </>     

    );
};
