import "./styles.scss";

import React, { useEffect, useState } from "react";

import { FiArrowDownRight, FiMapPin } from "react-icons/fi";
import { MdArrowOutward } from "react-icons/md";
import { PiPhoneCallLight } from "react-icons/pi";

import eventsApi from "../../../../../core/services/EventsApi";
import { trackEvent } from "../../../../../shared/analytics";
import { IRouteImmobile } from "../../IProps/property.interface";
import { PropertyDetail } from "../propertyDetail";

export const PropertyRoute: React.FC = () => {
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [eventData, setEventData] = useState<{
    [key: string]: IRouteImmobile[];
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeDetail, setActiveDetail] = useState<string | null>(null);

  useEffect(() => {
    trackEvent("Button", "Click", "rotoasImoveis");
    getRoutes();
  }, []);

  const getRoutes = async () => {
    try {
      const data = await eventsApi.getRouteImmobile("6696d5451f8a82a8c8c18a7c");
      setEventData(data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
      setLoading(false);
    }
  };

  const handleCompanyClick = (company: string) => {
    setSelectedCompany(selectedCompany === company ? null : company);
    setActiveDetail(null);
  };

  const handleDetailClick = (id: string) => {
    setActiveDetail(id);
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (activeDetail) {
    return (
      <PropertyDetail
        routeId={activeDetail}
        onBack={() => setActiveDetail(null)}
      />
    );
  }

  return (
    <div className="property">
      {eventData &&
        Object.keys(eventData).map((company) => (
          <div key={company}>
            <div
              className="property__option"
              onClick={() => handleCompanyClick(company)}
              style={{ cursor: "pointer" }}
            >
              {company}
              {selectedCompany === company ? (
                <FiArrowDownRight />
              ) : (
                <MdArrowOutward />
              )}
            </div>
            {selectedCompany === company && (
              <div className="property__details">
                {eventData[company].map((option) => (
                  <div
                    key={option.id}
                    onClick={() => handleDetailClick(option.id)}
                  >
                    {option.images.length > 0 && (
                      <img src={option.images[0]} alt={option.title} />
                    )}
                    <h3 className="property__details--title">{option.title}</h3>
                    <hr />
                    <p className="property__details--location">
                      <FiMapPin style={{ marginRight: "9px" }} />
                      {option.address.logradouro}, {option.address.localidade}
                    </p>
                    <p className="property__details--phone">
                      <PiPhoneCallLight style={{ marginRight: "9px" }} />
                      (83) 90000-0000
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
