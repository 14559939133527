import React from "react";
import { IoClose } from "react-icons/io5";
import success from "../../../assets/icons/success.svg";
import "./styles.scss";

export const ModalSuccess: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <section className="modalSuccess">
      <section className="modalSuccess__content">
        <section className="modalSuccess__content--header">
          <IoClose onClick={onClose} size={20} />
        </section>
        <section className="modalSuccess__content--body">
          <h4>Conta Criada!</h4>
          <p>
            Seja bem-vindo(a) à Psiu! Aproveite os melhores eventos do Brasil.
          </p>
          <img src={success} alt="" />
          <button onClick={onClose}>Navegar na PSIU</button>
        </section>
      </section>
    </section>
  );
};
