import React from "react";
import "./styles.scss";
export const Politics: React.FC = () => {
    return (
        <div className="politics">
            <h1>Política de Privacidade para Psui Eventos</h1>
            <p>
                <strong>Introdução</strong>
                <br /><br />
                Bem-vindo ao Psui Eventos ("Aplicativo"). A sua privacidade é importante para nós. Esta Política de Privacidade descreve como coletamos, usamos, e protegemos suas informações pessoais ao usar nosso Aplicativo, que tem a finalidade de expor stands de algumas marcas, podendo o local ser on-line.
                <br /><br />
                <strong>1. Coleta de Informações</strong>
                <br /><br />
                Coletamos informações que você nos fornece diretamente, tais como:
                <ul>
                    <li><strong>Informações de Cadastro:</strong> Nome, endereço de e-mail, número de telefone, e preferências de eventos.</li>
                    <li><strong>Informações de Perfil:</strong> Foto de perfil, biografia, e outras informações que você optar por compartilhar.</li>
                    <li><strong>Informações de Pagamento:</strong> Detalhes do cartão de crédito ou outras informações de pagamento quando você adquire ingressos ou outros serviços.</li>
                </ul>
                Também coletamos automaticamente certas informações quando você usa o Aplicativo, como:
                <ul>
                    <li><strong>Dados de Uso:</strong> Informações sobre como você utiliza o Aplicativo, incluindo páginas visualizadas, tempo gasto em cada página e cliques.</li>
                    <li><strong>Informações de Dispositivo:</strong> Tipo de dispositivo, sistema operacional, identificadores exclusivos de dispositivo e dados de conexão.</li>
                    <li><strong>Dados de Localização:</strong> Informações de geolocalização, se você permitir.</li>
                </ul>
                <br />
                <strong>2. Uso das Informações</strong>
                <br /><br />
                Utilizamos as informações coletadas para:
                <ul>
                    <li>Fornecer, operar e manter o Aplicativo.</li>
                    <li>Processar transações e enviar confirmações relacionadas.</li>
                    <li>Personalizar sua experiência com base em suas preferências e interações anteriores.</li>
                    <li>Enviar notificações e atualizações sobre eventos, promoções e outras comunicações relevantes.</li>
                    <li>Analisar e melhorar a eficiência do Aplicativo e de nossos serviços.</li>
                    <li>Proteger contra fraudes e garantir a segurança do Aplicativo.</li>
                </ul>
                <br />
                <strong>3. Compartilhamento de Informações</strong>
                <br /><br />
                Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes situações:
                <ul>
                    <li>Com fornecedores e prestadores de serviços que nos ajudam a operar o Aplicativo e fornecer nossos serviços.</li>
                    <li>Quando exigido por lei, para cumprir com processos legais ou para responder a solicitações de autoridades governamentais.</li>
                    <li>Para proteger nossos direitos, privacidade, segurança ou propriedade, e/ou de nossos usuários.</li>
                    <li>Com seu consentimento explícito para qualquer outra situação.</li>
                </ul>
                <br />
                <strong>4. Segurança das Informações</strong>
                <br /><br />
                Implementamos medidas de segurança técnicas e organizacionais apropriadas para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.
                <br /><br />
                <strong>5. Seus Direitos</strong>
                <br /><br />
                Você tem o direito de:
                <ul>
                    <li>Acessar e corrigir suas informações pessoais.</li>
                    <li>Solicitar a exclusão de suas informações pessoais.</li>
                    <li>Optar por não receber comunicações de marketing.</li>
                </ul>
                Para exercer esses direitos, entre em contato conosco através dos detalhes fornecidos abaixo.
                <br /><br />
                <strong>6. Retenção de Dados</strong>
                <br /><br />
                Retemos suas informações pessoais pelo tempo necessário para cumprir os propósitos para os quais foram coletadas, incluindo para atender a quaisquer requisitos legais, contábeis ou de relatórios.
                <br /><br />
                <strong>7. Alterações a esta Política de Privacidade</strong>
                <br /><br />
                Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos você sobre quaisquer mudanças publicando a nova Política de Privacidade no Aplicativo. Recomenda-se revisar esta Política periodicamente para quaisquer alterações.
                <br /><br />
                <strong>8. Contato</strong>
                <br /><br />
                Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre nossas práticas de privacidade, entre em contato conosco em:
                <br /><br />
                <strong>Psui Eventos</strong>
                <br />
                Email: suporte@psuieventos.com
                <br />
                Telefone: +55 83 9856-7883
                <br /><br />
                Última atualização: 29/07/2024
                <br /><br />
                <strong>9. Consentimento</strong>
                <br /><br />
                Ao usar o Aplicativo Psui Eventos, você concorda com a coleta e uso de suas informações conforme descrito nesta Política de Privacidade.
            </p>
        </div>
    );
}