import { BASE_URL } from "../interceptors/axiosInstance";

const ExhibitorApi = {
  create: async (params: string, profilePicture: any, coverPhoto: any, coverPhotoMobile: any ) => {
    const token = localStorage.getItem("authToken");
    const url = new URL(`${BASE_URL}exhibitor`);
 
    const formData = new FormData();
    formData.append("profilePicture", profilePicture);
    formData.append("coverPhoto", coverPhoto);
    formData.append("coverPhotoMobile", coverPhotoMobile);
    formData.append("exhibitorRequest", params);

    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "X-Tenant": "frmi",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  },
};

export default ExhibitorApi;
