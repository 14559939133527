import { BASE_URL, fetchWithAuth } from "../interceptors/axiosInstance";

export const AuthenticationApi = {
    confirmUser: async (id: string, code: string) => {
        const url = `${BASE_URL}auth/confirm-user/${id}`;
        const token = localStorage.getItem("authToken");

        const response = await fetch(url.toString(), {
            method: "PATCH",
            headers: {
                "X-Tenant": "frmi",
                Authorization: `Bearer ${token}`,
            },
            body: code,
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json();
    },

    resendCode: async (id: string) => {
        const url = new URL(`${BASE_URL}auth/resend-token/${id}`);
        const token = localStorage.getItem("authToken");

        const response = await fetch(url.toString(), {
            method: "POST",
            headers: {
                "X-Tenant": "frmi",
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json();
    }

}