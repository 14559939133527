// TextAreaField.tsx
import React from "react";
import "./styles.scss";

interface TextAreaFieldProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  name?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  label,
  required = false,
  placeholder,
  value,
  name,
  error,
  onChange,
}) => {
  return (
    <div className="textarea-field-container">
      <label className="textarea-field-container__label">
        {label}
        {required && (
          <span className="textarea-field-container__required">*</span>
        )}
      </label>
      <div className="input-field-container__input-wrapper">
        <textarea
          name={name}
          className="textarea-field-container__textarea"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
      {error && <p className="input-field__error">{error}</p>}
    </div>
  );
};

export default TextAreaField;
