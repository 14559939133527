import React, { useContext, useState, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../assets/imgs/logo-p.svg";
import users from "../../../../assets/imgs/users.svg";
import "./styles.scss";
import { AuthContext } from "../../../../core/contexts/auth/AuthContext";
import { DropdownMenu } from "../../../../shared/components/menuDropDown";

interface NavbarAdminProps {
  toggleSidebar: () => void;
}

export const NavbarAdmin: React.FC<NavbarAdminProps> = ({ toggleSidebar }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const name = localStorage.getItem("name");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [role, setRole] = useState<string | null>(null);

  useEffect(() => {
    if (authToken) {
      const base64Url = authToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      const decodedToken = JSON.parse(jsonPayload);
      setRole(decodedToken.role);
    }
  }, [authToken]);

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("name");
    navigate("/auth/login");
  };

  const redirectToEvent = () => {
    navigate("/event");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="navBarAdmin" id="navBarAdmin">
      <div className="navBarAdmin__icon" id="navBarAdmin-icon">
        <img src={logo} alt="Logo" id="navBarAdmin-logo" onClick={redirectToEvent} />
        <div className="navBarAdmin__icon--users" id="navBarAdmin-users">
          <FiMenu onClick={toggleSidebar} />
        </div>
      </div>

      <nav className="navBarAdmin__options" id="navBarAdmin-options">
        {authToken ? (
          <div className="navBarAdmin__user">
            <div className="navBarAdmin__user--icon">
              {name?.charAt(0).toUpperCase()}
              {name?.charAt(1).toUpperCase()}
            </div>
            <div className="navBarAdmin__user--info">
              <p>{name}</p>
              <p>{role}</p>
            </div>
            <button onClick={toggleDropdown}>
              <IoIosArrowDown size={12} />
              {isDropdownOpen && <DropdownMenu />}
            </button>
          </div>
        ) : (
          <div className="navBarAdmin__buttons" id="navBarAdmin-buttons">
            <div className="navBarAdmin__buttons--login" id="login-button">
              <button>Entrar</button>
            </div>
            <div className="navBarAdmin__buttons--register" id="register-button">
              <button>Cadastrar</button>
            </div>
          </div>
        )}
      </nav>
    </nav>
  );
};
