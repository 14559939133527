import "./styles.scss";

import React from "react";

import { Tag } from "../../../../../shared/components/tag";
import { IEventCardProps } from "../../../event/IProps/eventCardAdm.interface";
import { ModalPublicationType } from "../../../event/IProps/modalPublication.interface";

export const EventCard: React.FC<IEventCardProps> = ({
  category,
  imageUrl,
  title,
  status,
  organizador,
  participants,
  organizadorImg,
  date,
  onClick,
}) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      case "Ativo":
        return "eventcard__content--status status--active";
      case "Encerrado":
        return "eventcard__content--status status--closed";
      case "Rascunho":
        return "eventcard__content--status status--sketch";
      default:
        return "eventcard__content--status";
    }
  };

  return (
    <section className="eventcard" onClick={onClick}>
      <div className="eventcard__image">
        <img src={imageUrl} alt={title} />
        <section className="eventcard__image--category">{category}</section>
      </div>

      <div className="eventcard__content">
        <div className="eventcard__content--title">
          <h3>{title}</h3>
          <div className="eventcard__content--organizador">
            <section className="eventcard__content--organizador--profile">
              <img src={organizadorImg} alt={organizador} />
              <h4>{organizador}</h4>
            </section>
          </div>
        </div>
        <Tag
          status={status}
          eventType={ModalPublicationType.PUBLICATION}
          title="Ativo"
          color="#FFAC3885"
        />
      </div>
      <hr />
      <section className="eventcard__footer">
        <section className="eventcard__footer--participants">
          <strong>{participants ? participants : "--"}</strong>
          <h4>Participantes</h4>
        </section>
        <section className="eventcard__footer--date">
          <h1>{date}</h1>
        </section>
      </section>
    </section>
  );
};
