import Skeleton from "react-loading-skeleton";
import "./styles.scss";


export const ExpositorCardSkeleton: React.FC = () => {
    return (
        <section className="expositorCardSkeleton">
                <Skeleton height={111} width={113} borderRadius={9} />
            <div className="expositorCardSkeleton__content">
                <div className="expositorCardSkeleton__content--info">
                    <Skeleton height={17} width={90} />
                </div>

                <div className="expositorCardSkeleton__content--info">
                    <Skeleton height={39} width={130} />
                </div>
                <div className="expositorCardSkeleton__content--info">
                    <Skeleton height={19} width={115} />
                </div>
            </div>


        </section>
    );
}