import "./styles.scss";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import { trackEvent } from "../../../../../shared/analytics";
import { ExpositorCard } from "../../../../../shared/components/expositorCard";
import { ExpositorCardSkeleton } from "../skeleton/expositorCard";
import { useTranslation } from "react-i18next";

export const Expositors: React.FC = () => {
  const [exhibitorsData, setExhibitorsData] =
    useState<IEvent.Exhibitors | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    trackEvent("Button", "Click", "expositores");
    const fetchSpeakers = async () => {
      try {
        const eventId = "6696d5451f8a82a8c8c18a7c";
        const response = await eventsApi.getExhibitors(eventId);
        setExhibitorsData(response);
      } catch (err) {
        console.error("Failed to fetch speakers:", err);
        setError(t("errorFetchingSpeakers"));  
      } finally {
        setLoading(false);
      }
    };

    fetchSpeakers();
  }, [t]);  

  const getTranslatedCategory = (category: string) => {
    return t(category.toUpperCase()) || capitalizeFirstLetter(category);
  };

  const capitalizeFirstLetter = (category: string) => {
    if (!category) return category;
    return category.charAt(0).toUpperCase() + category.slice(1).toLowerCase();
  };

  if (loading) {
    return (
      <div className="expositors">
        <div className="expositors__category">
          <h2 className="expositors__category-title">
            <Skeleton
              width={176}
              height={25}
              style={{ marginBottom: 10 }}
              borderRadius={0}
            />
          </h2>
          <div className="expositors__cards">
            {[...Array(4)].map((_, index) => (
              <ExpositorCardSkeleton key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!exhibitorsData || Object.keys(exhibitorsData).length === 0) {
    return <div>{t("noExhibitorsFound")}</div>;
  }

  return (
    <div className="expositors">
      {Object.keys(exhibitorsData).map((category) => (
        <div key={category} className="expositors__category">
          <h2 className="expositors__category-title">
            {getTranslatedCategory(category)}
          </h2>
          <div className="expositors__cards">
            {exhibitorsData[category as keyof IEvent.Exhibitors]?.map(
              (exhibitor: IEvent.Exhibitor) => (
                <Link
                  to="/expositor"
                  state={{ exhibitorId: exhibitor.id }}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: "#212121",
                  }}
                  key={exhibitor.id}
                >
                  <ExpositorCard
                    id={exhibitor.id}
                    imageUrl={exhibitor.profilePicture}
                    name={exhibitor.title}
                    category={
                      getTranslatedCategory(exhibitor.category) ??
                      t("defaultCategory")  
                    }
                  />
                </Link>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
