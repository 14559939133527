import React from "react";
import constructionImage from "../../../../assets/imgs/under_constructions_1.svg";
import * as C from "./styles";

export const Maintenance: React.FC = () => {
  return (
    <C.ConstructionContainer>
      <C.BackgroundImage
        src={constructionImage}
        alt="Construction Illustration"
      />
      <C.Title>Página em Manutenção</C.Title>
      <C.Description>
      Pedimos desculpas pelo inconveniente, mas esta página está em manutenção.
      Estamos trabalhando para torná-la disponível em breve.
      </C.Description>
    </C.ConstructionContainer>
  );
};
 