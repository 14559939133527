import React, { useState } from "react";
import InputField from "../../../../../shared/components/forms/input";
import TextAreaField from "../../../../../shared/components/forms/textarea";
import "./styles.scss";
import ImmobilesApi from "../../../../../core/services/ImmobilesApi";
import { Immobile } from "../../../../../core/interfaces/immobile/immobile.interface";
import MultipleUploadMedia from "../../../../../shared/components/multipleUploadMedia";

export const CreateImmobile: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<Immobile.create>({
    title: "",
    description: "",
    suites: 0,
    room: 0,
    size: 0,
    contact: "",
    status: 1,
    address: {
      id: null,
      road: "",
      city: "",
      state: "",
      zipCode: "",
      number: "",
      complement: "",
    },
    exhibitorsId: "66bd6ef5d228a364bffc9169",
    eventId: "6696d5451f8a82a8c8c18a7c",
    images: [],
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name in formValues.address) {
      setFormValues((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = (files: File[]) => {
    setFormValues((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...files],  
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setLoading(true);
    setSuccessMessage(null);
  
    try {
      const params = JSON.stringify({
        eventId: formValues.eventId, 
        title: formValues.title,
        description: formValues.description,
        suites: formValues.suites,
        address: formValues.address,
        room: formValues.room,
        size: formValues.size,
        contact: formValues.contact,
        status: formValues.status,
        exhibitorsId: formValues.exhibitorsId,
      });
  
      await ImmobilesApi.create(params, formValues.images);
      console.log("Form data:", formValues);
    } catch (error) {
      console.error("Erro ao enviar a solicitação:", error);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <section className="event-list">
      <header className="event-list__header">
        <h1 className="event-list__title">Criar Imóvel</h1>
      </header>
      <div className="event-list__body">
        <article className="event-card">
          <div className="Immobile-card">
            <label className="Immobile-card__label">
              <h2>1. Informações básicas</h2>
            </label>

            <InputField
              name="title"
              label="Nome do Imóvel"
              required
              placeholder="Digite o nome do imóvel"
              value={formValues.title}
              onChange={handleChange}
            />

            <TextAreaField
              name="description"
              label="Descrição"
              required
              placeholder="Conte todos os detalhes do imóvel!"
              value={formValues.description}
              onChange={handleChange}
            />
          </div>

          <div className="event-card--quota">
          <label htmlFor="quota">Status atual</label>
          <select
            name="status"
            id="status"
            value={formValues.status.toString()}
            onChange={handleSelectChange}
            required
          >
            <option value="" disabled>
              Selecione o status do imóvel
            </option>
            <option value="1">Pronto</option>
            <option value="2">lançamento</option>
            <option value="3">em construção</option>
            <option value="4">breve lançamento</option>
          </select>
        </div>
        </article>

        <article className="event-card">
          <div className="Immobile-card">
            <label className="Immobile-card__label">
              <h2>2. Imagens</h2>
            </label>
 

            <MultipleUploadMedia
            label="Imagens do imóvel"
            additionalText="A dimensão recomendada é de 655 x 368. Formato JPEG ou PNG de no mínimo 5MB. Imagens com dimensões diferentes são redimensionadas."
            onImageUpload={(file) => handleImageUpload(file)}
          />
            </div>
        </article>

        <article className="event-card">
          <div className="Immobile-card">
            <label className="Immobile-card__label">
              <h2>3. Localização</h2>
            </label>

            <InputField
              name="road"
              label="Rua"
              required
              placeholder="Digite a rua"
              value={formValues.address.road}
              onChange={handleChange}
            />

            <InputField
              name="city"
              label="Cidade"
              required
              placeholder="Digite a cidade"
              value={formValues.address.city}
              onChange={handleChange}
            />

            <InputField
              name="state"
              label="Estado"
              required
              placeholder="Digite o estado"
              value={formValues.address.state}
              onChange={handleChange}
            />

            <InputField
              name="zipCode"
              label="CEP"
              required
              placeholder="Digite o CEP"
              value={formValues.address.zipCode}
              onChange={handleChange}
            />

            <InputField
              name="number"
              label="Número"
              required
              placeholder="Digite o número"
              value={formValues.address.number}
              onChange={handleChange}
            />

            <InputField
              name="complement"
              label="Complemento"
              placeholder="Digite o complemento"
              value={formValues.address.complement}
              onChange={handleChange}
            />
          </div>
        </article>

        <div className="action-buttons">
          <button
            className="action-button publish-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {loading ? "Enviando..." : "Publicar"}
          </button>
        </div>

        {successMessage && (
          <div className="success-message">
            <p>{successMessage}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default CreateImmobile;
