export interface ModalPublicationProps {
    isOpen: boolean;
    onClose: () => void;
    type: ModalPublicationType;
    onUpdate: () => Promise<void>;
    
}

export enum ModalPublicationType {
    ANNOUNCEMENT = "ANNOUNCEMENT",
    PUBLICATION = "PUBLICATION"
}