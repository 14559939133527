import "./styles.scss";
import React, { useEffect, useState } from "react";
import { EventCard } from "../eventCard";
import { DetailEvent } from "./detailEvent";
import eventsApi from "../../../../../core/services/EventsApi";
import { ISubscribers } from "../../../event/IProps/subscribers.interface";

export const MyEvent: React.FC = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [subscribeData, setSubscribeData] = useState<ISubscribers | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleCardClick = () => {
    setShowDetail(true);
  };

  useEffect(() => {
    fetchSubscribe();
  }, []);

  const fetchSubscribe = async () => {
    try {
      const response = await eventsApi.getSubscribes();
      setSubscribeData(response);
      console.log(response);
    } catch (err) {
      console.error("Failed to fetch subscribes:", err);
      setError("Failed to fetch subscribes");
    } finally {
      setLoading(false);
    }
  };

  if (showDetail) {
    return (
      <DetailEvent
        title="Meus Eventos / Evento 1"
        eventTitle="FRMI"
        navItems={["Inscritos", "Publicações"]}
        eventId="6696d5451f8a82a8c8c18a7c"
      />
    );
  }

  return (
    <section className="myEvent">
      <section className="myEvent__title">
        <h1>Meus Expositores</h1>
      </section>
      <section
        className="myEvent__body"
        style={{ marginTop: "30px", cursor: "pointer" }}
      >
        {loading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <EventCard
            category="Categoria"
            imageUrl="https://semaluguel-images.s3.us-east-2.amazonaws.com/FRMI/Event/Logo+-+Forum.png"
            title="FRMI"
            status="Ativo"
            organizador="Ney"
            participants={subscribeData?.totalElements || 0}
            date="22/08/2024"
            organizadorImg="https://picsum.photos/50/50"
            onClick={handleCardClick}
          />
        )}
      </section>
    </section>
  );
};
