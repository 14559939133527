import { FiMoreVertical } from "react-icons/fi";
import { styled } from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownIcon = styled(FiMoreVertical)`
  cursor: pointer;
  color: #646464;
`;

export const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 10;
`;

export const DropdownButton = styled.button`
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  color: #333;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  color: #333;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DropdownText = styled.span`
  margin-left: 8px;
`;

export const PostImage = styled.img`
  width: 100%;
  height: auto;
`;

export const PostDescription = styled.p`
  justify-content: start;
  font-size: 16px;
  color: #333;
`;

export const Divider = styled.div`
  margin: 10px 0;
  border-top: 1px solid #e0e0e0;
`;
