import React, { useEffect, useState } from "react";
import "./styles.scss";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import logo from "../../../../../assets/icons/logo-branca-psiu.svg";
import { Link } from "react-router-dom";
import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import { useTranslation } from "react-i18next";

export const Footer: React.FC = () => {
    const [shortcuts, setShortcuts] = useState<IEvent.shortcuts>();
    const {t} = useTranslation();

    useEffect(() => {
        getShortCutById();
      }, []);
    
      const getShortCutById = async () => {
        const date = await eventsApi.getShortCutById("6696d5451f8a82a8c8c18a7c");
        if (date) {
          setShortcuts(date);
        }
      };

    return (
        <footer className="footer">
            <div className="footer__content">
                <section className="footer__content--logo">
                    <img src={logo} alt="Logo" />
                </section>
                <section className="footer__content--column">
                    <div className="footer__content--options">
                        <section className="footer__content--options--home">
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white', fontSize: '14px', fontWeight: '700' }}>
                                <h4>Home</h4>
                            </Link>
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <p>{t("findEvents")}</p>
                            </Link>
                        </section>
                        <section className="footer__content--options--producers">
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <h4>{t("forProducers")}</h4>
                            </Link>
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <p>{t("createEvent")}</p>
                            </Link>
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <p>{t("myEvents")}</p>
                            </Link>
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <p>{t("lostPassword")}</p>
                            </Link>
                        </section>
                        <section className="footer__content--options--help">
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <h4>{t("help")}</h4>
                            </Link>
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <p>{t("buyers")}</p>
                            </Link>
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <p>{t("eventProducers")}</p>
                            </Link>
                        </section>
                        <section className="footer__content--options--service">
                            <Link 
                                to="/construction" 
                                style={{ textDecoration: 'none', color: 'white' }}>
                                <h4>{t("support")}</h4>
                            </Link>
                                <p>psiu@gmail.com</p>
                                <p>(11) 9 89287268</p>
 
                        </section>
                        <section className="footer__content--options--network">
                            <h4>PSIU na Mídia</h4>
                            <section>
                                <a style={{ textDecoration: 'none', color: 'white' }} target="_blank" href={shortcuts?.socialMedia.instagram}>
                                <AiFillInstagram className="footer__content--options--network--icons" />
                                </a>
                                <a style={{ textDecoration: 'none', color: 'white' }} target="_blank" href={shortcuts?.socialMedia.facebook}>
                                <FaFacebook className="footer__content--options--network--icons" />
                                </a>

                            </section>
                        </section>
                    </div>
                    {/* <section className="footer__sponsors">
                        <h4>Patrocinadores</h4>
                        <section className="footer__sponsors--perfil">
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 1" />
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 2" />
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 3" />
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 4" />
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 5" />
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 6" />
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 7" />
                            <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=70&w=70" alt="Sponsor 8" />
                        </section>
                    </section> */}
                </section>
            </div>
            <section className="footer__copyright">
                <p>© 2024, {t("allRightsReserved")}</p>
                <Link to="/politics"  style={{ textDecoration: 'none', color: 'white' }}>
                <p>{t("privacyPolicy")}</p>
                </Link>
            </section>
        </footer>
    );
}
