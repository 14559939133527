export const BASE_URL = "https://yyxkpx5kpv.us-east-2.awsapprunner.com/api/";

const getHeaders = () => {
  const token = localStorage.getItem("authToken");
  return {
    "Content-Type": "application/json",
    "X-Tenant": "frmi",
    Authorization: `Bearer ${token}`,
  };
};

interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
  method?: string;
  body?: BodyInit;
}

export const fetchWithAuth = async (
  url: string,
  options: FetchOptions = {}
) => {
  const headers = new Headers(getHeaders());

  if (options.headers) {
    for (const [key, value] of Object.entries(options.headers)) {
      headers.append(key, value as string);
    }
  }

  const config: RequestInit = {
    method: options.method || "GET",
    headers,
    body: options.body,
  };

  const response = await fetch(url, config);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Fetch failed");
  }

  return response.json();
};
