import { BASE_URL } from "../interceptors/axiosInstance";

const ImmobilesApi = {
    create: async (description: string, files: File[]) => {
      const token = localStorage.getItem("authToken");
      const url = new URL(`${BASE_URL}routeImmobile`);

      url.searchParams.append("request", description);
   
      const formData = new FormData();
  
      files.forEach((file) => formData.append("files", file));
  
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "X-Tenant": "frmi",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(response.statusText);
      }
  
      return response.json();
    },
  };
  
  export default ImmobilesApi;
 

