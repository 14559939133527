import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import background from '../../../../assets/imgs/onboarding-bg.jpg';

export const Onboarding: React.FC = () => {
  const navigate = useNavigate();
  const [isAndroid, setIsAndroid] = useState<boolean>(false);

  useEffect(() => {
    // Detecta se o usuário está usando um dispositivo Android
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.includes("android")) {
      setIsAndroid(true);
    }
  }, []);

  const handleExternalRedirect = () => {
    navigate("/auth/login/guest");
  };

  const handleDownloadAPK = () => {
    window.location.href =
      "https://semaluguel-images.s3.us-east-2.amazonaws.com/psiu_educacao.apk"; // Substitua pela URL do seu APK
  };

  return (
    <div className="onboarding">
      <div className="onboarding__background">
        <img src={background} alt="Background" className="onboarding__image" />
      </div>
      <div className="onboarding__overlay">
        <div className="onboarding__gradient">
          <div className="onboarding__content">
            <h1 className="onboarding__title">
              A melhor experiência em eventos imobiliários.
            </h1>
            <p className="onboarding__description">
              Experiência Memorável. Conhecimento Único Networking Para a Vida.
              Nossa missão é te manter aprendendo a vida toda, pois conhecimento
              nunca é demais!
            </p>

            <div className="onboarding__buttons">
              <button
                className="onboarding__button onboarding__button--primary"
                onClick={() => {
                  navigate("/auth/cadastrar");
                }}
              >
                Não tenho cadastro
              </button>
              <button
                className="onboarding__button onboarding__button--secondary"
                onClick={() => {
                  navigate("/auth/login");
                }}
              >
                Já tenho cadastro
              </button>
            </div>
            <div className="onboarding__buttons onboarding__buttons--tertiary">
              <button
                className="onboarding__button onboarding__button--tertiary"
                onClick={handleExternalRedirect}
              >
                Entrar sem cadastro
              </button>
            </div>
            {isAndroid && (
              <div className="onboarding__android-message">
                <p
                  className="onboarding__android-text"
                  onClick={handleDownloadAPK}
                >
                  Para uma melhor experiência, baixe nosso aplicativo aqui!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
