import "./styles.scss";

import { ChangeEvent, useContext, useState } from "react";

import { FcGoogle } from "react-icons/fc";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../../../assets/imgs/logo-p.svg";
import { AuthContext } from "../../../../core/contexts/auth/AuthContext";
import { LoadingOverlay } from "../../../../shared/components/loading";

export const Login = () => {
  const { signin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleGoogleLogin = () => {
    const googleAuthUrl =
      "https://yyxkpx5kpv.us-east-2.awsapprunner.com/api/oauth2/authorization/google";

    window.location.href = googleAuthUrl;
  };

  const handleLogin = async () => {
    setIsloading(true);
    if (email && password) {
      const isLogged = await signin(email, password);
      if (isLogged.messageError) {
        setIsloading(false);
        setErrorMessage(isLogged.messageError); // Ajuste para garantir que a mensagem de erro está correta
      } else {
        navigate("/event");
        setIsloading(false);
        setErrorMessage(null);
      }
    } else {
      setErrorMessage("Por favor, preencha o email e a senha."); // Atualize a mensagem de erro caso os campos estejam vazios
      setIsloading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="login">
      <LoadingOverlay loading={isLoading} />
      <section className="login__left">
        <img
          src="https://semaluguel-images.s3.us-east-2.amazonaws.com/FRMI/Event/Logo+PSIU+2023+-+Cores.jpg"
          alt="logo"
        />
      </section>

      <section className="login__content">
        <section className="login__content--header">
          <img src={logo} alt="log" />
          <h2>Seja Bem - Vindo</h2>
          <p>Acesse sua conta</p>
        </section>

        <section className="login__content--body">
          <section>
            <h5>Email</h5>
            <input
              type="text"
              onKeyDown={handleKeyPress}
              onChange={handleEmailInput}
              placeholder="Insira seu email"
              value={email}
            />
            <h5>Senha</h5>
            <section className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onKeyDown={handleKeyPress}
                onChange={handlePasswordInput}
                placeholder="Insira sua senha"
              />
              <span onClick={togglePasswordVisibility}>
                {showPassword ? <HiOutlineEyeSlash /> : <HiOutlineEye />}
              </span>
            </section>
            <section className="login__content--body--buttons">
              <button
                onClick={handleLogin}
                className="login__content--body--buttons--login"
              >
                Entrar
              </button>

              <p style={{ color: "red" }}>{errorMessage}</p>

              <div className="login__content--body--buttons--separator">
                <hr />
              </div>
              <button
                className="login__content--body--buttons--google"
                onClick={handleGoogleLogin}
              >
                <FcGoogle /> Entrar com Google
              </button>

              <section className="login__content--body--buttons--register">
                <p>Ainda não possui uma conta? </p>
                <Link
                  style={{
                    textDecoration: "none",
                    paddingLeft: "5px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                  to="/auth/cadastrar"
                >
                  {" "}
                  Registar
                </Link>
              </section>

              <Link
                style={{
                  textDecoration: "none",
                  paddingLeft: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
                to="/auth/fale-conosco"
              >
                {" "}
                fale-conosco
              </Link>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};
