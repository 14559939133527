import './styles.scss';

import React from 'react';

import {
  SpeakerScheduleCardProps,
} from '../../Iprops/IPspeakerSheduleCard.interface';

export const SpeakerScheduleCard: React.FC<SpeakerScheduleCardProps> = ({
  dados,
  onSpeakerClick,
}) => {
  return (
    <section className="card">
      <section className="card__info--header">
        <section className="card__info--location--hour">
          {dados.timeStart ? ` ${dados.timeStart}` : ""}
          {dados.timeEnd ? ` - ${dados.timeEnd}` : ""}
        </section>
        <section className="card__info--header--theme">
          <h2>{dados.theme}</h2>
          {dados.description && <p>{dados.description}</p>}
        </section>
      </section>

      <section className="card__info_shedule">
        <section className="container_shedule">
          {dados.speaker.map((speaker: any) => (
            <section
              key={speaker.id}
              className="card__info_shedule--speaker"
              onClick={() => onSpeakerClick(speaker)}
            >
              <img src={speaker.profilePicture} alt={speaker.name} />
              <section>
                <section className="card__info_shedule--name">
                  {speaker.name}
                </section>
                <section className="card__info_shedule--company">
                  {speaker.company} - {speaker.office}
                </section>
              </section>
            </section>
          ))}
        </section>
      </section>
    </section>
  );
};
