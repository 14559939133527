import { ChangeEvent, useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../assets/imgs/logo-p.svg";
import { AuthContext } from "../../../../core/contexts/auth/AuthContext";

import { LoadingOverlay } from "../../../../shared/components/loading";
import "./styles.scss";
import { AuthenticationApi } from "../../../../core/services/AuthenticationApi";
import { decodeToken } from "../../../../core/utils/jwt-code/decodeToken";

export interface CodeAuthenticationProps {
  email: string;
}

export const CodeAuthentication: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const location = useLocation();
  const email = location.state?.email || "";
  const authToken = localStorage.getItem("authToken");



  const userId = decodeToken(authToken!).userId;
  console.log("userId", userId)

  const handleCodeInput = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newCode = [...code];
    newCode[index] = event.target.value;
    setCode(newCode);

    // Mover o foco para o próximo input automaticamente
    if (event.target.value.length === 1 && index < 5) {
      (document.getElementById(`code-input-${index + 1}`) as HTMLElement).focus();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Backspace" && !code[index] && index > 0) {
      (document.getElementById(`code-input-${index - 1}`) as HTMLElement).focus();
    }

    if (event.key === "Enter") {
      // handleVerifyCode();
    }
  };

  const handleLogin = async () => {
    setIsloading(true);
    try {
      const isAuthorizated = await AuthenticationApi.confirmUser(userId, code.join(""),);
      if (isAuthorizated.messageError) {
        setIsloading(false);
        setErrorMessage(isAuthorizated.messageError);
      } else {
        navigate("/event");
        setIsloading(false);
        setErrorMessage(null);
      }
    } catch (error) {
      setIsloading(false);
    }

  };

  const resendCode = async () => {
    setIsloading(true);

    try {
      const isAuthorizated = await AuthenticationApi.resendCode(userId);
      if (isAuthorizated.messageError) {
        setIsloading(false);
        setErrorMessage(isAuthorizated.messageError);
      }
    } catch (error) {
      setIsloading(false);
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="codeAuth">
      <LoadingOverlay loading={isLoading} />
      <section className="codeAuth__left">
        <img
          src="https://semaluguel-images.s3.us-east-2.amazonaws.com/FRMI/Event/Logo+PSIU+2023+-+Cores.jpg"
          alt="logo"
        />
      </section>

      <section className="codeAuth__content">
        <section className="codeAuth__content--header">
          <h2>Código de verificação</h2>
          <p>Insira o código que enviamos para <strong>{email}</strong> </p>
        </section>

        <section className="codeAuth__content--body">
          <section>
            <section className="codeAuth__inputs">
              <section className="codeAuth__inputs--group">
                {code.slice(0, 3).map((digit, index) => (
                  <input
                    key={index}
                    id={`code-input-${index}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeInput(index, e)}
                    onKeyDown={(e) => handleKeyPress(e, index)}
                    className="codeAuth__input"
                  />
                ))}
              </section>

              <section className="codeAuth__inputs--group">
                {code.slice(3).map((digit, index) => (
                  <input
                    key={index + 3}
                    id={`code-input-${index + 3}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeInput(index + 3, e)}
                    onKeyDown={(e) => handleKeyPress(e, index + 3)}
                    className="codeAuth__input"
                  />
                ))}
              </section>
            </section>


            <section className="codeAuth__content--body--buttons">
              <button
                onClick={handleLogin}
                className="codeAuth__content--body--buttons--login"
              >
                Entrar
              </button>

              <p style={{ color: "red" }}>{errorMessage}</p>

              <div className="codeAuth__content--body--buttons--separator">
                <hr />
              </div>

              <section className="codeAuth__content--body--buttons--register">
                <p>Não recebeu o código?</p>
                <p
                  onClick={resendCode}
                  style={{
                    color: "darkslateblue",
                    cursor: "pointer",
                    textDecoration: "none",
                    paddingLeft: "5px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {" "}
                  Clique aqui para reenviar.
                </p>
              </section>

              <Link
                style={{
                  textDecoration: "none",
                  paddingLeft: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
                to="/auth/fale-conosco"
              >
                {" "}
                fale-conosco
              </Link>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};