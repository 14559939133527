import { BASE_URL, fetchWithAuth } from "../interceptors/axiosInstance";

export const speakersApi = {
   getByEvent: async (eventId: string) => {
    const url = `${BASE_URL}speaker/findByEvent/${eventId}`;
    return fetchWithAuth(url, { method: "GET" });
  },
   getById: async (id: string) => {
    const url = `${BASE_URL}speaker/${id}`;
    return fetchWithAuth(url, { method: "GET" });
  },
};