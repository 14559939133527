import React, { useState } from "react";
import InputField from "../../../../../shared/components/forms/input";
import TextAreaField from "../../../../../shared/components/forms/textarea";
import "./styles.scss";
import MultipleUploadMedia from "../../../../../shared/components/multipleUploadMedia";
import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import ImageUpload from "../../../../../shared/components/forms/upload";

export const CreateEvent: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<IEvent.create>({
    title: "",
    local: "",
    capacity: 0,
    description: "",
    aboutSponsor: "",
    dateStart: "",
    timeStart: "",
    dateEnd: "",
    timeEnd: "",
    profilePicture: new File([""], "default.png", { type: "image/png" }),
    coverPhoto: new File([""], "default.png", { type: "image/png" }),
    coverPhotoMobile: new File([""], "default.png", { type: "image/png" }),
    images: [],
    address: {
      road: "",
      city: "",
      state: "",
      zipCode: "",
      number: "",
      complement: "",
    },
    socialMedia: {
      site: "",
      youtube: "",
      twitter: "",
      spotify: "",
      linkedin: "",
      facebook: "",
      instagram: "",
      whatsapp: "",
    }
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
  
    if (name in formValues.address) {
      setFormValues((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    } else if (name in formValues.socialMedia) {
      setFormValues((prevState) => ({
        ...prevState,
        socialMedia: {
          ...prevState.socialMedia,
          [name]: value,
        },
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  

  const handleImageUpload = (file: File | null, type: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [type]: file,
    }));
  };

  const handleMultipleImageUpload = (files: File[]) => {
    setFormValues((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...files],  
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setLoading(true);
    setSuccessMessage(null);
  
    try {
      const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },

      );
      };
  
      const params = JSON.stringify({
        title: formValues.title,
        description: formValues.description,
        aboutSponsor: formValues.aboutSponsor,
        local: formValues.local,
        capacity: Number(formValues.capacity),
        dateStart: formatDate(formValues.dateStart),
        dateEnd: formatDate(formValues.dateEnd),
        timeStart: formValues.timeStart,
        timeEnd: formValues.timeEnd,
        address: formValues.address,
        socialMediaRequest: formValues.socialMedia,
      });
  
      await eventsApi.create(params, 
        formValues.images, 
        formValues.coverPhoto,
        formValues.coverPhotoMobile,
        formValues.profilePicture  
      );
      console.log("Form data:", formValues);
    } catch (error) {
      console.error("Erro ao enviar a solicitação:", error);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };
  

  return (
    <section className="event-list">
      <header className="event-list__header">
        <h1 className="event-list__title">Criar Imóvel</h1>
      </header>
      <div className="event-list__body">
        <article className="event-card">
          <div className="Immobile-card">
            <label className="Immobile-card__label">
              <h2>1. Informações básicas</h2>
            </label>

            <InputField
              name="title"
              label="Nome do Evento"
              required
              placeholder="Digite o nome do evento"
              value={formValues.title}
              onChange={handleChange}
            />

            <TextAreaField
              name="description"
              label="Descrição"
              required
              placeholder="Descreva os detalhes do evento!"
              value={formValues.description}
              onChange={handleChange}
            />
 
          </div>
        </article>

        <article className="event-card">
          <div className="Immobile-card">
            <label className="Immobile-card__label">
              <h2>2. Detalhes do Evento</h2>
            </label>

            <InputField
              name="aboutSponsor"
              label="Sobre os patrocinadores"
              required
              placeholder="Fale sobre os patrocinadores do evento"
              value={formValues.aboutSponsor}
              onChange={handleChange}
            />
            <InputField
              name="local"
              label="Local"
              required
              placeholder="Digite o local do evento"
              value={formValues.local}
              onChange={handleChange}
            />

            <InputField
              name="capacity"
              label="capacidade"
              required
              placeholder="Digite a capacidade"
              value={formValues.capacity.toString()}
              onChange={handleChange}
            />

            <section className="event-card--dateStart" style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Data de Início*</label>
              <input
                name="dateStart"
                type="date"
                value={formValues.dateStart}
                onChange={handleChange}
                style={{ width: '200px', height: '40px', marginBottom: '20px' }} />
            </section>

            <section className="event-card--dateStart" style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Data de Fim*</label>
              <input
                name="dateEnd"
                type="date"
                value={formValues.dateEnd}
                onChange={handleChange}
                style={{ width: '200px', height: '40px', marginBottom: '20px' }} />
            </section>

            <section className="event-card--dateStart" style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Hora de Início*</label>
              <input
                name="timeStart"
                type="time"
                onChange={handleChange}
                value={formValues.timeStart}
                style={{ width: '200px', height: '40px', marginBottom: '20px' }} />
            </section>

            <section className="event-card--dateStart" style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Hora de fim*</label>
              <input
                name="timeEnd"
                type="time"
                onChange={handleChange}
                value={formValues.timeEnd}
                style={{ width: '200px', height: '40px', marginBottom: '20px' }} />
            </section>
 
          </div>
        </article>

        <article className="event-card">
          <div className="Immobile-card">
            <label className="Immobile-card__label">
              <h2>3. Localização</h2>
            </label>

            <InputField
              name="road"
              label="Rua"
              required
              placeholder="Digite a rua"
              value={formValues.address.road}
              onChange={handleChange}
            />

            <InputField
              name="city"
              label="Cidade"
              required
              placeholder="Digite a cidade"
              value={formValues.address.city}
              onChange={handleChange}
            />

            <InputField
              name="state"
              label="Estado"
              required
              placeholder="Digite o estado"
              value={formValues.address.state}
              onChange={handleChange}
            />

            <InputField
              name="zipCode"
              label="CEP"
              required
              placeholder="Digite o CEP"
              value={formValues.address.zipCode}
              onChange={handleChange}
            />

            <InputField
              name="number"
              label="Número"
              required
              placeholder="Digite o número"
              value={formValues.address.number}
              onChange={handleChange}
            />

            <InputField
              name="complement"
              label="Complemento"
              placeholder="Digite o complemento"
              value={formValues.address.complement}
              onChange={handleChange}
            />
          </div>
        </article>

        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">4. Imagens</label>

            <ImageUpload
              label="Imagem de capa"
              additionalText="A dimensão recomendada é de 1440 x 159. Formato JPEG ou PNG de no máximo 5MB. Imagens com dimensões diferentes serão redimensionadas."
              onImageUpload={(file) => handleImageUpload(file, "coverPhoto")}
            />

            <ImageUpload
              label="Imagem de capa mobile"
              additionalText="A dimensão recomendada é de 1251 x 159. Formato JPEG ou PNG de no máximo 5MB. Imagens com dimensões diferentes serão redimensionadas."
              onImageUpload={(file) =>
                handleImageUpload(file, "coverPhotoMobile")
              }
            />

            <ImageUpload
              label="Imagem de perfil"
              additionalText="A dimensão recomendada é de 300 x 300. Formato JPEG ou PNG de no máximo 5MB. Imagens com dimensões diferentes serão redimensionadas."
              onImageUpload={(file) =>
                handleImageUpload(file, "profilePicture")
              }
            />

            <MultipleUploadMedia
              label="Fotos para ficar na galeria"
              additionalText="A dimensão recomendada é de 655 x 368. Formato JPEG ou PNG de no mínimo 5MB. Imagens com dimensões diferentes são redimensionadas."
              onImageUpload={(file) => handleMultipleImageUpload(file)}
            />
          </div>
        </article>


        <article className="event-card">
          <div className="exhibitor-card">
            <label className="exhibitor-card__label">
              6. Redes Sociais
            </label>

            <InputField
              name="site"
              label="site"
              required
              placeholder="Digite o link do site"
              value={formValues.socialMedia.site}
              onChange={handleChange}
            />

            <InputField
              name="youtube"
              label="youtube"
              required
              placeholder="Digite o link do youtube"
              value={formValues.socialMedia.youtube}
              onChange={handleChange}
            />

            <InputField
              name="twitter"
              label="twitter"
              required
              placeholder="Digite o link do twitter"
              value={formValues.socialMedia.twitter}
              onChange={handleChange}
            />
            <InputField
              name="spotify"
              label="spotify"
              required
              placeholder="Digite o link do spotify"
              value={formValues.socialMedia.spotify}
              onChange={handleChange}
            />
            <InputField
              name="linkedin"
              label="linkedin"
              required
              placeholder="Digite o link do linkedin"
              value={formValues.socialMedia.linkedin}
              onChange={handleChange}
            />
            <InputField
              name="facebook"
              label="facebook"
              required
              placeholder="Digite o link do facebook"
              value={formValues.socialMedia.facebook}
              onChange={handleChange}
            />
            <InputField
              name="instagram"
              label="instagram"
              required
              placeholder="Digite o link do instagram"
              value={formValues.socialMedia.instagram}
              onChange={handleChange}
            />
            <InputField
              name="whatsapp"
              label="whatsapp"
              required
              placeholder="Digite o número do whatsapp"
              value={formValues.socialMedia.whatsapp}
              onChange={handleChange}
            />
          </div>
        </article>

        <div className="action-buttons">
          <button
            className="action-button publish-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {loading ? "Enviando..." : "Publicar"}
          </button>
        </div>

        {successMessage && (
          <div className="success-message">
            <p>{successMessage}</p>
          </div>
        )}
      </div>
    </section>
  );
};

