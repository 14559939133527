import "reactflow/dist/style.css";
// import NewPost from "../../../../components/commons/home/newPost";
// import { List } from "../../../../components/commons/menu/styles";

export const Home = () => {
  return (
    <div>
      teste
      {/* <NewPost /> */}
      {/* <List /> */}
    </div>
  );
};
