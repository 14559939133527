import "./styles.scss";

import React, { useState } from "react";

import { SharedPublication } from "../../sharedPublication";
import { IPublicationAPI } from "../interfaces/publicationEvent.interface";

export const PublicationsEvent: React.FC<{
  publications: IPublicationAPI[];
}> = ({ publications }) => {
  const [selectedPost, setSelectedPost] = useState<IPublicationAPI | null>(
    null
  );

  const handleViewPost = (post: IPublicationAPI) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case "ANALYSIS":
        return "status--draft";
      case "APPROVED":
        return "status--published";
      case "DENIED":
        return "status--cancelled";
      default:
        return "";
    }
  };

  const translateStatus = (status: string): string => {
    switch (status) {
      case "ANALYSIS":
        return "Análise";
      case "APPROVED":
        return "Aprovado";
      case "DENIED":
        return "Negado";
      default:
        return status;
    }
  };

  return (
    <>
      <div className="publications__header">Publicações: FRMI</div>
      <div className="publications__table-container">
        <table className="publications__table">
          <thead>
            <tr>
              <th className="publications__table-header">Nome</th>
              <th className="publications__table-header">Descrição</th>
              <th className="publications__table-header">Data de Criação</th>
              <th className="publications__table-header">Status</th>
              <th className="publications__table-header">Ação</th>
            </tr>
          </thead>
          <tbody>
            {publications.length > 0 ? (
              publications.map((publication) => (
                <tr key={publication.id}>
                  <td className="publications__table-cell">
                    {publication.user.name}
                  </td>
                  <td className="publications__table-cell">
                    {publication.description}
                  </td>
                  <td className="publications__table-cell">
                    {new Date(publication.creationDate).toLocaleDateString()}
                  </td>
                  <td className="publications__table-cell">
                    <span className={getStatusClass(publication.status)}>
                      {translateStatus(publication.status)}
                    </span>
                  </td>
                  <td className="publications__table-cell">
                    <button
                      className="publications__button"
                      onClick={() => handleViewPost(publication)}
                    >
                      Visualizar Post
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="publications__table-cell"
                  style={{ textAlign: "center" }}
                >
                  Nenhuma solicitação de publicação cadastrada
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {selectedPost && (
        <SharedPublication post={selectedPost} onClose={closeModal} />
      )}
    </>
  );
};
