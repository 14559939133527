import React from "react";
import { FaCalendar } from "react-icons/fa";
import { MdFavoriteBorder } from "react-icons/md";
import "./styles.scss";
import { IRelatedEventCard } from "../../../event/IProps/expositor.interface";

export const RelatedEventCard: React.FC<IRelatedEventCard> = ({
  imageUrl,
  name,
  location,
  moth,
  timeEnd,
  timeStart
}) => {
  return (
    <section className="expositorCard">
      <div className="expositorCard__image">
        <img
          src={
            imageUrl ??
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjpmZvsRQgC9JK17R3HTGetqX8J5Xy8EsfgQ&s"
          }
          alt={name}
        />
      </div>

      <div className="expositorCard__info">
        <section className="expositorCard__info--desc">
          <div className="expositorCard__info--hour">
          <p>
              <FaCalendar style={{marginRight: '5px'}} size={10} id="icon-calendar" />{moth}
            </p>
          </div>
          <div className="expositorCard__info--favorite">
            {/* <MdFavoriteBorder size={20} id="icon-favorite" /> */}
          </div>
        </section>
        <p className="expositorCard__info--name">{name}</p>
        <p className="expositorCard__info--location">{location}</p>
      </div>
    </section>
  );
};
