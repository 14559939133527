import "./styles.scss";

import React, { useEffect, useState } from "react";

import { FiLogOut } from "react-icons/fi";
import { GoHome } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi2";
import {
  IoChatbubbleEllipsesOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { LuLayoutDashboard } from "react-icons/lu";
import { PiCalendarDotsBold } from "react-icons/pi";
import { TbCarouselVertical } from "react-icons/tb";

import eventsApi from "../../../../core/services/EventsApi";
import { ConstructionPage } from "../../../generic/pages/construction";
import { IMediaPublication } from "../../event/IProps/media.interface";
import { ModalPublicationType } from "../../event/IProps/modalPublication.interface";
import { MyEvent } from "../components/myEvent";
import { DetailEvent } from "../components/myEvent/detailEvent";
import { MyExhibitors } from "../components/myExibithors";
import { CreatExhibitors } from "../components/createExhibitors";
import { CreateImmobile } from "../components/createImmobile";
import { CreateEvent } from "../components/createEvent";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  isVisible: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ isVisible }) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("Dashboard");
  const eventType = ModalPublicationType.PUBLICATION;

  const [publicationsData, setPublicationsData] = useState<IMediaPublication[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const {t} = useTranslation();

  const fetchPublications = async () => {
    try {
      const eventId = "6696d5451f8a82a8c8c18a7c";
      const response = await eventsApi.getPublications(
        eventId,
        eventType,
        true
      );
      setPublicationsData(response);
    } catch (err) {
      console.error("Failed to fetch publications:", err);
      setError("Failed to fetch publications");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPublications();
  }, []);

  const handleItemClick = (item: string, tab: string) => {
    setSelectedItem(item);
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Inicial":
        return (
          <section className="body__info--content">
            <ConstructionPage />
          </section>
        );
      case "Dashboard":
        return (
          <section className="body__info--content">
            <ConstructionPage />
          </section>
        );
      case "CreateEvent":
        return (
          <section className="body__info--content">
            <CreateEvent />
          </section>
        );
      case "MyEvent":
        return (
          <section className="body__info--speakers">
            <MyEvent />
          </section>
        );
      case "CreateImmobile":
        return (
          <section className="body__info--speakers">
           <CreateImmobile />
          </section>
        );
      case "CreateExpositor":
        return (
          <section className="body__info--speakers">
            {" "}
            <CreatExhibitors />
          </section>
        );
      case "Publication":
        return (
          <section className="body__info--speakers">
            <DetailEvent
              title="Publicações/ FRMI"
              eventTitle="FRMI"
              navItems={["Publicações"]}
              eventId="6696d5451f8a82a8c8c18a7c"
            />
          </section>
        );
      case "Messages":
        return (
          <section className="body__info--speakers">
            {" "}
            <ConstructionPage />
          </section>
        );
      case "MyStands":
        return (
          <section className="body__info--speakers">
            <MyExhibitors />
          </section>
        );
      case "Member":
        return (
          <section className="body__info--speakers">
            {" "}
            <ConstructionPage />
          </section>
        );
      case "Config":
        return (
          <section className="body__info--speakers">
            {" "}
            <ConstructionPage />
          </section>
        );
      case "Logout":
        return <section className="body__info--speakers">{t("logout")}</section>;
      default:
        return "Dashboard";
    }
  };

  return (
    <div className="sidebar-layout">
      <nav
        className={`sidebar-layout__sidebar ${
          isVisible ? "visible" : "hidden"
        }`}
        aria-label="Menu Lateral"
      >
        <ul className="sidebar-layout__list">
          <li
            className={`sidebar-layout__item ${
              selectedItem === "home" ? "sidebar-layout__item--selected" : ""
            }`}
            onClick={() => handleItemClick("home", "Inicial")}
          >
            <a href="#home" className="sidebar-layout__link">
              <GoHome size={18} style={{ marginRight: "8px" }} /> {t("initialPage")}
            </a>
          </li>
          <hr className="sidebar-layout__divider" />
          <li className="sidebar-layout__item">
            <p className="sidebar-layout__category">Eventos</p>
            <ul className="sidebar-layout__sublist">
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "dashboard"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("dashboard", "Dashboard")}
              >
                <a href="#dashboard" className="sidebar-layout__link">
                  <LuLayoutDashboard style={{ marginRight: "8px" }} />
                  Dashboard
                </a>
              </li>
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "criar-evento"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("criar-evento", "CreateEvent")}
              >
                <a href="#criar-evento" className="sidebar-layout__link">
                  <PiCalendarDotsBold
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("createEvent")}
                </a>
              </li>
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "meus-eventos"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("meus-eventos", "MyEvent")}
              >
                <a href="#meus-eventos" className="sidebar-layout__link">
                  <PiCalendarDotsBold
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("myEvents")}
                </a>
              </li>
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "criar-imovel"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("criar-imovel", "CreateImmobile")}
              >
                <a href="#criar-imovel" className="sidebar-layout__link">
                  <PiCalendarDotsBold
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("createProperty")}
                </a>
              </li>
            </ul>
          </li>
          <hr className="sidebar-layout__divider" />
          <li className="sidebar-layout__item">
            <p className="sidebar-layout__category">Stands</p>
            <ul className="sidebar-layout__sublist">
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "criar-stand"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("criar-stand", "CreateExpositor")}
              >
                <a href="#criar-stand" className="sidebar-layout__link">
                  <PiCalendarDotsBold
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("createExhibitor")}
                </a>
              </li>
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "meus-stands"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("meus-stands", "MyStands")}
              >
                <a href="#meus-stands" className="sidebar-layout__link">
                  <PiCalendarDotsBold
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("myExhibitors")}
                </a>
              </li>
            </ul>
          </li>
          <hr className="sidebar-layout__divider" />
          <li className="sidebar-layout__item">
            <p className="sidebar-layout__category">Usuários</p>
            <ul className="sidebar-layout__sublist">
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "publicacoes"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("publicacoes", "Publication")}
              >
                <a href="#publicacoes" className="sidebar-layout__link">
                  <TbCarouselVertical
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("publications")}
                </a>
              </li>
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "mensagens"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("mensagens", "Messages")}
              >
                <a href="#mensagens" className="sidebar-layout__link">
                  <IoChatbubbleEllipsesOutline
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("messages")}
                </a>
              </li>
            </ul>
          </li>
          <hr className="sidebar-layout__divider" />
          <li className="sidebar-layout__item">
            <p className="sidebar-layout__category">Outros</p>
            <ul className="sidebar-layout__sublist">
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "membros"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("membros", "Member")}
              >
                <a href="#membros" className="sidebar-layout__link">
                  <HiOutlineUserGroup
                    size={18}
                    style={{ marginRight: "8px" }}
                  />
                  {t("members")}
                </a>
              </li>
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "configuracoes"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("configuracoes", "Config")}
              >
                <a href="#configuracoes" className="sidebar-layout__link">
                  <IoSettingsOutline size={18} style={{ marginRight: "8px" }} />
                  {t("settings")}
                </a>
              </li>
              <li
                className={`sidebar-layout__subitem ${
                  selectedItem === "sair"
                    ? "sidebar-layout__item--selected"
                    : ""
                }`}
                onClick={() => handleItemClick("sair", "Logout")}
              >
                <a href="#sair" className="sidebar-layout__link">
                  <FiLogOut size={18} style={{ marginRight: "8px" }} />
                  {t("logout")}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <main className="sidebar-layout__content">{renderContent()}</main>
    </div>
  );
};
