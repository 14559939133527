export class StatusTranslator {
  translations: { [key: string]: string };

  constructor() {
    this.translations = {
      // CONTENT
      "Content not found": "Conteúdo não encontrado: '{0}'",

      // EVENTS
      "Event not found": "Evento não encontrado: '{0}'",
      "Event is crowded": "Evento '{0}' está lotado",
      "User does not participate in this event":
        "Usuário '{0}' não participa deste evento",
      "User cannot participate in this event":
        "Usuário '{0}' não pode participar deste evento",

      // EXHIBITOR
      "Exhibitor not found": "Expositor não encontrado: '{0}'",

      // IMMOBILE
      "Immobile not found": "Imóvel não encontrado: '{0}'",

      // CONDOMINIUM
      "Condominium not found": "Condomínio não encontrado: '{0}'",

      // AUTHENTICATION

      // POWER
      "Power not found": "Poder não encontrado: '{0}'",
      "Power already exists": "Poder '{0}' já existe",

      // ROLE
      "Role not found": "Função não encontrada: '{0}'",
      "Role already exists": "Função '{0}' já existe",
      "User '{0}' already exists": "Usuário '{0}' já existe",

      // USER
      "User not found": "Usuário não encontrado: '{0}'",
      "User already exists": "Usuário '{0}' já existe",
      "You can't delete the last admin!":
        "Você não pode excluir o último administrador!",
      "You are not authorized to add this role!":
        "Você não está autorizado a adicionar esta função!",
      "Bad credentials": "Senha incorreta, Tente novamente!",

      // ADDRESS
      "Address not found": "Endereço não encontrado: '{0}'",

      // PERSONAL DATA
      "Personal data not found": "Dados pessoais não encontrados: '{0}'",

      // FILES
      "File not found": "Arquivo não encontrado",

      // PUBLICATION
      "Publication not found": "Publicação não encontrada: '{0}'",
      "Publication awaiting analysis": "Publicação aguardando análise",

      // SPEAKER
      "Speaker not found": "Palestrante não encontrado: '{0}'",
      "User {0} already exists": "Usuário '{0}' já existe",
    };
  }
  translate(message: string): string {
    // Iterate through the known error messages
    for (const [key, translation] of Object.entries(this.translations)) {
      // Create a regex pattern to match the error message and capture variable parts
      const regex = new RegExp(`^${key.replace("{0}", "(.*)")}.*$`);
      const match = message.match(regex);

      if (match) {
        // Extract the variable part and replace it in the translation
        const variablePart = match[1];
        return translation.replace("{0}", variablePart);
      }
    }

    // Return a default message if no translation is found
    return `Translation not found for message: '${message}'`;
  }
}
