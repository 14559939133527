import "leaflet/dist/leaflet.css";
import "./styles.scss";

import { useEffect, useState } from "react";

import { EmblaOptionsType } from "embla-carousel";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaSpotify,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { SlGlobe } from "react-icons/sl";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import { trackEvent } from "../../../../../shared/analytics";
import { EmblaCarousel } from "../../../../../shared/components/carouselImgs";
import { Circle } from "../../../../../shared/components/circle";
import { useTranslation } from "react-i18next";

const OPTIONS: EmblaOptionsType = {
  align: "start",
  dragFree: true,
  loop: true,
};

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

export const Detail: React.FC = () => {
  const [eventData, setEventData] = useState<IEvent.byId>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    getById();
    trackEvent("Button", "Click", "detalhes do evento");
  }, []);

  const getById = async () => {
    const data = await eventsApi.getById("6696d5451f8a82a8c8c18a7c");
    if (data) {
      setEventData(data);
      setLoading(false);
    }
  };

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex + 1) % (eventData?.images.length || 1)
    );
  };

  const prevImage = () => {
    setSelectedImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + (eventData?.images.length || 1)) %
        (eventData?.images.length || 1)
    );
  };

  const getYouTubeEmbedUrl = (url: string) => {
    const videoId = url.split("v=")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const handleClick = () => {
    if (eventData && eventData.socialMedia && eventData.socialMedia.whatsapp) {
      window.location.href = eventData.socialMedia.whatsapp;
    } else {
      console.error("WhatsApp link is not available");
    }
  };

  const generateGoogleMapsLink = (
    road: string,
    city: string,
    state: string,
    zipCode: string,
    number: string
  ): string => {
    const baseUrl = "https://www.google.com/maps/place/";
    const addressNumber = number === "S/N" ? "" : `${number}+`;
    const address = `${addressNumber}${road},+${city},+${state},+${zipCode}`;
    return `${baseUrl}${encodeURIComponent(address)}`;
  };

  return (
    <div className="containe">
      <section id="sponsors">
        <section id="containe-sponsors" className="containe__section">
          {loading ? (
            <Skeleton
              width={182}
              height={25}
              style={{ marginBottom: 10 }}
              borderRadius={0}
            />
          ) : (
            <h2>{t("sponsors")}</h2>
          )}
          <div className="wrapper">
            {loading
              ? Array(7)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton
                      key={index}
                      circle={true}
                      height={71}
                      width={71}
                    />
                  ))
              : eventData?.listSponsor.map((sponsor) => (
                  <Link
                    to="/expositor"
                    state={{ exhibitorId: sponsor.id }}
                    style={{
                      pointerEvents: "auto",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "#212121",
                    }}
                  >
                    <Circle
                      key={sponsor.id}
                      name={sponsor.name}
                      img={sponsor.profilePicture}
                    />
                  </Link>
                ))}
          </div>
        </section>
        <hr id="line" />
      </section>

      <section className="containe__section">
        {loading ? (
          <Skeleton
            width={176}
            height={25}
            style={{ marginBottom: 10, marginTop: 64 }}
            borderRadius={0}
          />
        ) : (
          <h2 id="details">{t("detailEvent")}</h2>
        )}

        {loading ? (
          <Skeleton
            height={633}
            width={"100%"}
            borderRadius={0}
            className="skeleton-map"
          />
        ) : (
          <article className="containe__section-article">
            <div
              dangerouslySetInnerHTML={{ __html: eventData?.description || "" }}
            />
          </article>
        )}
      </section>
      <hr />

      <section className="containe__section">
        {loading ? (
          <Skeleton
            width={182}
            height={25}
            style={{ marginBottom: 10 }}
            borderRadius={0}
          />
        ) : (
          <h2>{t("videoEvent")}</h2>
        )}
        {loading ? (
          <Skeleton
            height={346}
            width={"100%"}
            borderRadius={0}
            className="skeleton-map"
          />
        ) : (
          <div className="video-container">
            <iframe
              width="100%"
              height="100%"
              src={getYouTubeEmbedUrl(eventData?.videos[0] || "")}
              title="Vídeo do Evento"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </section>
      <hr />
      <section id="images" className="containe__section">
        {loading ? (
          <Skeleton
            width={208}
            height={25}
            style={{ marginBottom: 10 }}
            borderRadius={0}
          />
        ) : (
          <h2>{t("imagesEvent")}</h2>
        )}
        {loading ? (
          <div id="skeleton-images">
            {Array(8)
              .fill(0)
              .map((_, index) => (
                <Skeleton key={index} height={200} width={200} />
              ))}
          </div>
        ) : (
          <>
            <EmblaCarousel slides={eventData?.images || []} options={OPTIONS} />
          </>
        )}
      </section>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={closeModal}>
              <IoClose />
            </button>
            <button className="modal-nav-button-prev" onClick={prevImage}>
              ‹
            </button>
            <img
              src={eventData?.images[selectedImageIndex]}
              alt={`Imagem ${selectedImageIndex + 1}`}
              className="modal-image"
            />
            <button className="modal-nav-button-next" onClick={nextImage}>
              ›
            </button>
          </div>
        </div>
      )}
      <hr />
      <section className="containe__section">
        {loading ? (
          <Skeleton
            width={208}
            height={25}
            style={{ marginBottom: 10 }}
            borderRadius={0}
          />
        ) : (
          <h2>{t("localization")}</h2>
        )}
        {loading ? (
          <Skeleton height={25} width={306} borderRadius={0} />
        ) : (
          <>
            <p className="containe__section-location">
              {`${eventData?.local}`}
            </p>
            <p className="containe__section-location">
              {`${eventData?.address.road}, ${eventData?.address.number} - ${eventData?.address.city}, ${eventData?.address.state}`}{" "}
              -{" "}
              <span className="address-cep">
                CEP {eventData?.address.zipCode}
              </span>
            </p>
          </>
        )}
        {loading ? (
          <Skeleton
            height={469}
            width={"100%"}
            borderRadius={0}
            className="skeleton-map"
          />
        ) : (
          <a
            target="_blank"
            href={generateGoogleMapsLink(
              eventData?.address.road || "",
              eventData?.address.number || "",
              eventData?.address.city || "",
              eventData?.address.state || "",
              eventData?.address.zipCode || ""
            )}
            rel="noreferrer"
          >
            <MapContainer
              className="containe__section-map"
              center={[-9.669255548500397, -35.72293836004883]}
              zoom={20}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[-9.669255548500397, -35.72293836004883]}>
                <Popup></Popup>
              </Marker>
            </MapContainer>
          </a>
        )}
        <div className="btn--location" id="register-button">
          <button
            onClick={() =>
              window.open(
                generateGoogleMapsLink(
                  eventData?.address.road || "",
                  eventData?.address.number || "",
                  eventData?.address.city || "",
                  eventData?.address.state || "",
                  eventData?.address.zipCode || ""
                ),
                "_blank"
              )
            }
          >
            {t("howToGetThere")}
          </button>
        </div>
      </section>
      <hr />
      <section className="containe__section">
        {loading ? (
          <Skeleton
            width={182}
            height={25}
            style={{ marginBottom: 10 }}
            borderRadius={0}
          />
        ) : (
          <h2>{t("aboutThe")} PSIU Educação</h2>
        )}
        {loading ? (
          <Skeleton
            height={346}
            width={"100%"}
            borderRadius={0}
            className="skeleton-map"
          />
        ) : (
          <article className="containe__section-article">
            <div
              dangerouslySetInnerHTML={{
                __html: eventData?.aboutSponsor || "",
              }}
            />
          </article>
        )}
        {loading ? (
          <Skeleton
            width={209}
            height={43}
            style={{ marginTop: 20 }}
            borderRadius={33}
          />
        ) : (
          <div className="btn--productor" id="register-button">
            <button
              onClick={handleClick}
              className={eventData?.socialMedia?.whatsapp ? "active" : ""}
            >
              {t("contactTheProducer")}
            </button>
          </div>
        )}
      </section>

      <section className="details__social">
        <section className="details__social--website">
          <section className="details__social--website--link">
            {loading ? (
              <Skeleton width={200} />
            ) : (
              eventData?.socialMedia?.site && (
                <>
                  <SlGlobe />

                  <a
                    target="_blank"
                    href={eventData.socialMedia.site}
                    rel="noreferrer"
                  >
                    {eventData.socialMedia.site}
                  </a>
                </>
              )
            )}
          </section>
        </section>

        <section className="details__social--network">
          <div className="details__social--network--title">
            {loading ? (
              <Skeleton width={138} height={25} />
            ) : (
              (eventData?.socialMedia?.facebook ||
                eventData?.socialMedia?.instagram ||
                eventData?.socialMedia?.linkedin ||
                eventData?.socialMedia?.spotify) && <h3>{t("socialMedia")}</h3>
            )}
          </div>
          <div className="details__social--network--icons">
            {loading ? (
              <Skeleton width={25} height={25} />
            ) : (
              <>
                {eventData?.socialMedia?.spotify && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.spotify}
                    rel="noreferrer"
                  >
                    <FaSpotify size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.facebook && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.facebook}
                    rel="noreferrer"
                  >
                    <FaFacebook size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.linkedin && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.linkedin}
                    rel="noreferrer"
                  >
                    <FaLinkedin size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.instagram && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.instagram}
                    rel="noreferrer"
                  >
                    <FaInstagram size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.youtube && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.youtube}
                    rel="noreferrer"
                  >
                    <FaYoutube size={19} />
                  </a>
                )}
                {eventData?.socialMedia?.twitter && (
                  <a
                    target="_blank"
                    href={eventData.socialMedia.twitter}
                    rel="noreferrer"
                  >
                    <FaTwitter size={19} />
                  </a>
                )}
              </>
            )}
          </div>
        </section>
      </section>
    </div>
  );
};
