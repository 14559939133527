import { useEffect, useState } from "react";
import "reactflow/dist/style.css";
import { IEvent } from "../../../../core/interfaces/event/event.interface";
import { setData } from "../../../../core/reduxTest/feature/dataSlice";
import { useAppDispatch } from "../../../../core/reduxTest/hook/hook";
import eventsApi from "../../../../core/services/EventsApi";
import { Body } from "../components/body";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { Shortcuts } from "../components/shortcuts";
import "./styles.scss";
import { HeaderSkeleton } from "../components/skeleton/header";
import { Menu } from "../../../../core/components/menu";
import { useTranslation } from "react-i18next";

export const Event = () => {
  const [eventData, setEventData] = useState<IEvent.byId>();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("Detalhes");
  const { t } = useTranslation();

  const handleViewAllClick = () => {
    setActiveTab("Expositores");
  };
  const dispatch = useAppDispatch();
  const meses: string[] = [
    t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
  ];

  useEffect(() => {
    getById();
  }, [dispatch]);

  const getById = async () => {
    const date = await eventsApi.getById("6696d5451f8a82a8c8c18a7c");
    if (date) {
      setEventData(date);
      const data = {
        images: date.images || [],
        listSponsor: date.listSponsor || [],
        videos: date.videos || [],
        aboutSponsor: date.aboutSponsor || "",
        description: date.description || "",
        location: date.local || "",
      } as never;

      (Object.keys(data) as Array<keyof unknown>).forEach((key) => {
        const value = data[key] || "";
        dispatch(setData({ key: key as keyof unknown, value: value }));
      });
    }
    setLoading(false);
  };

  const separarData = (data: string) => {
    const match = /^(\d{2})\/(\d{2})\/\d{4}$/.exec(data);

    if (!match) {
      console.error(
        "Formato de data inválido. O formato esperado é dd/MM/yyyy."
      );
      return null;
    }

    const [, dia, mesIndex] = match;
    const mes = meses[parseInt(mesIndex, 10) - 1]; // Convertendo o índice do mês para 0-based

    return { dia, mes };
  };

  const calcularDiferencaDias = (dataInicio: string, dataFim: string) => {
    const [diaInicio, mesInicio, anoInicio] = dataInicio.split("/").map(Number);
    const [diaFim, mesFim, anoFim] = dataFim.split("/").map(Number);

    const data1 = new Date(anoInicio, mesInicio - 1, diaInicio);
    const data2 = new Date(anoFim, mesFim - 1, diaFim);

    const diferencaEmMilissegundos = Math.abs(data2.getTime() - data1.getTime());
    const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

    return diferencaEmDias;
  };

  const formatarMes = (dataInicio: string, dataFim: string) => {
    const dataSeparadaInicio = separarData(dataInicio);
    const dataSeparadaFim = separarData(dataFim);

    if (!dataSeparadaInicio || !dataSeparadaFim) {
        return "";
    }

    const diferencaDias = calcularDiferencaDias(dataInicio, dataFim);

    const diaInicio = dataSeparadaInicio.dia;
    const diaFim = dataSeparadaFim.dia;
    const mes = dataSeparadaInicio.mes;

    const translateFrom = t("from");  
    const translateTo = t("to");    
    const translateAnd = t("and");     

    return `${diaInicio} ${diferencaDias > 1 ? translateTo : translateAnd} ${diaFim} ${translateFrom} ${mes}`;
};


  return (
    <div className="main">
      <Menu />
      {loading || !eventData ? (
        <HeaderSkeleton />
      ) : (
          <Header
          title={eventData.title}
          month={formatarMes(eventData.dateStart || "", eventData.dateEnd || "")}
          hours={`${eventData.timeStart || ""} - ${eventData.timeEnd || ""}`}
          location={eventData.local}
          backgroundImage={eventData.coverPhoto}
          profileImage={eventData.profilePicture}
          mobileBackground={eventData.coverPhotoMobile}
        />
      )}

      <div className="main__principal">
        <section className="main__principal--body">
          <Body activeTab={activeTab} setActiveTab={setActiveTab} />
        </section>

        <section className="main__principal--shortcuts">
          <Shortcuts onViewAllClick={handleViewAllClick} />
        </section>
      </div>
      <section className="main__footer">
        <Footer />
      </section>
    </div>
  );
};
