import { createContext } from "react";

export type AuthContextType = {
  user: string | null;
  signin: (name: string, password: string) => Promise<any>;
  signout: () => void;
  register: (userData: any) => Promise<any>;
};

export const AuthContext = createContext<AuthContextType>(null!);
