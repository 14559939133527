import React, { useState } from "react";
import "./styles.scss";
import perfil from "../../../assets/imgs/perfil.svg";
import { ModalPublication } from "../modalPublication";
import { InputPublicationProps } from "../../../modules/psiu/event/IProps/inputPublication.interface";
import { ModalRegister } from "../modalRegister";
import { useTranslation } from "react-i18next";

export const InputPublication: React.FC<InputPublicationProps> = ({ isLogged, name, perfil, typePublication, onUpdate }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const imageProfile = "https://semaluguel-images.s3.us-east-2.amazonaws.com/USER/profile.jpg";
  const {t} = useTranslation();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    handleOpenModal();
  };

  return (
    <section className="inputPublication">
      <section className="inputPublication__content" onClick={handleOpenModal}>
        {isLogged && (
          perfil === imageProfile || perfil == null ? (
            <div className="modal__publication--icon">
              {name?.charAt(0).toUpperCase()}
              {name?.charAt(1).toUpperCase()}
            </div>
          ) : (
            <img id="inputPublication__image" src={perfil} alt="image" />
          )
        )}
        <input type="text" placeholder={t("startPublication")} onFocus={handleInputFocus} readOnly />
      </section>
      {isLogged ? (
        <ModalPublication onUpdate={onUpdate} type={typePublication} isOpen={isModalOpen} onClose={handleCloseModal} />
      ) : (
        <ModalRegister isOpen={isModalOpen} onClose={handleCloseModal} />
      )}
    </section>
  );
};
