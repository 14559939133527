import "./styles.scss";

import React, { useEffect, useState } from "react";

import eventsApi from "../../../../../core/services/EventsApi";
import { trackEvent } from "../../../../../shared/analytics";
import { InputPublication } from "../../../../../shared/components/inputPublication";
import { MediaPublication } from "../../../../../shared/components/mediaPublication";
import { IMediaPublication } from "../../IProps/media.interface";
import { ModalPublicationType } from "../../IProps/modalPublication.interface";
import { InputPublicationSkeleton } from "../skeleton/inputPublication";
import { MediaPublicationSkeleton } from "../skeleton/mediaPublication";

export const Announcement: React.FC = () => {
  const authToken = localStorage.getItem("authToken");
  const name = localStorage.getItem("name");
  let isAdmin: boolean = false;
  const typePublication = ModalPublicationType.ANNOUNCEMENT;
  const [publicationsData, setPublicationsData] = useState<IMediaPublication[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const fetchAnnouncements = async () => {
    try {
      const eventId = "6696d5451f8a82a8c8c18a7c";
      const response = await eventsApi.getPublications(
        eventId,
        typePublication,
        false
      );
      setPublicationsData(response);
    } catch (err) {
      console.error("Failed to fetch announcements:", err);
      setError("Failed to fetch announcements");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      const base64Url = authToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const decodedToken = JSON.parse(jsonPayload);
      setRole(decodedToken.role);
      setImage(decodedToken.image);
    }
    trackEvent("Button", "Click", "anuncios");
    fetchAnnouncements();
  }, []);

  if (role) {
    if (role === "ADMIN") {
      isAdmin = true;
    }
  }

  if (publicationsData.length === 0) {
    return (
      <section className="announcement">
        <section
          className={`announcement__input ${
            !isAdmin ? "announcement__input--hidden" : ""
          }`}
        >
          {loading ? (
            <InputPublicationSkeleton />
          ) : (
            <InputPublication
              name={name}
              perfil={image}
              onUpdate={fetchAnnouncements}
              typePublication={typePublication}
              isLogged={isAdmin}
            ></InputPublication>
          )}
        </section>
      </section>
    );
  }

  return (
    <section className="announcement">
      <section
        className={`announcement__input ${
          !isAdmin ? "announcement__input--hidden" : ""
        }`}
      >
        {loading ? (
          <InputPublicationSkeleton />
        ) : (
          <InputPublication
            name={name}
            perfil={image}
            onUpdate={fetchAnnouncements}
            typePublication={typePublication}
            isLogged={isAdmin}
          ></InputPublication>
        )}
      </section>

      <section className="announcement__posts">
        {loading ? (
          <MediaPublicationSkeleton />
        ) : (
          publicationsData.map((publication) => (
            <MediaPublication
              status={publication.status}
              typePublication={typePublication}
              onUpdate={fetchAnnouncements}
              key={publication.id}
              id={publication.id}
              creationDate={publication.creationDate}
              user={publication.user}
              description={publication.description}
              isAdmin={isAdmin}
              bookmark={true}
              images={publication.images}
            />
          ))
        )}
      </section>
    </section>
  );
};
