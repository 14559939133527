import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { IEvent } from "../../../../../core/interfaces/event/event.interface";
import eventsApi from "../../../../../core/services/EventsApi";
import { EventButtons } from "../../../../../shared/components/buttons";
import { Circle } from "../../../../../shared/components/circle";
import { ExpositorCardShortcuts } from "../../../../../shared/components/expositorCardShortcuts";
import { ExpositorCardSkeleton } from "../skeleton/expositorCard";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const generateGoogleMapsLink = (
  road: string,
  city: string,
  state: string,
  zipCode: string,
  number: string
): string => {
  const baseUrl = "https://www.google.com/maps/place/";
  const addressNumber = number === "S/N" ? "" : `${number}+`;
  const address = `${addressNumber}${road},+${city},+${state},+${zipCode}`;
  return `${baseUrl}${encodeURIComponent(address)}`;
};
interface ShortcutsProps {
  onViewAllClick: () => void;
}

export const Shortcuts:  React.FC<ShortcutsProps> = ({ onViewAllClick }) => {
  const [shortcuts, setShortcuts] = useState<IEvent.shortcuts | null>(null);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    getShortCutById();
  }, []);

  const getShortCutById = async () => {
    const date = await eventsApi.getShortCutById("6696d5451f8a82a8c8c18a7c");
    if (date) {
      setShortcuts(date);
    }
    setLoading(false);
  };

  const getTranslatedCategory = (category: string) => {
    return t(category.toUpperCase());
  };

  return (
    <section className="shortcuts">
      <EventButtons />
      <section className="shortcuts__sponsors">
        {loading ? "" : <h4>{t("sponsors")}</h4>}
        <section className="shortcuts__sponsors--perfil">
          {loading ? (
            <Skeleton height={25} width={143} />
          ) : (
            shortcuts?.listSponsor.map((sponsor) => (
              
                <Link to="/expositor"
                state={{ exhibitorId: sponsor.id }}
                style={{
                  pointerEvents: "auto", // Permite interações de clique
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#212121",
                }}
              >
              <Circle 
                key={sponsor.id}
                name={sponsor.name}
                img={sponsor.profilePicture}
              />
               </Link>
             
            ))
          )}
        </section>
      </section>

      <section className="shortcuts__expositor">
        {loading ? "" : <h4>{t("exhibitors")}</h4>}
        <section className="shortcuts__expositor--cards">
          {loading
            ? Array(4)
              .fill(0)
              .map((_, index) => ExpositorCardSkeleton(index))
            : shortcuts?.exhibitors.slice(0, 4).map((exhibitors) => (
              <Link to="/expositor"
                state={{ exhibitorId: exhibitors.id }}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#212121",
                }}
              >
                <ExpositorCardShortcuts
                  key={exhibitors.id}
                  id={exhibitors.id}
                  imageUrl={exhibitors.profilePicture}
                  name={exhibitors.title}
                  description={exhibitors.shortDescription}
                  quota={getTranslatedCategory(exhibitors.quota)}
                />
              </Link>
            ))}
          {loading ? (
            <Skeleton width={138} height={25} />
          ) : (
            <a href="#" onClick={onViewAllClick}>
              {t("viewAll")}
            </a>
          )}
        </section>
      </section>
      <hr />



      <section className="shortcuts__location">
        {loading ? <Skeleton width={138} height={25} /> : <h4>{t("localization")}</h4>}
        {loading ? (
          <Skeleton width={308} height={25} />
        ) : (
          <p className="containe__section-location">
            {`${shortcuts?.address.road}, ${shortcuts?.address.number} - ${shortcuts?.address.city}, ${shortcuts?.address.state} - CEP ${shortcuts?.address.zipCode}`}
          </p>
        )}

        {loading ? (
          <Skeleton width={308} height={210} />
        ) : (
          <a target="_blank" href={generateGoogleMapsLink(shortcuts?.address.road || '', shortcuts?.address.number || '', shortcuts?.address.city || '', shortcuts?.address.state || '', shortcuts?.address.zipCode || '')}>
            <MapContainer center={[-9.669255548500397, -35.72293836004883]} zoom={20} style={{ height: "208px", width: "100%", marginTop: "20px" }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[-9.669255548500397, -35.72293836004883]}>
                <Popup>
                </Popup>
              </Marker>
            </MapContainer>
          </a>
        )}
      </section>
    </section>
  );
};
