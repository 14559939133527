import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const userService = {
  register: async (userData: any) => {
    const response = await fetch(
      `https://yyxkpx5kpv.us-east-2.awsapprunner.com/api/auth/register`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Tenant": "frmi",
        },
        body: JSON.stringify({ ...userData }),
      }
    );

    return response.json();
  },
};

export default userService;
