import { jwtDecode } from "jwt-decode";
import { DecodedToken } from "../../interfaces/decodedToken.interface";

export const decodeToken = (token: string): DecodedToken => {
  try {
    const decoded = (jwtDecode as any)(token) as DecodedToken;
    return decoded;
  } catch (error) {
    throw new Error("Token inválido");
  }
};
