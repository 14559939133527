import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import verified from '../../../assets/icons/star-verified.svg';
import eventsApi from '../../../core/services/EventsApi';
import {
  IMediaPublicationProps,
} from '../../../modules/psiu/event/IProps/media.interface';
import Dropdown from '../drowdown';
import { ModalEditPublication } from '../modalEdiPublication';
import { Tag } from '../tag';

export const MediaPublication: React.FC<IMediaPublicationProps> = ({
  status,
  description,
  typePublication,
  bookmark,
  isAdmin,
  creationDate,
  user,
  images,
  id,
  onUpdate,
}) => {
  const [liked, setLiked] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false); // Novo estado para o modal de confirmação
  const [isLoading, setIsLoading] = useState(false); // Novo estado para o loading

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      const payload = JSON.parse(atob(authToken.split(".")[1]));
      const userIdFromToken = payload.userId;
      if (userIdFromToken === user.id) {
        setDropdownVisible(true);
      } else {
        setDropdownVisible(false);
      }
    }
  }, [user.id]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLikeClick = () => {
    setLiked(!liked);
  };

  const handleRemoveClick = () => {
    setConfirmModalOpen(true); // Abre o modal de confirmação
  };

  const handleConfirmRemove = async () => {
    setIsLoading(true); // Inicia o loading
    try {
      await eventsApi.removePublication(id);
      await onUpdate();
    } catch (error) {
      console.error("Erro ao remover a publicação:", error);
    }
    setIsLoading(false);
    setConfirmModalOpen(false);
    await onUpdate();
  };

  const handleCancelRemove = () => {
    setConfirmModalOpen(false);
  };

  const prevSlide = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const nextSlide = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const toggleText = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  const openMediaModal = () => {
    setIsModalVisible(true);
  };

  const closeMediaModal = () => {
    setIsModalVisible(false);
  };

  const renderContent = () => {
    const maxLength = 320;
    const truncatedDescription = description.slice(0, maxLength);
    return (
      <>
        <p style={{ marginBottom: "10px" }} id="description">
          {isTextExpanded ? description : truncatedDescription}
          {description.length > maxLength && (
            <span onClick={toggleText} className="see-more">
              {isTextExpanded ? "...ver menos" : " ...ver mais"}
            </span>
          )}
        </p>
        <div className="slider">
          <div
            className="slider-content"
            style={{
              transform: `translateX(-${currentSlide * 100}%)`,
              maxHeight: "552px",
              objectFit: "contain",
            }}
          >
            {images.map((item, index) => {
              const isVideo = item.match(/\.(mp4|webm|ogg)$/);
              return (
                <div
                  key={index}
                  className={`slide ${index === currentSlide ? "active" : ""}`}
                  onClick={openMediaModal}
                >
                  {isVideo ? (
                    <video controls width="100%">
                      <source src={item} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img src={item} alt="Content" width="100%" />
                  )}
                </div>
              );
            })}
          </div>
          {images.length > 1 && (
            <>
              <button className="prev" onClick={prevSlide}>
                &#10094;
              </button>
              <button className="next" onClick={nextSlide}>
                &#10095;
              </button>
            </>
          )}
        </div>

        {/* Modal de Mídia Ampliada */}
        {isModalVisible && (
          <div className="media-modal" onClick={closeMediaModal}>
            <div className="modal-content">
              {images.length > 1 && (
                <button className="prev-esquerda" onClick={prevSlide}>
                  &#10094;
                </button>
              )}
              {images[currentSlide].match(/\.(mp4|webm|ogg)$/) ? (
                <video controls>
                  <source src={images[currentSlide]} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={images[currentSlide]} alt="Content" />
              )}
              {images.length > 1 && (
                <button className="next-direita" onClick={nextSlide}>
                  &#10095;
                </button>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const formatCreationDate = (creationDate: string) => {
    const now = new Date();
    const createdDate = new Date(creationDate);
    const diffInMs = now.getTime() - createdDate.getTime();
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 60) {
      return diffInMinutes === 1
        ? "1 minuto atrás"
        : `${diffInMinutes} minutos atrás`;
    } else if (diffInHours < 24) {
      return diffInHours === 1 ? "1 hora atrás" : `${diffInHours} horas atrás`;
    } else {
      return diffInDays === 1 ? "1 dia atrás" : `${diffInDays} dias atrás`;
    }
  };

  return (
    <section className="mediaPublication">
      <section className="mediaPublication__header">
        <div className="mediaPublication__header--profile-container">
          <img src={user.image} alt={user.name} />
          <img src={verified} alt="Verificado" id="verified-star" />
        </div>
        <section className="mediaPublication__header--title">
          <section className="mediaPublication__header--title--name">
            <h4>{user.name}</h4>
            <p id="publication-date">{formatCreationDate(creationDate)}</p>
          </section>
        </section>
        <section className="mediaPublication__header--icon">
          <Tag
            status={status}
            eventType={typePublication}
            title="aguardando análise"
            color="#FFAC3885"
          />
          {isDropdownVisible && (
            <section className="mediaPublication__header--dropdown">
              <Dropdown onEdit={handleOpenModal} onRemove={handleRemoveClick} />
            </section>
          )}
        </section>
      </section>
      <section className="mediaPublication__content">{renderContent()}</section>
      <ModalEditPublication
        id={id}
        description={description}
        images={images}
        onUpdate={onUpdate}
        type={typePublication}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />

      {/* Modal de confirmação de remoção */}
      {isConfirmModalOpen && (
        <section className="modal-overlay">
          <div className="confirm-modal">
            <p>Tem certeza que deseja remover esta publicação?</p>
            {isLoading ? (
              <div className="confirm-modal--loading">
                <div className="spinner"></div>
                <p>Removendo...</p>
              </div>
            ) : (
              <div className="confirm-modal__buttons">
                <button
                  className="confirm-modal__buttons--cancel"
                  onClick={handleCancelRemove}
                >
                  Cancelar
                </button>
                <button
                  className="confirm-modal__buttons--confirm"
                  onClick={handleConfirmRemove}
                >
                  Excluir
                </button>
              </div>
            )}
          </div>
        </section>
      )}
    </section>
  );
};
