import React, { useContext, useEffect, useState } from "react";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/imgs/logo-p.svg";
import users from "../../../assets/imgs/users.svg";
import { DropdownMenu } from "../../../shared/components/menuDropDown";
import { AuthContext } from "../../contexts/auth/AuthContext";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { ChangeLanguage } from "../../../shared/components/changeLanguage";

export const Menu: React.FC = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const name = localStorage.getItem("name");
  const [isMenuBarOpen, setIsMenuBarOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [role, setRole] = useState<string | null>(null);
  const {t} = useTranslation(); 

 
  useEffect(() => {
    if (authToken) {
      const base64Url = authToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const decodedToken = JSON.parse(jsonPayload);
      setRole(decodedToken.role);
    }
  }, [authToken]);

  const redirect = () => {
    navigate("/area-produtor");
  };

  const redirectToEvent = () => {
    navigate("/event");
  };

  const toggleMenuBar = () => {
    setIsMenuBarOpen(!isMenuBarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const translateRole = (role: string) => {
    if (role === "USER") {
      return "Usuário";
    } else if (role === "GUEST") {
      return "Convidado";
    } else if (role === "ADMIN") {
      return "Administrador";
    } else if (role === "MANAGER") {
      return "Gerente";
    }
  };

  return (
    <nav className="menu" id="menu">
      <div className="menu__icon" id="menu-icon" onClick={redirectToEvent}>
        <img src={logo} onClick={toggleMenuBar} alt="Logo" id="menu-logo" />
        <div className="menu__icon--users" id="menu-users">
          {authToken ? (
            <div className="menu__user">
              <div className="menu__user--icon">
                {name?.charAt(0).toUpperCase()}
                {name?.charAt(1).toUpperCase()}
              </div>
              <div className="menu__user--info">
                <p>{name}</p>                
                {/* <p>{translateRole(role!)}</p> */}
                 
              </div>
              <button onClick={toggleDropdown}>
                <IoIosArrowDown size={12} />
                {isDropdownOpen && <DropdownMenu />}
              </button>
            </div>
          ) : (
            <img
              src={users}
              onClick={toggleMenuBar}
              alt="Usuários"
              id="users-logo"
            />
          )}
        </div>
      </div>

      {/* <div className="menu__search" id="menu-search">
          <FiSearch />
        <input
          type="text"
          placeholder="Buscar por eventos..."
          aria-label="Buscar por eventos"
          id="search-input"
        />
      </div> */}
      <nav className="menu__options" id="menu-options">
        <>
        <ChangeLanguage />
        </>
        <>
          {role === "ADMIN" && (
            <p id="producer-area" onClick={redirect}>
             {t("producerArea")}
            </p>
          )}
        </>

        {authToken ? (
          <div className="menu__user">
            <div className="menu__user--icon">
              {name?.charAt(0).toUpperCase()}
              {name?.charAt(1).toUpperCase()}
            </div>
            <div className="menu__user--info">
              <p>{name}</p>
              {/* <p>{translateRole(role!)}</p> */}
              </div>
            <button onClick={toggleDropdown}>
              <IoIosArrowDown size={12} />
              {isDropdownOpen && <DropdownMenu />}
            </button>
          </div>
        ) : (
          <div className="menu__buttons" id="menu-buttons">
            <div className="menu__buttons--login" id="login-button">
              <button>{t("login")}</button>
            </div>
            <div className="menu__buttons--register" id="register-button">
              <button>{t("signUp")}</button>
            </div>
          </div>
        )}
      </nav>
    </nav>
  );
};
