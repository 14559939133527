import "./index.scss";

import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import GlobalStyle from "./assets/styles/global";
import { AuthProvider } from "./core/contexts/auth/AuthProviders";
import { store } from "./core/reduxTest/store/store";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.ts`;

    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        // console.log("SW registered: ", registration);
      })
      .catch((error) => {
        // console.error("SW registration failed: ", error);
      });
  });
}
// G-NGT5ZWFD4J
ReactGA.initialize("G-NGT5ZWFD4J", {
  gaOptions: {
    cookieFlags: "SameSite=None; Secure",
  },
});

// Enviar um evento de página
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

root.render(
  <AuthProvider>
    <Provider store={store}>
      <BrowserRouter>
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </Provider>
  </AuthProvider>
);

reportWebVitals();
