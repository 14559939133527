import ReactGA from "react-ga4";

// Função para rastrear um evento
export const trackEvent = (category: any, action: any, label: any) => {
  console.log(
    `Tracking Event - Category: ${category}, Action: ${action}, Label: ${label}`
  );
  ReactGA.event({
    category: category, // Exemplo: 'Button'
    action: action, // Exemplo: 'Click'
    label: label, // Exemplo: 'Signup Button'
  });
};
