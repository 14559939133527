import React, { useEffect, useState } from "react";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import eventsApi from "../../../core/services/EventsApi";
import "./styles.scss";
import { RiAdminFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

export const DropdownMenu: React.FC = () => {
  const authToken = localStorage.getItem("authToken");
  const [email, setEmail] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (authToken) {
      const base64Url = authToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const decodedToken = JSON.parse(jsonPayload);
      setEmail(decodedToken.email);
      setRole(decodedToken.role);
    } else {
      navigate("/login");
    }
  }, [authToken, navigate]);

  const logout = async () => {
    eventsApi.logoutUser();
    localStorage.removeItem("authToken");
    localStorage.removeItem("name");
    navigate("/login");
    window.location.reload();
  };

  const redirect = async () => {
    navigate("/suport");
    window.location.reload();
  };

  const redirectAdmin = async () => {
    navigate("/area-produtor");
    window.location.reload();
  };

  return (
    <section className="dropdown">
      <section className="dropdown__options">
        {role === "ADMIN" && location.pathname !== "/area-produtor" ? (
          <>
            <div className="dropdown__options--item" onClick={redirectAdmin}>
              <div className="dropdown__options--icon">
                <RiAdminFill />
              </div>
              <div className="dropdown__options--text">
                <h4>{t("producerArea")}</h4>
              </div>
            </div>
            <hr />
          </>
        ) : null}

        <div className="dropdown__options--item" onClick={redirect}>
          <div className="dropdown__options--icon">
            <IoMdHelpCircleOutline size={15} />
          </div>
          <div className="dropdown__options--text">
            <h4>{t("help")}</h4>
          </div>
        </div>
        <hr />
        <div className="dropdown__options--item" onClick={logout}>
          <div className="dropdown__options--icon">
            <MdLogout size={15} />
          </div>
          <div className="dropdown__options--text">
            <h4>{t("logout")}</h4>
            {/* <p>{email}</p> */}
          </div>
        </div>
      </section>
    </section>
  );
};
