import { useEffect, useState } from "react";
import { useApi } from "../../../modules/generic/hooks/UseApi";
import userService from "../../../modules/generic/hooks/UserServices";
import { StatusTranslator } from "../../utils/statusTranslator/statusTranslator";
import { AuthContext } from "./AuthContext";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<string | null>(null);
  const api = useApi();

  const getNameFromToken = (token: string): string | null => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.name || null;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  useEffect(() => {
    const storageData = localStorage.getItem("authToken");
    if (storageData) {
      const name = getNameFromToken(storageData);
      if (name) {
        setUser(name);
        localStorage.setItem("name", name);
      }
    }
  }, [api]);

  const signin = async (name: string, password: string) => {
    const data: any = await api.signin(name, password);
    if (data.message) {
      // Exemplo de uso
      const translator = new StatusTranslator();
      const translatedMessage = translator.translate(data.message);
      return { messageError: translatedMessage };
    }
    if (data.token) {
      const userName = getNameFromToken(data.token);
      if (userName) {
        setUser(userName);
        setName(userName);
        setToken(data.token);
        return data;
      }
    }
    return data;
  };

  const register = async (userData: any) => {
    const data: any = await userService.register(userData);
    if (data.message) {
      // Exemplo de uso
      const translator = new StatusTranslator();
      const translatedMessage = translator.translate(data.message);
      return { messageError: translatedMessage };
    }
    if (data.token) {
      const userName = getNameFromToken(data.token);
      if (userName) {
        setUser(userName);
        setName(userName);
        setToken(data.token);
        return true;
      }
    }
    return false;
  };

  const signout = async () => {
    setUser(null);
    setToken("");
  };

  const setToken = (token: string) => {
    localStorage.setItem("authToken", token);
  };

  const setName = (name: string) => {
    localStorage.setItem("name", name);
  };

  return (
    <AuthContext.Provider value={{ user, signin, signout, register }}>
      {children}
    </AuthContext.Provider>
  );
};
