import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "reactflow/dist/style.css";
import { Menu } from "../../../../core/components/menu";
import { IEvent } from "../../../../core/interfaces/event/event.interface";
import { setData } from "../../../../core/reduxTest/feature/dataSlice";
import { useAppDispatch } from "../../../../core/reduxTest/hook/hook";
import eventsApi from "../../../../core/services/EventsApi";
import { Footer } from "../../event/components/footer";
import { Header } from "../../event/components/header";
import { HeaderSkeleton } from "../../event/components/skeleton/header";
import { BodyExpositor } from "../components/body";
import { ShortcutsExhibitor } from "../components/shortcuts";
import "./styles.scss";
import { HeaderExhibitor } from "../components/header";
import { useTranslation } from "react-i18next";

export const Expositor = () => {
  const [eventData, setEventData] = useState<IEvent.byId>();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const exhibitorId = location.state?.exhibitorId;
  const { t } = useTranslation();

  const meses: string[] = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  useEffect(() => {
    getById();
  }, [dispatch]);

  const getById = async () => {
    const date = await eventsApi.getOneExhibitor(exhibitorId);
    if (date) {
      setEventData(date);
      const data = {
        images: date.images || [],
        listSponsor: date.listSponsor || [],
        videos: date.videos || [],
        aboutSponsor: date.aboutSponsor || "",
        description: date.description || "",
        location: date.local || "",
      } as never;

      (Object.keys(data) as Array<keyof unknown>).forEach((key) => {
        const value = data[key] || "";
        dispatch(setData({ key: key as keyof unknown, value: value }));
      });
    }
    setLoading(false);
  };

  const separarData = (data: string) => {
    const match = /^(\d{2})\/(\d{2})\/\d{4}$/.exec(data);

    if (!match) {
      console.error(
        "Formato de data inválido. O formato esperado é dd/MM/yyyy."
      );
      return null;
    }

    const [, dia, mesIndex] = match;
    const mes = meses[parseInt(mesIndex, 10) - 1]; // Convertendo o índice do mês para 0-based

    return { dia, mes };
  };

  return (
    <div className="main">
      <Menu />
      {loading || !eventData ? (
        <HeaderSkeleton />
      ) : (

        <HeaderExhibitor
          title={eventData.title}
          month={separarData(eventData.dateStart || "")?.mes}
          location={eventData.local}
          backgroundImage={eventData.coverPhoto}
          profileImage={eventData.profilePicture}
          mobileBackground={eventData.coverPhotoMobile}
        />
      )}
      <div className="main__principal">
        <section className="main__principal--body">
          <BodyExpositor />
        </section>

        <section className="main__principal--shortcuts">
          <ShortcutsExhibitor />
        </section>
      </div>
      <section className="main__footer">
        <Footer />
      </section>
    </div>
  );
};
