import React from 'react';
import { HiOutlineChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import "./styles.scss";

export const EventButtons: React.FC = () => {
    return (
        <></>
        // <section className="buttons">
        //     <section className="buttons__chat">
        //         <button
        //         disabled={true}
        //         >                    
        //             <HiOutlineChatBubbleOvalLeftEllipsis />
        //         </button>
        //     </section>
        //     <section className="buttons__register">
        //         <button
        //         disabled={true}
        //         >Inscrever</button>
        //     </section>
        // </section>
    );
};
